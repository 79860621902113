import React from 'react';
import {createAppContainer, createStackNavigator} from 'react-navigation';

import HomeScreen from "../screens/HomeScreen";
import MapScreen from "../screens/MapScreen";
import StrategieGuideScreen from "../screens/StrategieGuideScreen";
import ParolenVerzeichnisScreen from "../screens/ParolenVerzeichnisScreen";
import CharacterEditorScreen from "../screens/CharacterEditorScreen";
import GameScreen from "../screens/GameScreen";
import ReviewScreen from "../screens/ReviewScreen";
import LastReviewScreen from "../screens/LastReviewScreen";
import CreditsScreen from "../screens/CreditsScreen";
import SettingsScreen from "../screens/SettingsScreen";
import LocationScreen from "../screens/LocationScreen";
import DetailParolenVerzeichnisScreen from "../screens/DetailParolenVerzeichnisScreen";
import DetailStrategieguideVerzeichnisScreen from "../screens/DetailStrategieguideVerzeichnisScreen";

const MainNavigator = createStackNavigator({
        'Home': HomeScreen,
        'Map': MapScreen,
        'StrategieGuide': StrategieGuideScreen,
        'ParolenVerzeichnis': ParolenVerzeichnisScreen,
        'CharacterEditor': CharacterEditorScreen,
        'GameScreen': GameScreen,
        'ReviewScreen': ReviewScreen,
        'LastReviewScreen': LastReviewScreen,
        'CreditsScreen': CreditsScreen,
        'SettingsScreen': SettingsScreen,
        'LocationScreen': LocationScreen,
        'DetailParolenVerzeichnisScreen': DetailParolenVerzeichnisScreen,
        'DetailStrategieguideVerzeichnisScreen': DetailStrategieguideVerzeichnisScreen,
    },
    {initialRouteName: 'Home'}
);

MainNavigator.navigationOptions = {
    header: null,
};

const App = createAppContainer(MainNavigator);

export default App;