const icons = {
    'icon_behinderung': require("./icons/icon_behinderung.png"),
    'icon_gefluechtete': require("./icons/icon_gefluechtete.png"),
    'icon_rassismus': require("./icons/icon_rassismus.png"),
    'icon_antiangiz': require("./icons/icon_antiangiz.png"),
    'icon_antiangiz_grey': require("./icons/icon_antiangiz_grey.png"),
    'icon_antisem': require("./icons/icon_antisem.png"),
    'icon_antisem_grey': require("./icons/icon_antisem_grey.png"),
    'icon_behinderung_grey': require("./icons/icon_behinderung_grey.png"),
    'icon_gefluechtete_grey': require("./icons/icon_gefluechtete_grey.png"),
    'icon_rassismus_grey': require("./icons/icon_rassismus_grey.png"),
    'icon_sozialehaerte': require("./icons/icon_sozialehaerte.png"),
    'icon_sozialehaerte_grey': require("./icons/icon_sozialehaerte_grey.png"),
    'icon_sxismus': require("./icons/icon_sxismus.png"),
    'icon_sxismus_grey': require("./icons/icon_sxismus_grey.png"),
    'icon_trans_grey': require("./icons/icon_trans_grey.png"),
    'icon_trans': require("./icons/icon_trans.png")
};

export default icons;