import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Alert,
    Button, TouchableWithoutFeedback, Linking, AsyncStorage
} from 'react-native';
import {WebBrowser} from 'expo';
import icons from "../assets/images/images_icons"
import gameimages from "../assets/images/images_game";

const deviceWidth = 380;
const deviceHeight = 768

export default class SettingsScreen extends React.Component {

    static navigationOptions = {
        title: "Einstellungen",
        headerStyle: {
            backgroundColor: '#605659'
        },
        headerTintColor: '#FFFFFF',
    };

    constructor(props) {
        super(props);

        this.state = {
            musikChecked: true,
            soundChecked: true,
            erklarungenChecked: true,
            schwierigkeitEinfachChecked: false,
            schwierigkeitNormalChecked: true,
            popup: null,
            confirm: null,
            message: {text: null}
        };

    }

    componentDidMount() {

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {

                const settings = JSON.parse(result);

                this.setState({
                    musikChecked: settings.musikChecked,
                    soundChecked: settings.soundChecked,
                    erklarungenChecked: settings.erklarungenChecked,
                    schwierigkeitEinfachChecked: settings.schwierigkeitEinfachChecked,
                    schwierigkeitNormalChecked: settings.schwierigkeitNormalChecked,
                }, () => AsyncStorage.getItem('popup', (err, result) => {
                    console.log(result);
                    if (result !== null) {
                        this.setState({popup: JSON.parse(result)})
                    }
                }));


            }
        });

    }

    _check(identifier) {

        const state = this.state;

        var popup = this.state.popup;

        if (popup === null) {
            popup = {};
        }

        if (state[identifier]) {
            popup['strategieguide'] = false;
            popup['parolenverzeichnis'] = false;
            this.setState({[identifier]: false}, () => this._save());
        } else {
            popup['strategieguide'] = true;
            popup['parolenverzeichnis'] = true;
            this.setState({[identifier]: true}, () => this._save());
        }

        AsyncStorage.setItem('popup', JSON.stringify(popup));

    }

    _toggle(identifier) {

        if (identifier === 'schwierigkeitEinfach') {
            this.setState({schwierigkeitEinfachChecked: true, schwierigkeitNormalChecked: false}, () => this._save());
        } else {
            this.setState({schwierigkeitEinfachChecked: false, schwierigkeitNormalChecked: true}, () => this._save());
        }

    }

    _save() {
        console.log(this.state, "parolen settings")
        AsyncStorage.setItem('parolen_settings', JSON.stringify(this.state));
    }
    _reset() {
        this.setState({
            message: {
                id: 'info_reset',
                text: 'Die Einstellungen und Spielstände wurden gelöscht.',
            },
            popup: true,
            confirm: null
        });

        AsyncStorage.removeItem('parolen_settings');
        AsyncStorage.removeItem('popup');
        AsyncStorage.removeItem('isModeFreePlay');
        AsyncStorage.removeItem('parolen_stars_freeplay');
        AsyncStorage.removeItem('parolen_stars_win_last_location');
        AsyncStorage.removeItem('lastreview');
        AsyncStorage.removeItem('parolen_stars_final');
        AsyncStorage.removeItem('avatar');
    }

    _toggleReset() {

        this.setState({
            message: {
                id: 'confirm_reset',
                text: 'Sicher, dass der Spielstand zurückgestellt werden soll? Damit gehen alle Einstellungen und Spielstände verloren. Das kann nicht rückgängig gemacht werden.',
            },
            confirm: true
        });

    }

    _hideMessages() {
        this.setState({popup: null, confirm: null})

    }


    render() {


        var musikCheckedCheckbox = (<Image
            style={styles.checkbox}
            source={require('../assets/images/buttons/checkbox.png')}
            resizeMode="contain"
        />);

        if (this.state.musikChecked) {
            musikCheckedCheckbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox_checked.png')}
                resizeMode="contain"
            />);
        }

        var soundCheckedCheckbox = (<Image
            style={styles.checkbox}
            source={require('../assets/images/buttons/checkbox.png')}
            resizeMode="contain"
        />);

        if (this.state.soundChecked) {
            soundCheckedCheckbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox_checked.png')}
                resizeMode="contain"
            />);
        }

        var erklarungenCheckedCheckbox = (<Image
            style={styles.checkbox}
            source={require('../assets/images/buttons/checkbox.png')}
            resizeMode="contain"
        />);

        if (this.state.erklarungenChecked) {
            erklarungenCheckedCheckbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox_checked.png')}
                resizeMode="contain"
            />);
        }

        var schwierigkeitEinfachCheckedCheckbox = (<Image
            style={styles.checkbox}
            source={require('../assets/images/buttons/checkbox.png')}
            resizeMode="contain"
        />);

        if (this.state.schwierigkeitEinfachChecked) {
            schwierigkeitEinfachCheckedCheckbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox_checked.png')}
                resizeMode="contain"
            />);
        }

        var schwierigkeitNormalCheckedCheckbox = (<Image
            style={styles.checkbox}
            source={require('../assets/images/buttons/checkbox.png')}
            resizeMode="contain"
        />);

        if (this.state.schwierigkeitNormalChecked) {
            schwierigkeitNormalCheckedCheckbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox_checked.png')}
                resizeMode="contain"
            />);
        }

        var popup = null;
        var confirm = null;

        if (this.state.popup !== null) {

            var popupPositionStyle = {
                width: deviceWidth * 0.80,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.030,
                backgroundColor: '#FFFFFF',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'relative',
                zIndex: 100,
                padding: 30
            };

            var popupPositionTextStyle = {
                fontSize: 16,
                lineHeight: 20
            };

            popup = (
                <View style={{zIndex: 50}}>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{this.state.message.text}</Text>

                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapperCenter}
                                          onPress={() => this._hideMessages()}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>)
        }

        if (this.state.confirm !== null) {

            var popupPositionStyle = {
                width: deviceWidth * 0.80,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.030,
                backgroundColor: '#FFFFFF',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'relative',
                zIndex: 100,
                padding: 30
            };

            var popupPositionTextStyle = {
                fontSize: 16,
                lineHeight: 20
            };

            confirm = (
                <View style={{zIndex: 50}}>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{this.state.message.text}</Text>

                        </View>

                        <View style={{flexDirection: "row"}}>

                            <TouchableOpacity style={styles.weiterButtonWrapper}
                                              onPress={() => this._hideMessages()}>
                                <Image
                                    style={styles.weiterButton}
                                    source={require('../assets/images/buttons/nein.png')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                            <TouchableOpacity style={styles.weiterButtonWrapper}
                                              onPress={() => this._reset()}>
                                <Image
                                    style={styles.weiterButton}
                                    source={require('../assets/images/buttons/ja.png')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>


                        </View>
                    </View>
                </View>)
        }

        if (popup) {
            return (<View>{popup}</View>)
        } else if (confirm) {
            return (<View>{confirm}</View>)
        } else {

            return (

                <View style={styles.container}>

                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{width: '100%', height: '100%'}}>
                        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                            <View
                                style={{
                                    width: deviceWidth * 0.93,
                                    marginTop: 20,
                                    paddingTop: 40,
                                    marginLeft: 3,
                                    paddingRight: 15,
                                    paddingLeft: 40,
                                    flex: 1,
                                    overflow: 'hidden',
                                    backgroundColor: '#ffffff',
                                }}>


                                <View style={styles.checkboxWrapper}>

                                    <TouchableOpacity style={styles.checkboxImage}
                                                      onPress={this._check.bind(this, 'musikChecked')}>
                                        {musikCheckedCheckbox}
                                    </TouchableOpacity>

                                    <Text>Musik an / aus</Text>
                                </View>

                                <View style={styles.checkboxWrapper}>

                                    <TouchableOpacity style={styles.checkboxImage}
                                                      onPress={this._check.bind(this, 'soundChecked')}>
                                        {soundCheckedCheckbox}
                                    </TouchableOpacity>

                                    <Text>Sound an / aus</Text>
                                </View>


                                <View style={styles.checkboxWrapper}>

                                    <TouchableOpacity style={styles.checkboxImage}
                                                      onPress={this._check.bind(this, 'erklarungenChecked')}>
                                        {erklarungenCheckedCheckbox}
                                    </TouchableOpacity>

                                    <Text>Erklärungen an / aus</Text>
                                </View>


                                <View style={styles.checkboxWrapper}>

                                    <TouchableOpacity style={styles.checkboxImage}
                                                      onPress={this._toggle.bind(this, 'schwierigkeitEinfach')}>
                                        {schwierigkeitEinfachCheckedCheckbox}
                                    </TouchableOpacity>

                                    <Text>Schwierigkeit: Einfach</Text>
                                </View>

                                <View style={styles.checkboxWrapper}>

                                    <TouchableOpacity style={styles.checkboxImage}
                                                      onPress={this._toggle.bind(this, 'schwierigkeitNormal')}>
                                        {schwierigkeitNormalCheckedCheckbox}
                                    </TouchableOpacity>

                                    <Text>Schwierigkeit: Normal</Text>
                                </View>

                                <View style={styles.resetButton}>
                                    <TouchableWithoutFeedback onPress={this._toggleReset.bind(this)}>
                                        <Image
                                            source={gameimages['resetgame.png']}
                                            style={styles.resetButtonImage}
                                            resizeMode="contain"
                                        />
                                    </TouchableWithoutFeedback>
                                </View>

                            </View>

                        </ScrollView>
                    </ImageBackground>

                </View>
            );
        }
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        width: deviceWidth,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
    },

    text: {
        fontFamily: 'roboto-regular',
        textAlignVertical: "center",
        textAlign: "center",
        marginLeft: 10,
        fontSize: 16,
        lineHeight: 22,
        padding: 20,
    },

    line: {
        width: deviceWidth * .95,
        height: 2,
        backgroundColor: '#dddddd',
    },

    button: {
        width: '100%',
        height: 70,
    },

    checkboxWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 10,
        marginBottom: 20,
    },

    checkbox: {
        width: 40,
        height: 40
    },

    checkboxText: {
        width: deviceWidth * .8,
        height: 50,
        paddingLeft: 20,
    },

    checkboxImage: {
        width: deviceWidth * .1,
        height: 50,
        margin: 0,
        marginTop: -10,
        marginRight: 20,
        marginBottom: 20
    },

    resetButton: {
        width: 300,
        height: 60,
        zIndex: 4,
        marginBottom: 30,
        marginLeft: -10

    },
    resetButtonImage: {
        width: '100%',
        height: 60,
    },


    weiterButtonWrapper: {
        width: 130,
        height: 60,
        marginBottom: 30,
        marginTop: 130,
    },

    weiterButtonWrapperCenter: {
        width: 130,
        height: 60,
        marginBottom: 30,
        marginTop: 130,
        marginLeft: deviceWidth * 0.17,
    },
    weiterButton: {
        width: '90%',
        height: 60,
        marginTop: 20,
    },

});
