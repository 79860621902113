const gegner = {
    "Konterbunt_Gegneravatare_20_Alternativer_Hippie_2_x1": require("./avatare/Konterbunt_Gegneravatare_20_Alternativer_Hippie_2_x1.png"),

    "Konterbunt_Gegneravatare_20_Alternativer_Hippie_x1": require("./avatare/Konterbunt_Gegneravatare_20_Alternativer_Hippie_x1.png"),

    "Konterbunt_Gegneravatare_19_Business_Mensch_x1": require("./avatare/Konterbunt_Gegneravatare_19_Business_Mensch_x1.png"),

    "Konterbunt_Gegneravatare_18_Hipster_2_x1": require("./avatare/Konterbunt_Gegneravatare_18_Hipster_2_x1.png"),

    "Konterbunt_Gegneravatare_18_Hipster_x1": require("./avatare/Konterbunt_Gegneravatare_18_Hipster_x1.png"),

    "Konterbunt_Gegneravatare_17_Uni_Professor_x1": require("./avatare/Konterbunt_Gegneravatare_17_Uni_Professor_x1.png"),

    "Konterbunt_Gegneravatare_16_Hockey_Spieler_x1": require("./avatare/Konterbunt_Gegneravatare_16_Hockey_Spieler_x1.png"),

    "Konterbunt_Gegneravatare_15_Altenpflegerin_x1": require("./avatare/Konterbunt_Gegneravatare_15_Altenpflegerin_x1.png"),

    "Konterbunt_Gegneravatare_14_Gothic_Nachbarin_x1": require("./avatare/Konterbunt_Gegneravatare_14_Gothic_Nachbarin_x1.png"),

    "Konterbunt_Gegneravatare_13_Handwerkerin_x1": require("./avatare/Konterbunt_Gegneravatare_13_Handwerkerin_x1.png"),

    "Konterbunt_Gegneravatare_12_Pumper_x1": require("./avatare/Konterbunt_Gegneravatare_12_Pumper_x1.png"),

    "Konterbunt_Gegneravatare_11_Intellektueller_Student_2_x1": require("./avatare/Konterbunt_Gegneravatare_11_Intellektueller_Student_2_x1.png"),

    "Konterbunt_Gegneravatare_11_Intellektueller_Student_x1": require("./avatare/Konterbunt_Gegneravatare_11_Intellektueller_Student_x1.png"),

    "Konterbunt_Gegneravatare_10_Buero_Angestellter_2_x1": require("./avatare/Konterbunt_Gegneravatare_10_Buero_Angestellter_2_x1.png"),

    "Konterbunt_Gegneravatare_10_Buero_Angestellter_x1": require("./avatare/Konterbunt_Gegneravatare_10_Buero_Angestellter_x1.png"),

    "Konterbunt_Gegneravatare_09_Busfahrerin_x1": require("./avatare/Konterbunt_Gegneravatare_09_Busfahrerin_x1.png"),

    "Konterbunt_Gegneravatare_08_Selbstverliebter_Kuenstler_x1": require("./avatare/Konterbunt_Gegneravatare_08_Selbstverliebter_Kuenstler_x1.png"),

    "Konterbunt_Gegneravatare_07_Abiturientin_aus_gutem_Hause_x1": require("./avatare/Konterbunt_Gegneravatare_07_Abiturientin_aus_gutem_Hause_x1.png"),

    "Konterbunt_Gegneravatare_06_Grossonkel_Guenther_x1": require("./avatare/Konterbunt_Gegneravatare_06_Grossonkel_Guenther_x1.png"),

    "Konterbunt_Gegneravatare_05_Oma_x1": require("./avatare/Konterbunt_Gegneravatare_05_Oma_x1.png"),

    "Konterbunt_Gegneravatare_04_Supermutti_2_x1": require("./avatare/Konterbunt_Gegneravatare_04_Supermutti_2_x1.png"),

    "Konterbunt_Gegneravatare_02_Vater_Migrationshintergrund_x1": require("./avatare/Konterbunt_Gegneravatare_02_Vater_Migrationshintergrund_x1.png"),

    "Konterbunt_Gegneravatare_01_Jugendlicher_2_x1": require("./avatare/Konterbunt_Gegneravatare_01_Jugendlicher_2_x1.png"),

    "Konterbunt_Gegneravatare_01_Jugendlicher_x1": require("./avatare/Konterbunt_Gegneravatare_01_Jugendlicher_x1.png"),

    "Konterbunt_Gegneravatare_03_Rentner_x1": require("./avatare/Konterbunt_Gegneravatare_03_Rentner_x1.png"),
};

export default gegner;