import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    TextInput,
    Picker,
    Button, TouchableWithoutFeedback, AsyncStorage
} from 'react-native';
import {WebBrowser} from 'expo';

import charactereditor from "../assets/images/images_charactereditor"

const deviceWidth = 380;
const deviceHeight = 768

export default class CharacterEdtiorScreen extends React.Component {

    static navigationOptions = {
        title: "Avatar Editor",
        headerStyle: {
            backgroundColor: '#de1a74'
        },
        headerTintColor: '#fff',
    };

    constructor(props) {
        super(props);
        this.state = {
            strategieGuideElements: require('../assets/json/strategieguide.json'),
            toggle: -1,
            name: '',
            age: 15,
            gender: '',
            keys: 'Avatar_Editor_Icon_Head_',
            smallkeys: 'ch_head_color',
            keysStyle: {
                width: '100%',
                height: 28,
                marginTop: 11,
                marginLeft: 0
            },
            mode: 'kopf',
            avatarKopf: 'Avatar_Head_01_color',
            avatarHaare: 'Avatar_Hair_01_FG_color',
            avatarHaareBG: 'Avatar_Hair_01_BG_color',
            avatarGesicht: 'Avatar_Face_01',
            avatarTorso: 'Avatar_Clothes_01_color',
            avatarAccessoire: 'Avatar_Acc_01',
            avatarKopfColor: 1,
            avatarHaareColor: 1,
            avatarTorsoColor: 1,
        };

    }

    componentDidMount() {

        AsyncStorage.getItem('avatar', (err, result) => {
            if (result === null) {
                this._shuffle();
            } else {
                this.setState(JSON.parse(result))
            }
        });

    }

    _changeAge(number) {

        const age = this.state.age;

        if (number === -1 && age >= 6) {
            this.setState({
                age: age + number
            });
        }

        if (number === 1 && age <= 100) {
            this.setState({
                age: age + number
            });
        }

    };

    _changeMode(mode) {

        // TODO: Mode change

        var keys;
        var keysStyle;
        var smallkeys;

        if (mode === "kopf") {
            keys = "Avatar_Editor_Icon_Head_";
            smallkeys = "ch_head_color"
            keysStyle = {
                width: '100%',
                height: 28,
                marginTop: 11,
                marginLeft: 0
            };
        }

        if (mode === "haare") {
            keys = "Avatar_Editor_Icon_Hair_";
            smallkeys = "ch_hair"
            keysStyle = {
                width: '90%',
                height: 20,
                marginTop: 14,
                marginLeft: 2
            };
        }

        if (mode === "gesicht") {
            keys = "Avatar_Editor_Icon_Face_";
            smallkeys = "ch_accessoires"
            keysStyle = {
                width: '100%',
                height: 28,
                marginTop: 10,
                marginLeft: 0
            };
        }

        if (mode === "torso") {
            keys = "Avatar_Editor_Icon_Clothes_";
            smallkeys = "ch_clothes"
            keysStyle = {
                width: '100%',
                height: 28,
                marginTop: 10,
                marginLeft: 0
            };
        }

        this.setState({mode: mode, keys: keys, keysStyle: keysStyle, smallkeys: smallkeys});

        console.log(mode)
    };

    _change(mode, number) {

        var change;

        if (mode === "kopf") {

            change = {
                avatarKopf: 'Avatar_Head_' + number + '_color'
            }
        }

        if (mode === "haare") {

            change = {
                avatarHaare: 'Avatar_Hair_' + number + '_FG_color',
                avatarHaareBG: 'Avatar_Hair_' + number + '_BG_color'
            }


        }

        if (mode === "gesicht") {

            change = {
                avatarGesicht: 'Avatar_Face_' + number,
            }
        }

        if (mode === "torso") {

            change = {
                avatarTorso: 'Avatar_Clothes_' + number + '_color'
            }
        }

        this.setState(change)

    }

    _changeColor(mode, number) {

        var change;

        if (mode === "kopf") {

            change = {
                avatarKopfColor: parseInt(number)
            }
        }

        if (mode === "haare") {

            change = {
                avatarHaareColor: parseInt(number)
            };

            var avatarAccessoire = this.state.avatarAccessoire;

            if (avatarAccessoire.includes('Avatar_Acc_03')) {
                change = {
                    avatarHaareColor: parseInt(number),
                    avatarAccessoire: 'Avatar_Acc_03' + '_color' + parseInt(number)
                }
            }

            if (avatarAccessoire.includes('Avatar_Acc_04')) {
                change = {
                    avatarHaareColor: parseInt(number),
                    avatarAccessoire: 'Avatar_Acc_04' + '_color' + parseInt(number)
                }
            }
        }

        if (mode === "gesicht") {

            var color = this.state.avatarHaareColor;

            if (number !== '01'
                && number !== '02'
                && number !== '05'
                && number !== '06'
                && number !== '07'
                && number !== '08') {
                change = {
                    avatarAccessoire: 'Avatar_Acc_' + number + '_color' + color
                }
            } else {
                change = {
                    avatarAccessoire: 'Avatar_Acc_' + number
                }
            }

        }

        if (mode === "torso") {

            change = {
                avatarTorsoColor: parseInt(number)
            }
        }

        this.setState(change)
    }

    _saveGoBack(navigation) {
        this._save();
        navigation.goBack(null)
    }

    _save() {

        var avatar = {
            avatarKopf: this.state.avatarKopf,
            avatarHaare: this.state.avatarHaare,
            avatarHaareBG: this.state.avatarHaareBG,
            avatarGesicht: this.state.avatarGesicht,
            avatarTorso: this.state.avatarTorso,
            avatarAccessoire: this.state.avatarAccessoire,
            avatarKopfColor: this.state.avatarKopfColor,
            avatarHaareColor: this.state.avatarHaareColor,
            avatarTorsoColor: this.state.avatarTorsoColor,
            name: this.state.name,
            age: this.state.age,
            gender: this.state.gender,
        };

        console.log("save", avatar)

        AsyncStorage.setItem('avatar', JSON.stringify(avatar));
    }

    _shuffle() {

        var avatarHaareShuffle = Math.floor((Math.random() * 8) + 1);
        var avatarHaareColorShuffle = Math.floor((Math.random() * 8) + 1);
        var avatarTorsoColorShuffle = Math.floor((Math.random() * 8) + 1);
        var avatarKopfColorShuffle = Math.floor((Math.random() * 8) + 1);

        var avatarAccessoireShuffle = Math.floor((Math.random() * 2) + 1);

        var avatarAccessoire;

        if (avatarAccessoireShuffle === 1) {
            avatarAccessoire = 'Avatar_Acc_0' + Math.floor((Math.random() * 8) + 5)
        } else {
            avatarAccessoire = 'Avatar_Acc_0' + Math.floor((Math.random() * 4) + 1) + '_color' + avatarHaareColorShuffle
        }

        this.setState({
            avatarKopf: 'Avatar_Head_0' + Math.floor((Math.random() * 8) + 1) + '_color',
            avatarHaare: 'Avatar_Hair_0' + avatarHaareShuffle + '_FG_color',
            avatarHaareBG: 'Avatar_Hair_0' + avatarHaareShuffle + '_BG_color',
            avatarGesicht: 'Avatar_Face_0' + Math.floor((Math.random() * 8) + 1) + '',
            avatarTorso: 'Avatar_Clothes_0' + Math.floor((Math.random() * 8) + 1) + '_color',
            avatarAccessoire: avatarAccessoire,
            avatarKopfColor: avatarKopfColorShuffle,
            avatarHaareColor: avatarHaareColorShuffle,
            avatarTorsoColor: avatarTorsoColorShuffle,
        })

    }

    render() {

        var navigation = this.props.navigation;

        var mode = this.state.mode;
        var avatarKopf = this.state.avatarKopf;
        var avatarHaare = this.state.avatarHaare;
        var avatarHaareBG = this.state.avatarHaareBG;
        var avatarGesicht = this.state.avatarGesicht;
        var avatarTorso = this.state.avatarTorso;
        var avatarAccessoire = this.state.avatarAccessoire;
        var avatarKopfColor = this.state.avatarKopfColor;
        var avatarHaareColor = this.state.avatarHaareColor;
        var avatarTorsoColor = this.state.avatarTorsoColor;

        return (

            <View style={styles.container}>

                <ImageBackground source={require('../assets/images/buttons/BGWhite3x.png')}
                                 style={{width: '100%', height: '100%'}}>
                    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                        <View style={styles.box}>

                            <View style={styles.headContainer}>

                                <View style={styles.avatar}>

                                    <ImageBackground source={charactereditor['00_Avatar_BG']}
                                                     style={styles.avatarBackground}>

                                        <Image
                                            source={charactereditor[avatarHaareBG + avatarHaareColor]}
                                            style={styles.avatarHair}
                                            resizeMode="contain"
                                        />

                                        <Image
                                            source={charactereditor[avatarKopf + avatarKopfColor]}
                                            style={styles.avatarHead}
                                            resizeMode="contain"
                                        />

                                        <Image
                                            source={charactereditor[avatarTorso + avatarTorsoColor]}
                                            style={styles.avatarClothes}
                                            resizeMode="contain"
                                        />

                                        <Image
                                            source={charactereditor[avatarHaare + avatarHaareColor]}
                                            style={styles.avatarHair}
                                            resizeMode="contain"
                                        />


                                        <Image
                                            source={charactereditor[avatarGesicht]}
                                            style={styles.avatarFace}
                                            resizeMode="contain"
                                        />

                                        <Image
                                            source={charactereditor[avatarAccessoire]}
                                            style={styles.avatarAcc}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>

                                </View>

                                <View style={styles.details}>

                                    <TextInput
                                        placeholder="Name"
                                        style={styles.text}
                                        onChangeText={(text) => this.setState({name: text})}
                                        defaultValue={this.state.name}
                                    />

                                    <View style={styles.age}>
                                        <TouchableOpacity style={styles.touchableLeftRightButton}
                                                          onPress={this._changeAge.bind(this, -1)}>

                                            <Image
                                                source={charactereditor['ch_left']}
                                                style={styles.touchableLeftRightButton}
                                                resizeMode="contain"
                                            /></TouchableOpacity>

                                        <Text
                                            style={styles.label}
                                        >Alter: {this.state.age}</Text>


                                        <TouchableOpacity style={styles.touchableLeftRightButton}
                                                          onPress={this._changeAge.bind(this, 1)}>

                                            <Image
                                                source={charactereditor['ch_right']}
                                                style={styles.touchableLeftRightButton}
                                                resizeMode="contain"
                                            /></TouchableOpacity>
                                    </View>

                                    <View style={styles.pickerView}>

                                        <Picker
                                            style={styles.picker}
                                            selectedValue={(this.state && this.state.gender) || 'f'}
                                            onValueChange={(value) => {
                                                this.setState({gender: value});
                                            }}>
                                            <Picker.Item label={'Weiblich'} value={'f'} />
                                            <Picker.Item label={'Männlich'} value={'m'} />
                                            <Picker.Item label={'Divers'} value={'d'} />
                                        </Picker>

                                    </View>


                                </View>

                            </View>

                            <View style={styles.topContainer}>
                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._changeMode.bind(this, "kopf")}>

                                    <Image
                                        source={charactereditor['ch_kopf']}
                                        style={styles.shortButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>

                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._changeMode.bind(this, "haare")}>

                                    <Image
                                        source={charactereditor['ch_haare']}
                                        style={styles.shortButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>
                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._changeMode.bind(this, "gesicht")}>

                                    <Image
                                        source={charactereditor['ch_gesicht']}
                                        style={styles.shortButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>

                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._changeMode.bind(this, "torso")}>

                                    <Image
                                        source={charactereditor['ch_torso']}
                                        style={styles.shortButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>

                            </View>

                            <View style={styles.mainWrapper}>
                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '01')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '01']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '02')}>


                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '02']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '03')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '03']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '04')}>


                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '04']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>

                                </TouchableOpacity>
                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '05')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '05']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />

                                    </ImageBackground>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '06')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '06']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />
                                    </ImageBackground>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '07')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '07']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />
                                    </ImageBackground>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.touchableMainButton}
                                                  onPress={this._change.bind(this, mode, '08')}>

                                    <ImageBackground source={charactereditor['bg_ch']} resizeMode="contain"
                                                     style={styles.mainButtonWrapper}>

                                        <Image
                                            source={charactereditor[this.state.keys + '08']}
                                            style={this.state.keysStyle}
                                            resizeMode="contain"
                                        />
                                    </ImageBackground>
                                </TouchableOpacity>

                            </View>

                            <View style={styles.imageButtonWrapper}>
                                <ImageBackground source={charactereditor[this.state.smallkeys]} resizeMode="contain"
                                                 style={{width: deviceWidth * .90 - 40, height: 110, marginLeft: 10}}>

                                    <View style={styles.imageButtons}>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '01')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '02')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '03')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '04')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                    </View>


                                    <View style={styles.imageButtonsSecondRow}>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '05')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '06')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '07')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                        <TouchableWithoutFeedback
                                            onPress={this._changeColor.bind(this, mode, '08')}>
                                            <Text style={styles.imageButton}>&nbsp;</Text>
                                        </TouchableWithoutFeedback>
                                    </View>


                                </ImageBackground>
                            </View>

                            <View style={styles.bottomContainer}>

                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={navigation.goBack.bind(this, null)}>
                                    <Image
                                        source={charactereditor['ch_abbrechen']}
                                        style={styles.longButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>


                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._shuffle.bind(this)}>
                                    <Image
                                        source={charactereditor['ch_zufaellig']}
                                        style={styles.longButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>

                                <TouchableOpacity style={styles.touchableButton}
                                                  onPress={this._saveGoBack.bind(this, navigation)}>
                                    <Image
                                        source={charactereditor['ch_fertig']}
                                        style={styles.longButton}
                                        resizeMode="contain"
                                    /></TouchableOpacity>

                            </View>

                        </View>
                    </ScrollView>
                </ImageBackground>
            </View>
        );
    }

}

const styles = StyleSheet.create({

    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        marginTop: -5,
        marginBottom: 2,
        width: deviceWidth * .90,
        marginLeft: 20,
    },

    avatar: {
        width: 90,
        height: 108,
        marginBottom: 10,
    },

    avatarBackground: {
        width: 90,
        height: 108,
    },

    avatarHead: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarClothes: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarHair: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarAcc: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarFace: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    details: {
        marginBottom: 2,
        marginLeft: 13,
        padding: 5,
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: '#f2f2f2',
    },

    text: {
        width: 180,
        height: 40,
        backgroundColor: '#fce5c0',
        overflow: 'hidden',
        borderRadius: 10,
        color: '#7f4f5b',
        fontSize: 16,
        paddingLeft: 20
    },

    age: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 0,
    },

    label: {
        width: 109,
        height: 40,
        backgroundColor: '#fce5c0',
        overflow: 'hidden',
        borderRadius: 10,
        color: '#7f4f5b',
        fontSize: 16,
        marginTop: 5,
        paddingTop: 10,
        marginLeft: 5,
        paddingLeft: 20,
        marginRight: 5

    },

    pickerView: {
        width: 180,
        height: 40,
        backgroundColor: '#fce5c0',
        overflow: 'hidden',
        borderRadius: 10,
        paddingTop: 0,
        paddingLeft: 10,
        marginTop: 3,
    },

    picker: {
        width: '100%',
        height: '100%',
        color: '#7f4f5b',
    },

    pickerText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#7f4f5b',
        textAlign: 'center',
    },

    leftRightButton: {
        width: 30,
        height: 50,
        marginTop: 3
    },

    box: {
        flex: 1,
        padding: 10,
        paddingBottom: 20
    },

    headContainer: {
        width: deviceWidth * .90 - 20,
        height: 100,
        padding: 5,
        marginBottom: 20,

        borderRadius: 10,

        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',

    },

    topContainer: {
        width: deviceWidth * .92 - 20,
        height: 30,
        padding: 5,
        paddingTop:20,
        marginBottom: 10,
        marginTop:50,

        borderRadius: 10,
        backgroundColor: '#f2f2f2',

        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    bottomContainer: {
        width: deviceWidth * .85,
        minHeight: 15,
        padding: 0,
        marginBottom: 0,
        marginTop: 10,

        borderRadius: 10,
        overflow: 'hidden',

        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    touchableMainButton: {
        width: 50,
        height: 55,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 2,
        marginBottom: 2
    },

    touchableButton: {
        flex: 1,
        flexDirection: 'row',
        width: '10%',
        height: 55,
        marginLeft: 5,
        marginRight: 5
    },

    touchableLeftRightButton: {
        flex: 1,
        flexDirection: 'row',
        width: '10%',
        height: 55,
    },

    shortButton: {
        width: '100%',
        height: 55,
    },

    longButton: {
        flex: 1,
        flexDirection: 'row',
        width: '20%',
        height: 48,
        marginLeft: 5,
        marginRight: 5
    },

    mainWrapper: {
        width: deviceWidth * .98 - 40,
        minHeight: 100,
        marginBottom: 0,
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: '#f2f2f2',
        paddingTop: 20,
        paddingBottom: 50,
        paddingLeft: 25,

        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'stretch',
    },

    mainButtonWrapper: {
        width: '100%',
        height: 55,
    },

    imageButtonWrapper: {
        width: deviceWidth * .90 - 20,
        minHeight: 40,
        marginTop:20,
        marginBottom: 10,
        borderRadius: 10,
        overflow: 'hidden',
        backgroundColor: '#f2f2f2',

    },

    imageButtons: {
        position: 'absolute',
        left: 0,
        top: 0,
        marginTop: '5%',
        width: '100%',
        height: '100%',
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    imageButtonsSecondRow: {
        position: 'absolute',
        left: 0,
        top: 0,
        marginTop: '20%',
        width: '100%',
        height: '100%',
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    imageButton: {
        flex: 1,
        width: '30%',
        height: 30,
        marginLeft: 8,
        marginRight: 8,
    },


});
