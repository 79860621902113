import React from 'react';
import {
    Image,
    Button,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    View,
    Dimensions,
    ImageBackground,
    TouchableOpacity, AsyncStorage,
} from 'react-native';
import {WebBrowser} from 'expo';
import {Asset} from 'expo-asset';

const deviceWidth = 380;
const deviceHeight = 768

export default class HomeScreen extends React.Component {
    static navigationOptions = {
        header: null
    };

    constructor(props) {
        super(props);
        this.state = {
            toggle: {
                minispiel: false
            },
            splashscreen: false,
            popup: null,
            info: false,
            message: null,
            messages: null,
            popUpChecked: false,
        };

        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/01_MainTheme_BGM.aac');
    }

    componentDidMount = () => {
        this._componentFocused();

        this._sub = this.props.navigation.addListener(
            'didFocus',
            this._componentFocused
        );


        const messages = JSON.parse(JSON.stringify(require('../assets/json/messages.json')));
        this.setState({messages: messages}, () => {
            AsyncStorage.getItem('popup', (err, result) => {
                if (result === null) {
                    //this._showMessages()
                } else {
                    this.setState({popup: JSON.parse(result)}, () => {
                        //this._showMessages();
                    })
                }
            });

        })


        this._showSplash();
    };

    componentWillUnmount() {
        this._sub.remove();
        this.stopSound()
    }

    _componentFocused = () => {
        if (this.state.splashscreen === false) {

            AsyncStorage.getItem('parolen_settings', (err, result) => {
                if (result !== null) {
                    const settings = JSON.parse(result);
                    if (settings.musikChecked) {
                        this.playSound()
                    }
                } else
                    this.playSound()
            });

        }
    };

    playSound = async () => {

        this.setState({
            play: true,
            pause: false
        });

        this.audioPlayer.currentTime = 0;
        this.audioPlayer.play();

        this.audioPlayer.addEventListener('ended', function () {
            this.currentTime = 0;
            this.play();
        }, false);

    };

    stopSound = async () => {
        this.setState({
            play: false,
            pause: true
        });
        this.audioPlayer.pause();
    };

    _openURL(url) {
        this.stopSound()
        this.props.navigation.navigate(url);
    }

    _showSplash() {

        const splashscreen = this.state.splashscreen;

        setTimeout(() => {

            if (splashscreen === 1) {
                this.setState({splashscreen: 2})
                this._showSplash()
            } else if (splashscreen === 2) {
                this.setState({splashscreen: 3})
                this._showSplash()
            } else {
                this.setState({splashscreen: false})
            }

        }, 3000)

    }

    _showMessages() {

        const messages = JSON.parse(JSON.stringify(require('../assets/json/messages.json')));
        this.setState({messages: messages}, () => {
            AsyncStorage.getItem('popup', (err, result) => {
                if (result === null) {
                   this._showPopUp()
                } else {
                    this.setState({popup: JSON.parse(result)}, () => {
                        this._showPopUp()
                    })
                }
            });

        })



    }

    _showPopUp() {

        var popup = this.state.popup;

        if (popup != null) {

            if (popup.homescreen === undefined && !popup.homescreen === true) {
                this.setState({
                    message: {
                        id: 'homescreen',
                        text: this.state.messages.homescreen,
                        position: 'top'
                    }
                });
            } else {
                this._openURL('Map')
            }

        } else {
            this.setState({
                message: {
                    id: 'homescreen',
                    text: this.state.messages.homescreen,
                    position: 'top'
                }
            });
        }
    }

    _hideMessages() {

        if (this.state.popUpChecked) {

            var popup = this.state.popup;

            if (popup === null) {
                popup = {};
            }

            popup['homescreen'] = true;

            AsyncStorage.setItem('popup', JSON.stringify(popup));

        }


        this.setState({message: null})
    }

    _check() {
        if (this.state.popUpChecked) {
            this.setState({popUpChecked: false});
        } else {
            this.setState({popUpChecked: true});
        }
    }

    render() {

        var popup = null;

        if (this.state.message !== null) {

            var popupPositionStyle = {
                width: deviceWidth * 0.86,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.035,
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'absolute',
                top: 5,
                left: 0,
                zIndex: 20
            };

            var popupPositionTextStyle = {
                fontSize: 15,
                lineHeight: 17
            };

            var checkbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox.png')}
                resizeMode="contain"
            />);

            if (this.state.popUpChecked) {
                checkbox = (<Image
                    style={styles.checkbox}
                    source={require('../assets/images/buttons/checkbox_checked.png')}
                    resizeMode="contain"
                />);
            }

            popup = (
                <View style={{zIndex: 100}}>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{this.state.message.text}</Text>


                            <View style={styles.line}>
                            </View>

                            <View style={styles.checkboxWrapper}>

                                <TouchableOpacity style={styles.checkboxImage}
                                                  onPress={this._check.bind(this)}>
                                    {checkbox}
                                </TouchableOpacity>

                                <Text stlye={styles.checkboxText}>Nicht mehr anzeigen</Text>
                            </View>

                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={() => {
                                              this._openURL('Map');
                                              this._hideMessages()
                                          }}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>)

        }

        if (this.state.info) {

            var infoPositionStyle = {
                width: deviceWidth * 0.86,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.035,
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'absolute',
                top: 5,
                left: 0,
                zIndex: 100
            };

            var infoPositionTextStyle = {
                fontSize: 15,
                lineHeight: 17
            };

            var checkbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox.png')}
                resizeMode="contain"
            />);

            if (this.state.popUpChecked) {
                checkbox = (<Image
                    style={styles.checkbox}
                    source={require('../assets/images/buttons/checkbox_checked.png')}
                    resizeMode="contain"
                />);
            }

            var info = (
                <View style={{zIndex: 100}}>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={infoPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={infoPositionTextStyle}>{this.state.messages.info}</Text>
                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={() => this.setState({info: !this.state.info})}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>)

        }

        if (this.state.splashscreen !== false) {

            if (this.state.splashscreen === 1) {
                return (
                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{width: '100%', height: '100%'}}>
                        <View style={styles.container} contentContainerStyle={styles.contentContainer}>
                            <View style={styles.welcomeContainer}>

                                <View style={styles.container}>
                                    <Image
                                        source={require('../assets/images/splash/waza.png')}
                                        style={styles.splash}
                                        resizeMode="contain"
                                    />
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                )
            } else if (this.state.splashscreen === 2) {
                return (
                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{width: '100%', height: '100%'}}>
                        <View style={styles.container} contentContainerStyle={styles.contentContainer}>
                            <View style={styles.welcomeContainer}>

                                <View style={styles.container}>
                                    <Image
                                        source={require('../assets/images/splash/nlpb.png')}
                                        style={styles.splash}
                                        resizeMode="contain"
                                    />
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                )
            } else {
                return (
                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{width: '100%', height: '100%'}}>
                        <View style={styles.container} contentContainerStyle={styles.contentContainer}>
                            <View style={styles.welcomeContainer}>

                                <View style={styles.container}>
                                    <Image
                                        source={require('../assets/images/splash/konterbunt.png')}
                                        style={styles.splash}
                                        resizeMode="contain"
                                    />
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                )
            }

        }

        return (
            <View style={styles.container}>

                <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                 style={{width: '100%', height: '100%'}}>
                    {popup}

                    {info}

                    <View style={styles.container} contentContainerStyle={styles.contentContainer}>


                        <View style={styles.welcomeContainer}>
                            <Image
                                source={require('../assets/images/logos/Konterbunt_Logo_Subline_Colr_RGB_Medium.png')}
                                style={styles.logo}
                                resizeMode="contain"
                            />

                            <TouchableOpacity
                                onPress={() => this._showMessages()} //onPress={()=>this.setState({toggle:!this.state.toggle.minispiel})}>
                            >
                                {
                                    this.state.toggle.minispiel == false ?
                                        <Image source={require('../assets/images/buttons/minispiel.png')}
                                               style={styles.button}
                                               resizeMode="contain"
                                        />
                                        :
                                        <Image source={require('../assets/images/buttons/minispiel_down.png')}
                                               style={styles.button}
                                               resizeMode="contain"
                                        />

                                }
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => this._openURL('ParolenVerzeichnis')}>

                                <Image
                                    source={require('../assets/images/buttons/parolenverzeichnis.png')}
                                    style={styles.button}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => this._openURL('StrategieGuide')}>

                                <Image
                                    source={require('../assets/images/buttons/strategieguide.png')}
                                    style={styles.button}
                                    resizeMode="contain"
                                />

                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => this._openURL('CharacterEditor')}>

                                <Image
                                    source={require('../assets/images/buttons/avatar.png')}
                                    style={styles.button}
                                    resizeMode="contain"
                                />

                            </TouchableOpacity>


                            <View style={{flex: 1, flexDirection: 'row', width: '100%'}}>
                                <TouchableOpacity onPress={() => this._openURL('CreditsScreen')}>

                                    <Image
                                        source={require('../assets/images/buttons/credits.png')}
                                        style={styles.creditsButton}
                                        resizeMode="contain"
                                    />

                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => this._openURL('SettingsScreen')}>

                                    <Image
                                        source={require('../assets/images/buttons/settings.png')}
                                        style={styles.settingsButton}
                                        resizeMode="contain"
                                    />

                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => this.setState({info: !this.state.info})}>

                                    <Image
                                        source={require('../assets/images/buttons/info.png')}
                                        style={styles.infoButton}
                                        resizeMode="contain"
                                    />

                                </TouchableOpacity>
                            </View>
                        </View>


                    </View>
                </ImageBackground>

            </View>
        );
    }

    _maybeRenderDevelopmentModeWarning() {
        if (__DEV__) {
            const learnMoreButton = (
                <Text onPress={this._handleLearnMorePress} style={styles.helpLinkText}>
                    Learn more
                </Text>
            );

            return (
                <Text style={styles.developmentModeText}>
                    Development mode is enabled, your app will be slower but you can use useful development
                    tools. {learnMoreButton}
                </Text>
            );
        } else {
            return (
                <Text style={styles.developmentModeText}>
                    You are not in development mode, your app will run at full speed.
                </Text>
            );
        }
    }

    _handleLearnMorePress = () => {
        WebBrowser.openBrowserAsync('https://docs.expo.io/versions/latest/guides/development-mode');
    };

    _handleHelpPress = () => {
        WebBrowser.openBrowserAsync(
            'https://docs.expo.io/versions/latest/guides/up-and-running.html#can-t-see-your-changes'
        );
    };
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: 380,
        zIndex: 2,
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    contentContainer: {
        paddingTop: 30,
        zIndex: 2,
    },
    welcomeContainer: {
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 20,
        zIndex:30
    },
    welcomeImage: {
        width: 100,
        height: 80,
        resizeMode: 'contain',
        marginTop: 3,
        marginLeft: -10,
    },
    getStartedContainer: {
        alignItems: 'center',
        marginHorizontal: 50,
    },
    homeScreenFilename: {
        marginVertical: 7,
    },
    codeHighlightText: {
        color: 'rgba(96,100,109, 0.8)',
    },
    codeHighlightContainer: {
        backgroundColor: 'rgba(0,0,0,0.05)',
        borderRadius: 3,
        paddingHorizontal: 4,
    },
    getStartedText: {
        fontSize: 17,
        color: 'rgba(96,100,109, 1)',
        lineHeight: 24,
        textAlign: 'center',
        fontFamily: 'roboto-regular',
    },
    tabBarInfoContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        ...Platform.select({
            ios: {
                shadowColor: 'black',
                shadowOffset: {height: -3},
                shadowOpacity: 0.1,
                shadowRadius: 3,
            },
            android: {
                elevation: 20,
            },
        }),
        alignItems: 'center',
        backgroundColor: '#fbfbfb',
        paddingVertical: 20,
    },
    tabBarInfoText: {
        fontSize: 17,
        color: 'rgba(96,100,109, 1)',
        textAlign: 'center',
    },
    navigationFilename: {
        marginTop: 5,
    },
    helpContainer: {
        marginTop: 15,
        alignItems: 'center',
    },
    helpLink: {
        paddingVertical: 15,
    },
    helpLinkText: {
        fontSize: 14,
        color: '#2e78b7',
    },
    button: {
        width: deviceWidth * .9,
        height: 85,
        marginTop: 5,
        zIndex:50

    },
    creditsButton: {
        width: 130,
        height: 45,
        marginTop: 20,
        marginLeft: 140,
        alignItems: 'flex-end',
    },
    settingsButton: {
        width: 50,
        height: 45,
        marginTop: 20,
        marginLeft: -15
    },
    infoButton: {
        width: 50,
        height: 45,
        marginTop: 20,
        marginLeft: -15
    },
    logo: {
        width: deviceWidth * 1.0,
        height: 110,
        marginTop: 50
    },
    splash: {
        width: deviceWidth * 1.0,
        height: 110,
        marginTop: deviceHeight * 0.5 - 50
    },

    pauseOverlay: {
        width: deviceWidth,
        height: deviceHeight,
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 8,
        opacity: 0.5,
        overflow: 'hidden',

    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
        zIndex:50
    },


    weiterButtonWrapper: {
        width: 140,
        height: 70,
        marginBottom: 30,
        marginTop: 130,
        marginLeft: deviceWidth * 0.25,
        zIndex:100,
    },

    weiterButton: {
        width: '90%',
        height: 60,
        marginTop: 20,
        position:'relative',
        zIndex:80
    },

    line: {
        width: '95%',
        marginLeft: '3%',
        height: 3,
        backgroundColor: '#f2f2f2',
        marginTop: 20,
        marginBottom: 10
    },

    checkboxWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 10,
        marginBottom: 20,
    },

    checkbox: {
        width: 40,
        height: 40
    },

    checkboxText: {
        width: deviceWidth * .8,
        height: 50,
        paddingLeft: 20,
    },

    checkboxImage: {
        width: deviceWidth * .1,
        height: 50,
        margin: 0,
        marginTop: -10,
        marginRight: 20,
        marginBottom: 20
    },
});
