const charactereditor = {
    '00_Avatar_BG': require('./charactereditor/00_Avatar_BG.png'),
    'accessoire': require('./charactereditor/accessoire.png'),
    'Avatar_Editor_Icon_Clothes_01': require('./charactereditor/Avatar_Editor_Icon_Clothes_01.png'),
    'Avatar_Editor_Icon_Clothes_02': require('./charactereditor/Avatar_Editor_Icon_Clothes_02.png'),
    'Avatar_Editor_Icon_Clothes_03': require('./charactereditor/Avatar_Editor_Icon_Clothes_03.png'),
    'Avatar_Editor_Icon_Clothes_04': require('./charactereditor/Avatar_Editor_Icon_Clothes_04.png'),
    'Avatar_Editor_Icon_Clothes_05': require('./charactereditor/Avatar_Editor_Icon_Clothes_05.png'),
    'Avatar_Editor_Icon_Clothes_06': require('./charactereditor/Avatar_Editor_Icon_Clothes_06.png'),
    'Avatar_Editor_Icon_Clothes_07': require('./charactereditor/Avatar_Editor_Icon_Clothes_07.png'),
    'Avatar_Editor_Icon_Clothes_08': require('./charactereditor/Avatar_Editor_Icon_Clothes_08.png'),
    'Avatar_Editor_Icon_Face_01': require('./charactereditor/Avatar_Editor_Icon_Face_01.png'),
    'Avatar_Editor_Icon_Face_02': require('./charactereditor/Avatar_Editor_Icon_Face_02.png'),
    'Avatar_Editor_Icon_Face_03': require('./charactereditor/Avatar_Editor_Icon_Face_03.png'),
    'Avatar_Editor_Icon_Face_04': require('./charactereditor/Avatar_Editor_Icon_Face_04.png'),
    'Avatar_Editor_Icon_Face_05': require('./charactereditor/Avatar_Editor_Icon_Face_05.png'),
    'Avatar_Editor_Icon_Face_06': require('./charactereditor/Avatar_Editor_Icon_Face_06.png'),
    'Avatar_Editor_Icon_Face_07': require('./charactereditor/Avatar_Editor_Icon_Face_07.png'),
    'Avatar_Editor_Icon_Face_08': require('./charactereditor/Avatar_Editor_Icon_Face_08.png'),
    'Avatar_Editor_Icon_Hair_01': require('./charactereditor/Avatar_Editor_Icon_Hair_01.png'),
    'Avatar_Editor_Icon_Hair_02': require('./charactereditor/Avatar_Editor_Icon_Hair_02.png'),
    'Avatar_Editor_Icon_Hair_03': require('./charactereditor/Avatar_Editor_Icon_Hair_03.png'),
    'Avatar_Editor_Icon_Hair_05': require('./charactereditor/Avatar_Editor_Icon_Hair_05.png'),
    'Avatar_Editor_Icon_Hair_06': require('./charactereditor/Avatar_Editor_Icon_Hair_06.png'),
    'Avatar_Editor_Icon_Hair_07': require('./charactereditor/Avatar_Editor_Icon_Hair_07.png'),
    'Avatar_Editor_Icon_Hair_08': require('./charactereditor/Avatar_Editor_Icon_Hair_08.png'),
    'Avatar_Editor_Icon_Hair_04': require('./charactereditor/Avatar_Editor_Icon_Hair_04.png'),
    'Avatar_Editor_Icon_Head_01': require('./charactereditor/Avatar_Editor_Icon_Head_01.png'),
    'Avatar_Editor_Icon_Head_02': require('./charactereditor/Avatar_Editor_Icon_Head_02.png'),
    'Avatar_Editor_Icon_Head_03': require('./charactereditor/Avatar_Editor_Icon_Head_03.png'),
    'Avatar_Editor_Icon_Head_04': require('./charactereditor/Avatar_Editor_Icon_Head_04.png'),
    'Avatar_Editor_Icon_Head_05': require('./charactereditor/Avatar_Editor_Icon_Head_05.png'),
    'Avatar_Editor_Icon_Head_06': require('./charactereditor/Avatar_Editor_Icon_Head_06.png'),
    'Avatar_Editor_Icon_Head_07': require('./charactereditor/Avatar_Editor_Icon_Head_07.png'),
    'Avatar_Editor_Icon_Head_08': require('./charactereditor/Avatar_Editor_Icon_Head_08.png'),
    'Avatar_Acc_01': require('./charactereditor/Avatar_Acc_01.png'),
    'Avatar_Acc_02': require('./charactereditor/Avatar_Acc_02.png'),
    'Avatar_Acc_03_color1': require('./charactereditor/Avatar_Acc_03_color1.png'),
    'Avatar_Acc_03_color2': require('./charactereditor/Avatar_Acc_03_color2.png'),
    'Avatar_Acc_03_color3': require('./charactereditor/Avatar_Acc_03_color3.png'),
    'Avatar_Acc_03_color4': require('./charactereditor/Avatar_Acc_03_color4.png'),
    'Avatar_Acc_03_color5': require('./charactereditor/Avatar_Acc_03_color5.png'),
    'Avatar_Acc_03_color6': require('./charactereditor/Avatar_Acc_03_color6.png'),
    'Avatar_Acc_03_color7': require('./charactereditor/Avatar_Acc_03_color7.png'),
    'Avatar_Acc_03_color8': require('./charactereditor/Avatar_Acc_03_color8.png'),
    'Avatar_Acc_04_color1': require('./charactereditor/Avatar_Acc_04_color1.png'),
    'Avatar_Acc_04_color2': require('./charactereditor/Avatar_Acc_04_color2.png'),
    'Avatar_Acc_04_color3': require('./charactereditor/Avatar_Acc_04_color3.png'),
    'Avatar_Acc_04_color4': require('./charactereditor/Avatar_Acc_04_color4.png'),
    'Avatar_Acc_04_color5': require('./charactereditor/Avatar_Acc_04_color5.png'),
    'Avatar_Acc_04_color6': require('./charactereditor/Avatar_Acc_04_color6.png'),
    'Avatar_Acc_04_color7': require('./charactereditor/Avatar_Acc_04_color7.png'),
    'Avatar_Acc_04_color8': require('./charactereditor/Avatar_Acc_04_color8.png'),
    'Avatar_Acc_05': require('./charactereditor/Avatar_Acc_05.png'),
    'Avatar_Acc_06': require('./charactereditor/Avatar_Acc_06.png'),
    'Avatar_Acc_07': require('./charactereditor/Avatar_Acc_07.png'),
    'Avatar_Acc_08': require('./charactereditor/Avatar_Acc_08.png'),
    'Avatar_Clothes_01_color1': require('./charactereditor/Avatar_Clothes_01_color1.png'),
    'Avatar_Clothes_01_color2': require('./charactereditor/Avatar_Clothes_01_color2.png'),
    'Avatar_Clothes_01_color3': require('./charactereditor/Avatar_Clothes_01_color3.png'),
    'Avatar_Clothes_01_color4': require('./charactereditor/Avatar_Clothes_01_color4.png'),
    'Avatar_Clothes_01_color5': require('./charactereditor/Avatar_Clothes_01_color5.png'),
    'Avatar_Clothes_01_color6': require('./charactereditor/Avatar_Clothes_01_color6.png'),
    'Avatar_Clothes_01_color7': require('./charactereditor/Avatar_Clothes_01_color7.png'),
    'Avatar_Clothes_01_color8': require('./charactereditor/Avatar_Clothes_01_color8.png'),
    'Avatar_Clothes_02_color1': require('./charactereditor/Avatar_Clothes_02_color1.png'),
    'Avatar_Clothes_02_color2': require('./charactereditor/Avatar_Clothes_02_color2.png'),
    'Avatar_Clothes_02_color3': require('./charactereditor/Avatar_Clothes_02_color3.png'),
    'Avatar_Clothes_02_color4': require('./charactereditor/Avatar_Clothes_02_color4.png'),
    'Avatar_Clothes_02_color5': require('./charactereditor/Avatar_Clothes_02_color5.png'),
    'Avatar_Clothes_02_color6': require('./charactereditor/Avatar_Clothes_02_color6.png'),
    'Avatar_Clothes_02_color7': require('./charactereditor/Avatar_Clothes_02_color7.png'),
    'Avatar_Clothes_02_color8': require('./charactereditor/Avatar_Clothes_02_color8.png'),
    'Avatar_Clothes_03_color1': require('./charactereditor/Avatar_Clothes_03_color1.png'),
    'Avatar_Clothes_03_color2': require('./charactereditor/Avatar_Clothes_03_color2.png'),
    'Avatar_Clothes_03_color3': require('./charactereditor/Avatar_Clothes_03_color3.png'),
    'Avatar_Clothes_03_color4': require('./charactereditor/Avatar_Clothes_03_color4.png'),
    'Avatar_Clothes_03_color5': require('./charactereditor/Avatar_Clothes_03_color5.png'),
    'Avatar_Clothes_03_color6': require('./charactereditor/Avatar_Clothes_03_color6.png'),
    'Avatar_Clothes_03_color7': require('./charactereditor/Avatar_Clothes_03_color7.png'),
    'Avatar_Clothes_03_color8': require('./charactereditor/Avatar_Clothes_03_color8.png'),
    'Avatar_Clothes_04_color1': require('./charactereditor/Avatar_Clothes_04_color1.png'),
    'Avatar_Clothes_04_color2': require('./charactereditor/Avatar_Clothes_04_color2.png'),
    'Avatar_Clothes_04_color3': require('./charactereditor/Avatar_Clothes_04_color3.png'),
    'Avatar_Clothes_04_color4': require('./charactereditor/Avatar_Clothes_04_color4.png'),
    'Avatar_Clothes_04_color5': require('./charactereditor/Avatar_Clothes_04_color5.png'),
    'Avatar_Clothes_04_color6': require('./charactereditor/Avatar_Clothes_04_color6.png'),
    'Avatar_Clothes_04_color7': require('./charactereditor/Avatar_Clothes_04_color7.png'),
    'Avatar_Clothes_04_color8': require('./charactereditor/Avatar_Clothes_04_color8.png'),
    'Avatar_Clothes_05_color1': require('./charactereditor/Avatar_Clothes_05_color1.png'),
    'Avatar_Clothes_05_color2': require('./charactereditor/Avatar_Clothes_05_color2.png'),
    'Avatar_Clothes_05_color3': require('./charactereditor/Avatar_Clothes_05_color3.png'),
    'Avatar_Clothes_05_color4': require('./charactereditor/Avatar_Clothes_05_color4.png'),
    'Avatar_Clothes_05_color5': require('./charactereditor/Avatar_Clothes_05_color5.png'),
    'Avatar_Clothes_05_color6': require('./charactereditor/Avatar_Clothes_05_color6.png'),
    'Avatar_Clothes_05_color7': require('./charactereditor/Avatar_Clothes_05_color7.png'),
    'Avatar_Clothes_05_color8': require('./charactereditor/Avatar_Clothes_05_color8.png'),
    'Avatar_Clothes_06_color1': require('./charactereditor/Avatar_Clothes_06_color1.png'),
    'Avatar_Clothes_06_color2': require('./charactereditor/Avatar_Clothes_06_color2.png'),
    'Avatar_Clothes_06_color3': require('./charactereditor/Avatar_Clothes_06_color3.png'),
    'Avatar_Clothes_06_color4': require('./charactereditor/Avatar_Clothes_06_color4.png'),
    'Avatar_Clothes_06_color5': require('./charactereditor/Avatar_Clothes_06_color5.png'),
    'Avatar_Clothes_06_color6': require('./charactereditor/Avatar_Clothes_06_color6.png'),
    'Avatar_Clothes_06_color7': require('./charactereditor/Avatar_Clothes_06_color7.png'),
    'Avatar_Clothes_06_color8': require('./charactereditor/Avatar_Clothes_06_color8.png'),
    'Avatar_Clothes_07_color1': require('./charactereditor/Avatar_Clothes_07_color1.png'),
    'Avatar_Clothes_07_color2': require('./charactereditor/Avatar_Clothes_07_color2.png'),
    'Avatar_Clothes_07_color3': require('./charactereditor/Avatar_Clothes_07_color3.png'),
    'Avatar_Clothes_07_color4': require('./charactereditor/Avatar_Clothes_07_color4.png'),
    'Avatar_Clothes_07_color5': require('./charactereditor/Avatar_Clothes_07_color5.png'),
    'Avatar_Clothes_07_color6': require('./charactereditor/Avatar_Clothes_07_color6.png'),
    'Avatar_Clothes_07_color7': require('./charactereditor/Avatar_Clothes_07_color7.png'),
    'Avatar_Clothes_07_color8': require('./charactereditor/Avatar_Clothes_07_color8.png'),
    'Avatar_Clothes_08_color1': require('./charactereditor/Avatar_Clothes_08_color1.png'),
    'Avatar_Clothes_08_color2': require('./charactereditor/Avatar_Clothes_08_color2.png'),
    'Avatar_Clothes_08_color3': require('./charactereditor/Avatar_Clothes_08_color3.png'),
    'Avatar_Clothes_08_color4': require('./charactereditor/Avatar_Clothes_08_color4.png'),
    'Avatar_Clothes_08_color5': require('./charactereditor/Avatar_Clothes_08_color5.png'),
    'Avatar_Clothes_08_color6': require('./charactereditor/Avatar_Clothes_08_color6.png'),
    'Avatar_Clothes_08_color7': require('./charactereditor/Avatar_Clothes_08_color7.png'),
    'Avatar_Clothes_08_color8_2': require('./charactereditor/Avatar_Clothes_08_color8_2.png'),
    'Avatar_Clothes_08_color8': require('./charactereditor/Avatar_Clothes_08_color8.png'),
    'Avatar_Face_01': require('./charactereditor/Avatar_Face_01.png'),
    'Avatar_Face_02': require('./charactereditor/Avatar_Face_02.png'),
    'Avatar_Face_03': require('./charactereditor/Avatar_Face_03.png'),
    'Avatar_Face_04': require('./charactereditor/Avatar_Face_04.png'),
    'Avatar_Face_05': require('./charactereditor/Avatar_Face_05.png'),
    'Avatar_Face_06': require('./charactereditor/Avatar_Face_06.png'),
    'Avatar_Face_07': require('./charactereditor/Avatar_Face_07.png'),
    'Avatar_Face_08': require('./charactereditor/Avatar_Face_08.png'),
    'Avatar_Hair_01_FG_color1': require('./charactereditor/Avatar_Hair_01_FG_color1.png'),
    'Avatar_Hair_01_FG_color2': require('./charactereditor/Avatar_Hair_01_FG_color2.png'),
    'Avatar_Hair_01_FG_color3': require('./charactereditor/Avatar_Hair_01_FG_color3.png'),
    'Avatar_Hair_01_FG_color4': require('./charactereditor/Avatar_Hair_01_FG_color4.png'),
    'Avatar_Hair_01_FG_color5': require('./charactereditor/Avatar_Hair_01_FG_color5.png'),
    'Avatar_Hair_01_FG_color6': require('./charactereditor/Avatar_Hair_01_FG_color6.png'),
    'Avatar_Hair_01_FG_color7': require('./charactereditor/Avatar_Hair_01_FG_color7.png'),
    'Avatar_Hair_01_FG_color8': require('./charactereditor/Avatar_Hair_01_FG_color8.png'),
    'Avatar_Hair_02_FG_color1': require('./charactereditor/Avatar_Hair_02_FG_color1.png'),
    'Avatar_Hair_02_FG_color2': require('./charactereditor/Avatar_Hair_02_FG_color2.png'),
    'Avatar_Hair_02_FG_color3': require('./charactereditor/Avatar_Hair_02_FG_color3.png'),
    'Avatar_Hair_02_FG_color4': require('./charactereditor/Avatar_Hair_02_FG_color4.png'),
    'Avatar_Hair_02_FG_color5': require('./charactereditor/Avatar_Hair_02_FG_color5.png'),
    'Avatar_Hair_02_FG_color6': require('./charactereditor/Avatar_Hair_02_FG_color6.png'),
    'Avatar_Hair_02_FG_color7': require('./charactereditor/Avatar_Hair_02_FG_color7.png'),
    'Avatar_Hair_02_FG_color8': require('./charactereditor/Avatar_Hair_02_FG_color8.png'),
    'Avatar_Hair_03_FG_color1': require('./charactereditor/Avatar_Hair_03_FG_color1.png'),
    'Avatar_Hair_03_FG_color2': require('./charactereditor/Avatar_Hair_03_FG_color2.png'),
    'Avatar_Hair_03_FG_color3': require('./charactereditor/Avatar_Hair_03_FG_color3.png'),
    'Avatar_Hair_03_FG_color4': require('./charactereditor/Avatar_Hair_03_FG_color4.png'),
    'Avatar_Hair_03_FG_color5': require('./charactereditor/Avatar_Hair_03_FG_color5.png'),
    'Avatar_Hair_03_FG_color6': require('./charactereditor/Avatar_Hair_03_FG_color6.png'),
    'Avatar_Hair_03_FG_color7': require('./charactereditor/Avatar_Hair_03_FG_color7.png'),
    'Avatar_Hair_03_FG_color8': require('./charactereditor/Avatar_Hair_03_FG_color8.png'),
    'Avatar_Hair_04_FG_color1': require('./charactereditor/Avatar_Hair_04_FG_color1.png'),
    'Avatar_Hair_04_FG_color2': require('./charactereditor/Avatar_Hair_04_FG_color2.png'),
    'Avatar_Hair_04_FG_color3': require('./charactereditor/Avatar_Hair_04_FG_color3.png'),
    'Avatar_Hair_04_FG_color4': require('./charactereditor/Avatar_Hair_04_FG_color4.png'),
    'Avatar_Hair_04_FG_color5': require('./charactereditor/Avatar_Hair_04_FG_color5.png'),
    'Avatar_Hair_04_FG_color6': require('./charactereditor/Avatar_Hair_04_FG_color6.png'),
    'Avatar_Hair_04_FG_color7': require('./charactereditor/Avatar_Hair_04_FG_color7.png'),
    'Avatar_Hair_04_FG_color8': require('./charactereditor/Avatar_Hair_04_FG_color8.png'),
    'Avatar_Hair_05_FG_color1': require('./charactereditor/Avatar_Hair_05_FG_color1.png'),
    'Avatar_Hair_05_FG_color2': require('./charactereditor/Avatar_Hair_05_FG_color2.png'),
    'Avatar_Hair_05_FG_color3': require('./charactereditor/Avatar_Hair_05_FG_color3.png'),
    'Avatar_Hair_05_FG_color4': require('./charactereditor/Avatar_Hair_05_FG_color4.png'),
    'Avatar_Hair_05_FG_color5': require('./charactereditor/Avatar_Hair_05_FG_color5.png'),
    'Avatar_Hair_05_FG_color6': require('./charactereditor/Avatar_Hair_05_FG_color6.png'),
    'Avatar_Hair_05_FG_color7': require('./charactereditor/Avatar_Hair_05_FG_color7.png'),
    'Avatar_Hair_05_FG_color8': require('./charactereditor/Avatar_Hair_05_FG_color8.png'),
    'Avatar_Hair_06_BG_color1': require('./charactereditor/Avatar_Hair_06_BG_color1.png'),
    'Avatar_Hair_06_BG_color2': require('./charactereditor/Avatar_Hair_06_BG_color2.png'),
    'Avatar_Hair_06_BG_color3': require('./charactereditor/Avatar_Hair_06_BG_color3.png'),
    'Avatar_Hair_06_BG_color4': require('./charactereditor/Avatar_Hair_06_BG_color4.png'),
    'Avatar_Hair_06_BG_color5': require('./charactereditor/Avatar_Hair_06_BG_color5.png'),
    'Avatar_Hair_06_BG_color6': require('./charactereditor/Avatar_Hair_06_BG_color6.png'),
    'Avatar_Hair_06_BG_color7': require('./charactereditor/Avatar_Hair_06_BG_color7.png'),
    'Avatar_Hair_06_BG_color8': require('./charactereditor/Avatar_Hair_06_BG_color8.png'),
    'Avatar_Hair_06_FG_color1': require('./charactereditor/Avatar_Hair_06_FG_color1.png'),
    'Avatar_Hair_06_FG_color2': require('./charactereditor/Avatar_Hair_06_FG_color2.png'),
    'Avatar_Hair_06_FG_color3': require('./charactereditor/Avatar_Hair_06_FG_color3.png'),
    'Avatar_Hair_06_FG_color4': require('./charactereditor/Avatar_Hair_06_FG_color4.png'),
    'Avatar_Hair_06_FG_color5': require('./charactereditor/Avatar_Hair_06_FG_color5.png'),
    'Avatar_Hair_06_FG_color6': require('./charactereditor/Avatar_Hair_06_FG_color6.png'),
    'Avatar_Hair_06_FG_color7': require('./charactereditor/Avatar_Hair_06_FG_color7.png'),
    'Avatar_Hair_06_FG_color8': require('./charactereditor/Avatar_Hair_06_FG_color8.png'),
    'Avatar_Hair_07_BG_color1': require('./charactereditor/Avatar_Hair_07_BG_color1.png'),
    'Avatar_Hair_07_BG_color2': require('./charactereditor/Avatar_Hair_07_BG_color2.png'),
    'Avatar_Hair_07_BG_color3': require('./charactereditor/Avatar_Hair_07_BG_color3.png'),
    'Avatar_Hair_07_BG_color4': require('./charactereditor/Avatar_Hair_07_BG_color4.png'),
    'Avatar_Hair_07_BG_color5': require('./charactereditor/Avatar_Hair_07_BG_color5.png'),
    'Avatar_Hair_07_BG_color6': require('./charactereditor/Avatar_Hair_07_BG_color6.png'),
    'Avatar_Hair_07_BG_color7': require('./charactereditor/Avatar_Hair_07_BG_color7.png'),
    'Avatar_Hair_07_BG_color8': require('./charactereditor/Avatar_Hair_07_BG_color8.png'),
    'Avatar_Hair_07_FG_color1': require('./charactereditor/Avatar_Hair_07_FG_color1.png'),
    'Avatar_Hair_07_FG_color2': require('./charactereditor/Avatar_Hair_07_FG_color2.png'),
    'Avatar_Hair_07_FG_color3': require('./charactereditor/Avatar_Hair_07_FG_color3.png'),
    'Avatar_Hair_07_FG_color4': require('./charactereditor/Avatar_Hair_07_FG_color4.png'),
    'Avatar_Hair_07_FG_color5': require('./charactereditor/Avatar_Hair_07_FG_color5.png'),
    'Avatar_Hair_07_FG_color6': require('./charactereditor/Avatar_Hair_07_FG_color6.png'),
    'Avatar_Hair_07_FG_color7': require('./charactereditor/Avatar_Hair_07_FG_color7.png'),
    'Avatar_Hair_07_FG_color8': require('./charactereditor/Avatar_Hair_07_FG_color8.png'),
    'Avatar_Head_01_color1': require('./charactereditor/Avatar_Head_01_color1.png'),
    'Avatar_Head_01_color2': require('./charactereditor/Avatar_Head_01_color2.png'),
    'Avatar_Head_01_color3': require('./charactereditor/Avatar_Head_01_color3.png'),
    'Avatar_Head_01_color4': require('./charactereditor/Avatar_Head_01_color4.png'),
    'Avatar_Head_01_color5': require('./charactereditor/Avatar_Head_01_color5.png'),
    'Avatar_Head_01_color6': require('./charactereditor/Avatar_Head_01_color6.png'),
    'Avatar_Head_01_color7': require('./charactereditor/Avatar_Head_01_color7.png'),
    'Avatar_Head_01_color8': require('./charactereditor/Avatar_Head_01_color8.png'),
    'Avatar_Head_02_color1': require('./charactereditor/Avatar_Head_02_color1.png'),
    'Avatar_Head_02_color2': require('./charactereditor/Avatar_Head_02_color2.png'),
    'Avatar_Head_02_color3': require('./charactereditor/Avatar_Head_02_color3.png'),
    'Avatar_Head_02_color4': require('./charactereditor/Avatar_Head_02_color4.png'),
    'Avatar_Head_02_color5': require('./charactereditor/Avatar_Head_02_color5.png'),
    'Avatar_Head_02_color6': require('./charactereditor/Avatar_Head_02_color6.png'),
    'Avatar_Head_02_color7': require('./charactereditor/Avatar_Head_02_color7.png'),
    'Avatar_Head_02_color8': require('./charactereditor/Avatar_Head_02_color8.png'),
    'Avatar_Head_03_color1': require('./charactereditor/Avatar_Head_03_color1.png'),
    'Avatar_Head_03_color2': require('./charactereditor/Avatar_Head_03_color2.png'),
    'Avatar_Head_03_color3': require('./charactereditor/Avatar_Head_03_color3.png'),
    'Avatar_Head_03_color4': require('./charactereditor/Avatar_Head_03_color4.png'),
    'Avatar_Head_03_color5': require('./charactereditor/Avatar_Head_03_color5.png'),
    'Avatar_Head_03_color6': require('./charactereditor/Avatar_Head_03_color6.png'),
    'Avatar_Head_03_color7': require('./charactereditor/Avatar_Head_03_color7.png'),
    'Avatar_Head_03_color8': require('./charactereditor/Avatar_Head_03_color8.png'),
    'Avatar_Head_04_color1': require('./charactereditor/Avatar_Head_04_color1.png'),
    'Avatar_Head_04_color2': require('./charactereditor/Avatar_Head_04_color2.png'),
    'Avatar_Head_04_color3': require('./charactereditor/Avatar_Head_04_color3.png'),
    'Avatar_Head_04_color4': require('./charactereditor/Avatar_Head_04_color4.png'),
    'Avatar_Head_04_color5': require('./charactereditor/Avatar_Head_04_color5.png'),
    'Avatar_Head_04_color6': require('./charactereditor/Avatar_Head_04_color6.png'),
    'Avatar_Head_04_color7': require('./charactereditor/Avatar_Head_04_color7.png'),
    'Avatar_Head_04_color8': require('./charactereditor/Avatar_Head_04_color8.png'),
    'Avatar_Head_05_color1': require('./charactereditor/Avatar_Head_05_color1.png'),
    'Avatar_Head_05_color2': require('./charactereditor/Avatar_Head_05_color2.png'),
    'Avatar_Head_05_color3': require('./charactereditor/Avatar_Head_05_color3.png'),
    'Avatar_Head_05_color4': require('./charactereditor/Avatar_Head_05_color4.png'),
    'Avatar_Head_05_color5': require('./charactereditor/Avatar_Head_05_color5.png'),
    'Avatar_Head_05_color6': require('./charactereditor/Avatar_Head_05_color6.png'),
    'Avatar_Head_05_color7': require('./charactereditor/Avatar_Head_05_color7.png'),
    'Avatar_Head_05_color8': require('./charactereditor/Avatar_Head_05_color8.png'),
    'Avatar_Head_06_color1': require('./charactereditor/Avatar_Head_06_color1.png'),
    'Avatar_Head_06_color2': require('./charactereditor/Avatar_Head_06_color2.png'),
    'Avatar_Head_06_color3': require('./charactereditor/Avatar_Head_06_color3.png'),
    'Avatar_Head_06_color4': require('./charactereditor/Avatar_Head_06_color4.png'),
    'Avatar_Head_06_color5': require('./charactereditor/Avatar_Head_06_color5.png'),
    'Avatar_Head_06_color6': require('./charactereditor/Avatar_Head_06_color6.png'),
    'Avatar_Head_06_color7': require('./charactereditor/Avatar_Head_06_color7.png'),
    'Avatar_Head_06_color8': require('./charactereditor/Avatar_Head_06_color8.png'),
    'Avatar_Head_07_color1': require('./charactereditor/Avatar_Head_07_color1.png'),
    'Avatar_Head_07_color2': require('./charactereditor/Avatar_Head_07_color2.png'),
    'Avatar_Head_07_color3': require('./charactereditor/Avatar_Head_07_color3.png'),
    'Avatar_Head_07_color4': require('./charactereditor/Avatar_Head_07_color4.png'),
    'Avatar_Head_07_color5': require('./charactereditor/Avatar_Head_07_color5.png'),
    'Avatar_Head_07_color6': require('./charactereditor/Avatar_Head_07_color6.png'),
    'Avatar_Head_07_color7': require('./charactereditor/Avatar_Head_07_color7.png'),
    'Avatar_Head_07_color8': require('./charactereditor/Avatar_Head_07_color8.png'),
    'Avatar_Head_08_color1': require('./charactereditor/Avatar_Head_08_color1.png'),
    'Avatar_Head_08_color2': require('./charactereditor/Avatar_Head_08_color2.png'),
    'Avatar_Head_08_color3': require('./charactereditor/Avatar_Head_08_color3.png'),
    'Avatar_Head_08_color4': require('./charactereditor/Avatar_Head_08_color4.png'),
    'Avatar_Head_08_color5': require('./charactereditor/Avatar_Head_08_color5.png'),
    'Avatar_Head_08_color6': require('./charactereditor/Avatar_Head_08_color6.png'),
    'Avatar_Head_08_color7': require('./charactereditor/Avatar_Head_08_color7.png'),
    'Avatar_Head_08_color8': require('./charactereditor/Avatar_Head_08_color8.png'),
    'Avatar-Editor-Icon-Face-01_key': require('./charactereditor/Avatar-Editor-Icon-Face-01_key.png'),
    'Avatar-Editor-Icon-Face-02_key': require('./charactereditor/Avatar-Editor-Icon-Face-02_key.png'),
    'Avatar-Editor-Icon-Face-03_key': require('./charactereditor/Avatar-Editor-Icon-Face-03_key.png'),
    'Avatar-Editor-Icon-Face-04_key': require('./charactereditor/Avatar-Editor-Icon-Face-04_key.png'),
    'Avatar-Editor-Icon-Face-05_key': require('./charactereditor/Avatar-Editor-Icon-Face-05_key.png'),
    'Avatar-Editor-Icon-Face-06_key': require('./charactereditor/Avatar-Editor-Icon-Face-06_key.png'),
    'Avatar-Editor-Icon-Face-07_key': require('./charactereditor/Avatar-Editor-Icon-Face-07_key.png'),
    'Avatar-Editor-Icon-Face-08_key': require('./charactereditor/Avatar-Editor-Icon-Face-08_key.png'),
    'avatar': require('./charactereditor/avatar.png'),
    'bg_ch_selected': require('./charactereditor/bg_ch_selected.png'),
    'bg_ch': require('./charactereditor/bg_ch.png'),
    'ch_abbrechen': require('./charactereditor/ch_abbrechen.png'),
    'ch_accessoires': require('./charactereditor/ch_accessoires.png'),
    'ch_alter': require('./charactereditor/ch_alter.png'),
    'ch_clothes': require('./charactereditor/ch_clothes.png'),
    'ch_fertig': require('./charactereditor/ch_fertig.png'),
    'ch_geschl': require('./charactereditor/ch_geschl.png'),
    'ch_gesicht': require('./charactereditor/ch_gesicht.png'),
    'ch_haare': require('./charactereditor/ch_haare.png'),
    'ch_hair': require('./charactereditor/ch_hair.png'),
    'ch_head_color': require('./charactereditor/ch_head_color.png'),
    'ch_kopf': require('./charactereditor/ch_kopf.png'),
    'ch_left': require('./charactereditor/ch_left.png'),
    'ch_name': require('./charactereditor/ch_name.png'),
    'ch_right': require('./charactereditor/ch_right.png'),
    'ch_torso': require('./charactereditor/ch_torso.png'),
    'ch_zufaellig': require('./charactereditor/ch_zufaellig.png'),
    'hair': require('./charactereditor/hair.png'),
    'rechteck_mitte': require('./charactereditor/rechteck_mitte.png'),
    'rechteck_oben': require('./charactereditor/rechteck_oben.png'),
    'rechteck_unten': require('./charactereditor/rechteck_unten.png')
};

export default charactereditor;