import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback, AsyncStorage,
    Linking,
} from 'react-native';
import {WebBrowser} from 'expo';
import {Icon} from 'expo';

const deviceWidth = 380;
const deviceHeight = 768

import icons from "../assets/images/images_icons";
import gegner from "../assets/images/images_gegner";
import charactereditor from "../assets/images/images_charactereditor";

export default class MapScreen extends React.Component {

    static navigationOptions = ({navigation}) => ({
        title: "Karte",
        headerStyle: {
            backgroundColor: '#dbcf1d'
        },
        headerTintColor: '#fff',
    });

    constructor(props) {
        super(props);
        this.state = {
            mapElements: require('../assets/json/map.json'),
            parolenElemente: require('../assets/json/parolen.json'),
            messages: require('../assets/json/messages.json'),
            info: false,
            popup: null,
            message: null,
            avatar: null,
            selectedLocation: -1,
            selectedElement: null,
            selectedLocationStar: null,
            selectedLocationWin: null,
            selectedLocationCategoryName: null,
            selectedLocationCategoryId: null,
            selectedLocationCategoryColor: null,
            selectedAllCategories: null,
            stars: {},
            lastreview: null,
            isModeFreePlay: false,
            parolenStarsFreeplay: {},
            parolenStarsWin: {},
            parolenStarsWinLocation: null,
            parolenStarsFinal: null,
            selectedStarteskalation: null,
            parolenStarsWinLastLocation: null,
            settings: {
                musikChecked: true,
                soundChecked: true,
                erklarungenChecked: true
            }
        };

        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/02_MapTheme_BGM.aac');

    }

    componentDidMount = () => {
        //AsyncStorage.removeItem('popup');
        //AsyncStorage.removeItem('avatar');
        //AsyncStorage.removeItem('parolen_stars_freeplay');
        //AsyncStorage.removeItem('parolen_stars_win_location');
        //AsyncStorage.removeItem('parolen_stars_win');
        //AsyncStorage.removeItem('parolen_stars_final');
        //AsyncStorage.removeItem('isModeFreePlay');

        this._componentFocused();

        this._sub = this.props.navigation.addListener(
            'didFocus',
            this._componentFocused
        );

        this.props.navigation.addListener('willFocus', this._load);

        this._load();

    };

    _savePopUp(id) {

        var popup = this.state.popup;
        if (popup === null) {
            popup = {};
        }
        popup[id] = true;

        AsyncStorage.setItem('popup', JSON.stringify(popup));

    }

    componentWillUnmount() {
        this._sub.remove();
        this.stopSound()
    }

    _componentFocused = () => {

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {

                const settings = JSON.parse(result);

                this.setState({
                    settings: {
                        musikChecked: settings.musikChecked,
                        soundChecked: settings.soundChecked,
                        erklarungenChecked: settings.erklarungenChecked
                    }
                });

                if (settings.musikChecked) {
                    this.playSound()
                }
            } else {
                this.playSound()
            }
        });

    };

    playSound = async () => {

        this.setState({
            play: true,
            pause: false
        });

        this.audioPlayer.currentTime = 0;
        this.audioPlayer.play();

        this.audioPlayer.addEventListener('ended', function () {
            this.currentTime = 0;
            this.play();
        }, false);

    };

    stopSound = async () => {
        this.setState({
            play: false,
            pause: true
        });
        this.audioPlayer.pause();
    };

    _load = () => {
        AsyncStorage.getItem('parolen_stars_final', (err, result) => {
            if (result !== null) {
                this.setState({parolenStarsFinal: true});
            }
        });

        AsyncStorage.getItem('isModeFreePlay', (err, result) => {
            if (result !== null) {
                this.setState({isModeFreePlay: true});
            }


            AsyncStorage.getItem('avatar', (err, result) => {
                if (result !== null) {
                    this.setState({avatar: JSON.parse(result)});
                } else {
                    this.setState({
                        avatar: {
                            avatarKopf: 'Avatar_Head_01_color',
                            avatarHaare: 'Avatar_Hair_01_FG_color',
                            avatarHaareBG: 'Avatar_Hair_01_BG_color',
                            avatarGesicht: 'Avatar_Face_01',
                            avatarTorso: 'Avatar_Clothes_01_color',
                            avatarAccessoire: 'Avatar_Acc_01',
                            avatarKopfColor: 1,
                            avatarHaareColor: 1,
                            avatarTorsoColor: 1
                        }
                    })
                }

                AsyncStorage.getItem('parolen_settings', (err, result) => {
                    if (result !== null) {

                        const settings = JSON.parse(result);

                        this.setState({
                            settings: {
                                musikChecked: settings.musikChecked,
                                soundChecked: settings.soundChecked,
                                erklarungenChecked: settings.erklarungenChecked
                            }
                        }, () => {
                            AsyncStorage.getItem('popup', (err, result) => {

                                if (result !== null) {
                                    this.setState({popup: JSON.parse(result)}, () => {
                                        this._showMessages()
                                    })

                                } else {
                                    this._showMessages()
                                }


                                AsyncStorage.getItem('lastreview', (err, result) => {
                                    if (result !== null) {
                                        this.setState({lastreview: JSON.parse(result)})

                                    }
                                });

                                AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                                    if (result !== null) {
                                        this.setState({parolenStarsFreeplay: JSON.parse(result)})
                                    } else {
                                        this.setState({parolenStarsFreeplay: {}})
                                    }

                                    AsyncStorage.getItem('parolen_stars_win_last_location', (err, result) => {
                                        if (result !== null) {
                                            this.setState({parolenStarsWinLastLocation: true});
                                        }
                                    });

                                    AsyncStorage.getItem('parolen_stars_win', (err, result) => {

                                        //console.log(result)

                                        if (result !== null) {
                                            this.setState({parolenStarsWin: JSON.parse(result)})
                                        } else {
                                            this.setState({parolenStarsWin: {}})
                                        }

                                        AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                                            //console.log(result)

                                            if (result !== null) {
                                                this.setState({parolenStarsWinLocation: JSON.parse(result)})
                                            }

                                        });


                                    });

                                });


                            });
                        });
                    } else {
                        AsyncStorage.getItem('popup', (err, result) => {

                            if (result !== null) {
                                this.setState({popup: JSON.parse(result)}, () => {
                                    this._showMessages()
                                })

                            } else {
                                this._showMessages()
                            }


                            AsyncStorage.getItem('lastreview', (err, result) => {
                                if (result !== null) {
                                    this.setState({lastreview: JSON.parse(result)})

                                }
                            });

                            AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                                if (result !== null) {
                                    this.setState({parolenStarsFreeplay: JSON.parse(result)})
                                } else {
                                    this.setState({parolenStarsFreeplay: {}})
                                }

                                AsyncStorage.getItem('parolen_stars_win_last_location', (err, result) => {
                                    if (result !== null) {
                                        this.setState({parolenStarsWinLastLocation: true});
                                    }
                                });

                                AsyncStorage.getItem('parolen_stars_win', (err, result) => {

                                    //console.log(result)

                                    if (result !== null) {
                                        this.setState({parolenStarsWin: JSON.parse(result)})
                                    } else {
                                        this.setState({parolenStarsWin: {}})
                                    }

                                    AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                                        //console.log(result)

                                        if (result !== null) {
                                            this.setState({parolenStarsWinLocation: JSON.parse(result)})
                                        }

                                    });


                                });

                            });


                        });
                    }
                });


            });

        });


    };

    _showInfo(selectedLocation) {

        if (this.state.message == null) {
            const mapElements = this.state.mapElements;

            var parolenStarsFreeplay = this.state.parolenStarsFreeplay;
            var parolenStarsFreePlayLocation = parolenStarsFreeplay['location_' + selectedLocation];

            //var parolenStarsWin = this.state.parolenStarsWin;
            //var parolenStarsLocationWin = parolenStarsWin['location_' + selectedLocation];

            this.setState({
                info: true,
                selectedLocation: selectedLocation,
                selectedElement: mapElements.map[selectedLocation - 1],
                selectedLocationStar: parolenStarsFreePlayLocation,
                selectedLocationWin: this._hasWonAllCategories(selectedLocation),
                selectedStarteskalation: mapElements.map[selectedLocation - 1].starteskalation,
                selectedAllCategories: mapElements.map[selectedLocation - 1].categories,
            })
        }
    }

    _hideInfo() {
        this.setState({
            info: false, selectedLocationCategoryName: null,
            selectedLocationCategoryId: null,
            selectedLocationWin: null
        })
    }

    _showMessages() {

        if (this.state.popup !== null) {

            var popup = this.state.popup;

            //if (this.state.settings.erklarungenChecked) {
                if (popup.overworld_2 === undefined) {
                    this.setState({
                        message: {
                            id: 'overworld_1',
                            text: this.state.messages.overworld_1,
                            position: 'top'
                        }
                    });
                }

                var parolenStarsFreeplay = this.state.parolenStarsFreeplay;

                if (popup.firstlevel === undefined && parolenStarsFreeplay['location_' + 1] !== undefined) {
                    this.setState({message: {id: 'firstlevel', text: this.state.messages.firstlevel, position: 'top'}});
                }

                if (popup.firstlevel !== undefined && parolenStarsFreeplay['location_' + 1] !== undefined) {
                    switch (undefined) {

                        case popup.startip:
                            this.setState({
                                message: {
                                    id: 'startip',
                                    text: this.state.messages.startip,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_2:
                            this.setState({
                                message: {
                                    id: 'startip_2',
                                    text: this.state.messages.startip_2,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_3:
                            this.setState({
                                message: {
                                    id: 'startip_3',
                                    text: this.state.messages.startip_3,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_4:
                            this.setState({
                                message: {
                                    id: 'startip_4',
                                    text: this.state.messages.startip_4,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_5:
                            this.setState({
                                message: {
                                    id: 'startip_5',
                                    text: this.state.messages.startip_5,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_6:
                            this.setState({
                                message: {
                                    id: 'startip_6',
                                    text: this.state.messages.startip_6,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_7:
                            this.setState({
                                message: {
                                    id: 'startip_7',
                                    text: this.state.messages.startip_7,
                                    position: 'middle'
                                }
                            });
                            break;

                        case popup.startip_8:
                            this.setState({
                                message: {
                                    id: 'startip_8',
                                    text: this.state.messages.startip_8,
                                    position: 'middle'
                                }
                            });
                            break;

                        default:
                            break;

                    }
                }

                if (popup.secondlevel_2 === undefined && parolenStarsFreeplay['location_' + 2] !== undefined) {
                    this.setState({
                        message: {
                            id: 'secondlevel',
                            text: this.state.messages.secondlevel,
                            position: 'top'
                        }
                    });
                }

                if (popup.familienfeier === undefined && parolenStarsFreeplay['location_' + 8] !== undefined) {
                    this.setState({
                        message: {
                            id: 'familienfeier',
                            text: this.state.messages.familienfeier,
                            position: 'top'
                        }
                    });
                }

                if (popup.finish === undefined && parolenStarsFreeplay['location_' + 9] !== undefined && !this.state.isModeFreePlay) {
                    AsyncStorage.setItem('isModeFreePlay', 'true');
                    this.setState({isModeFreePlay: true});
                    this.setState({message: {id: 'finish', text: this.state.messages.finish, position: 'top'}});
                }

                if (this.state.isModeFreePlay && popup.kunterbunt_2 === undefined && !this.state.isModeFreePlay) {
                    this.setState({message: {id: 'kunterbunt', text: this.state.messages.kunterbunt, position: 'top'}});
                }

            //}

            if (this.state.parolenStarsFinal !== undefined && this.state.parolenStarsFinal !== null) {
                this.setState({message: {id: 'final', text: this.state.messages.final, position: 'top'}});
            }

        } else {
            //if (this.state.settings.erklarungenChecked) {
                this.setState({message: {id: 'overworld_1', text: this.state.messages.overworld_1, position: 'top'}});
            //}
        }
        //var stars = this.state.stars;


    }

    _hideMessages() {
        this.setState({message: null})
    }

    _nextMessages(id) {

        if (id === 'secondlevel') {
            this.setState({message: {id: 'secondlevel_2', text: this.state.messages.secondlevel_2, position: 'top'}});
        } else if (id === 'eskalation') {
            this.setState({message: {id: 'eskalation_2', text: this.state.messages.eskalation_2, position: 'top'}});
        } else if (id === 'finish') {

            var popup = this.state.popup;

            if (popup === null) {
                popup = {};
            }

            popup['finish'] = true;

            AsyncStorage.setItem('popup', JSON.stringify(popup));

            this.setState({message: {id: 'kunterbunt', text: this.state.messages.kunterbunt, position: 'top'}});
        } else if (id === 'kunterbunt') {
            this.setState({message: {id: 'kunterbunt_2', text: this.state.messages.kunterbunt_2, position: 'top'}});
        } else if (id === 'overworld_1') {
            this.setState({message: {id: 'overworld_2', text: this.state.messages.overworld_2, position: 'top'}});
        } else {

            var popup = this.state.popup;

            if (popup === null) {
                popup = {};
            }

            popup[id] = true;

            AsyncStorage.setItem('popup', JSON.stringify(popup));

            this._hideMessages()

        }

    }

    _playLocation() {
        this._hideInfo();

        this.stopSound();

        this.props.navigation.push('LocationScreen', {
            selectedLocation: this.state.selectedLocation,
            selectedStarteskalation: this.state.selectedStarteskalation,
            selectedCategory: this.state.selectedLocationCategoryId,
            selectedCategoryName: this.state.selectedLocationCategoryName,
            selectedCategoryColor: this.state.selectedLocationCategoryColor,
            selectedAllCategories: this.state.selectedAllCategories,
        });
    }

    _selectCategory(selectedLocationCategoryName, selectedLocationCategoryId, selectedLocationCategoryColor, selectedAllCategories) {

        if (this.state.selectedLocationCategoryId !== selectedLocationCategoryId) {
            this.setState({
                selectedLocationCategoryName: selectedLocationCategoryName,
                selectedLocationCategoryColor: selectedLocationCategoryColor,
                selectedLocationCategoryId: selectedLocationCategoryId,
                selectedAllCategories: selectedAllCategories
            })
        } else {
            this.setState({
                selectedLocationCategoryName: null,
                selectedLocationCategoryId: null,
                selectedLocationCategoryColor: null,
                selectedAllCategories: selectedAllCategories,
            })
        }

    }

    _hasWonAllCategories(selectedLocation) {

        var isSelectedLocationWin = null;
        var mapElements = this.state.mapElements;

        if (this.state.parolenStarsWinLocation !== null && this.state.parolenStarsWinLocation !== undefined) {
            if (this.state.parolenStarsWinLocation[selectedLocation] !== undefined) {

                isSelectedLocationWin = true;

                var mapCategories = null;

                var m;
                for (m = 0; m < mapElements.map.length; m++) {

                    const mapElement = mapElements.map[m];

                    if (mapElement.id === selectedLocation) {
                        mapCategories = mapElement.categories
                    }
                }

                if (mapCategories !== null) {
                    var c;
                    for (c = 0; c < mapCategories.length; c++) {

                        const mapCategoriesElementId = mapCategories[c];

                        if (this.state.parolenStarsWinLocation[selectedLocation][mapCategoriesElementId] === undefined) {
                            isSelectedLocationWin = null;
                        }
                    }
                }
            }
        }

        return isSelectedLocationWin;

    }

    render() {

        var selectedElementText;

        if (this.state.selectedElement !== null) {
            selectedElementText = this.state.selectedElement.title;
        }

        var navigation = this.props.navigation;

        var popup = null;

        if (this.state.message !== null) {

            var messageText = this.state.message.text;

            if (this.state.avatar !== null) {
                if (this.state.avatar.name !== undefined) {
                    messageText = messageText.replace('[Spielername]', this.state.avatar.name)
                } else {
                    messageText = messageText.replace('[Spielername]', '')
                }

            } else {
                messageText = messageText.replace('[Spielername]', '')
            }

            var popupPositionStyle;
            var popupPositionTextStyle = {
                fontSize: 14,
                lineHeight: 17
            };

            if (this.state.message.id !== 'final') {

                if (this.state.message.position === 'top') {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.065,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.60,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 9
                    };
                } else if (this.state.message.position === 'middle') {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.035,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.80,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 9
                    };

                    popupPositionTextStyle = {
                        fontSize: 12,
                        lineHeight: 16
                    };
                } else {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.065,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.60,
                        position: 'absolute',
                        top: 95,
                        left: 0,
                        zIndex: 9
                    };
                }

                popup = <View>
                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{messageText}</Text>
                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={this._nextMessages.bind(this, this.state.message.id)}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>

            } else {

                // urkunde

                popupPositionTextStyle = {
                    fontSize: 14,
                    lineHeight: 17
                };

                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.015,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    borderColor: '#FFAA00',
                    borderWidth: 5,
                    height: deviceHeight * 0.70,
                    position: 'absolute',
                    top: 20,
                    left: 0,
                    zIndex: 9
                };

                var avatar = null;
                var name = null;

                if (this.state.avatar !== null) {
                    var avatarKopf = this.state.avatar.avatarKopf;
                    var avatarHaare = this.state.avatar.avatarHaare;
                    var avatarHaareBG = this.state.avatar.avatarHaareBG;
                    var avatarGesicht = this.state.avatar.avatarGesicht;
                    var avatarTorso = this.state.avatar.avatarTorso;
                    var avatarAccessoire = this.state.avatar.avatarAccessoire;
                    var avatarKopfColor = this.state.avatar.avatarKopfColor;
                    var avatarHaareColor = this.state.avatar.avatarHaareColor;
                    var avatarTorsoColor = this.state.avatar.avatarTorsoColor;

                    avatar = <ImageBackground source={charactereditor['00_Avatar_BG']}
                                              style={styles.avatarBackground}>

                        <Image
                            source={charactereditor[avatarHaareBG + avatarHaareColor]}
                            style={styles.avatarHair}
                            resizeMode="contain"
                        />

                        <Image
                            source={charactereditor[avatarKopf + avatarKopfColor]}
                            style={styles.avatarHead}
                            resizeMode="contain"
                        />

                        <Image
                            source={charactereditor[avatarTorso + avatarTorsoColor]}
                            style={styles.avatarClothes}
                            resizeMode="contain"
                        />

                        <Image
                            source={charactereditor[avatarHaare + avatarHaareColor]}
                            style={styles.avatarHair}
                            resizeMode="contain"
                        />


                        <Image
                            source={charactereditor[avatarGesicht]}
                            style={styles.avatarFace}
                            resizeMode="contain"
                        />

                        <Image
                            source={charactereditor[avatarAccessoire]}
                            style={styles.avatarAcc}
                            resizeMode="contain"
                        />

                    </ImageBackground>


                    name = this.state.avatar.name;
                }

                popup = <View>


                    <Image
                        style={{
                            width: 100,
                            height: 200,
                            position: 'fixed',
                            right: 30,
                            top: 65,
                            zIndex: 10
                        }}
                        source={require('../assets/images/urkunde/urkundeelement.png')}
                        resizeMode="contain"
                    />

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>

                            <View style={styles.avatar}>
                                {avatar}
                            </View>

                            <Text style={{
                                paddingLeft: '40%',
                                fontSize: 16,
                                fontWeight: 'bold',
                                width: '80%',
                                height: 60
                            }}>{name}</Text>

                            <View style={{
                                width: '98%',
                                marginLeft: '1%',
                                height: 3,
                                backgroundColor: '#f2f2f2',
                                marginBottom: 10,
                                marginTop: 10,
                            }}>
                            </View>

                            <Text style={{
                                fontSize: 18,
                                lineHeight: 20
                            }}>Herzlichen</Text>
                            <Text style={{
                                fontSize: 18,
                                lineHeight: 18,
                                marginTop: 5,
                                marginBottom: 10,
                                width: '100%'
                            }}>Glückwunsch!</Text>

                            <Text style={popupPositionTextStyle}>{messageText}</Text>

                            <View style={{
                                width: '98%',
                                marginLeft: '1%',
                                height: 3,
                                backgroundColor: '#f2f2f2',
                                marginTop: 10
                            }}>
                            </View>
                        </View>


                        <View style={{
                            flex: 1,
                            flexDirection: 'row', flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            marginTop: 170,
                            marginLeft: 30
                        }}>


                            <a href='http://konterbunt.de' target='_blank' style={{
                                width: 100,
                                height: 52,
                                marginBottom: 30,
                                marginTop: 30
                            }}>
                                <Image
                                    style={{width: '100%', height: 52}}
                                    source={require('../assets/images/urkunde/oeffne_website.png')}
                                    resizeMode="contain"
                                />
                            </a>


                            <TouchableOpacity style={{
                                width: 100,
                                height: 52,
                                marginBottom: 30,
                                marginTop: 30,
                            }}
                                              onPress={this._nextMessages.bind(this, this.state.message.id)}>
                                <Image
                                    style={{width: '100%', height: 52}}
                                    source={require('../assets/images/urkunde/schliessen.png')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                        </View>
                    </View>

                </View>
            }


        }

        var popupInfo = null;

        if (this.state.info) {

            var star = <Image
                source={require('../assets/images/review/Star_Below_Empty.png')}
                style={styles.star}
                resizeMode="contain"
            />;

            if (this.state.selectedLocationStar !== undefined && this.state.selectedLocationStar !== null) {
                star = <Image
                    source={require('../assets/images/review/Star_Below_Gold.png')}
                    style={styles.star}
                    resizeMode="contain"
                />;
            }

            if (this.state.selectedLocationWin !== undefined && this.state.selectedLocationWin !== null
                || this.state.selectedLocation === 9 && this.state.parolenStarsFinal !== null
                || this.state.parolenStarsWinLastLocation && this.state.selectedLocation === 9) {
                star = <Image
                    source={require('../assets/images/map/Map_Star_Platinum.png')}
                    style={styles.star}
                    resizeMode="contain"
                />;
            }

            var selectCategory = null;
            var categories = this.state.selectedElement.categories;

            if (this.state.isModeFreePlay && this.state.selectedLocation !== 9) {

                var selectedParoleCategory = null;
                var selectedParoleCategoryColor = '#000000';

                selectCategory = <View style={{width: '100%', height: 270}}>
                    <Text style={{textAlign: 'center', marginTop: 50, marginBottom: 10}}>Wähle das Thema</Text>

                    <View style={styles.line}>
                    </View>


                    <ScrollView style={{width: '100%', height: 280, paddingTop: 10}}>

                        <View style={{
                            width: '100%',
                            height: 200,
                            padding: 20,
                            lex: 1,
                            flexDirection: 'row', flexWrap: 'wrap',
                            alignItems: 'flex-start'
                        }}>

                            {this.state.selectedElement.categories.map(id => {


                                if (this.state.parolenElemente !== null) {
                                    var c;
                                    for (c = 0; c < this.state.parolenElemente.parolen.length; c++) {
                                        const paroleCategory = this.state.parolenElemente.parolen[c];
                                        if (paroleCategory.id === id) {
                                            selectedParoleCategory = paroleCategory;
                                        }
                                    }

                                }

                                if (selectedParoleCategory !== null) {

                                    var icon = icons[selectedParoleCategory.icon + '_grey'];

                                    var miniIcon = require('../assets/images/map/Map_Star_Small_Grey.png');

                                    //console.log("marker icon ==>", this.state.selectedLocation, selectedParoleCategory.id, this.state.parolenStarsWinLocation);

                                    if (this.state.parolenStarsWinLocation !== null) {

                                        if (this.state.parolenStarsWinLocation[this.state.selectedLocation] !== undefined) {

                                            if (this.state.parolenStarsWinLocation[this.state.selectedLocation][selectedParoleCategory.id] === true) {
                                                miniIcon = require('../assets/images/map/Map_Star_Small_Gold.png');
                                                icon = icons[selectedParoleCategory.icon];
                                            }
                                        }
                                    }

                                    var name = selectedParoleCategory.name;
                                    var color = selectedParoleCategory.color;

                                    if (this.state.selectedLocationCategoryId === id) {
                                        icon = icons[selectedParoleCategory.icon];
                                        selectedParoleCategoryColor = selectedParoleCategory.color;
                                    }

                                    return (<TouchableOpacity key={id}
                                                              style={{width: 40, height: 45, margin: 5, marginLeft: 10}}
                                                              onPress={this._selectCategory.bind(this, name, id, color, categories)}>
                                        <Image
                                            style={{position: 'absolute', width: 20, height: 20, right: -15, top: -6}}
                                            source={miniIcon}
                                            resizeMode="contain"
                                        />
                                        <Image
                                            style={{width: '100%', height: '100%'}}
                                            source={icon}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>)

                                } else {
                                    return null;
                                }

                            })}

                        </View>


                    </ScrollView>

                    <View style={styles.line}>
                    </View>

                    <Text style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        color: selectedParoleCategoryColor,
                        textAlign: 'center',
                        marginTop: 20,
                        marginBottom: -25,
                        height: 20
                    }}>{this.state.selectedLocationCategoryName}</Text>

                </View>

            } else if (this.state.isModeFreePlay && this.state.selectedLocation === 9) {
                selectCategory = <View style={{width: '100%', height: 270, paddingTop: 50}}>

                    <View style={styles.line}>
                    </View>

                    <ScrollView style={{width: '100%', height: 280, paddingTop: 10}}>

                        <View style={{
                            width: '100%',
                            height: 200,
                            padding: 20,
                            lex: 1,
                            flexDirection: 'row', flexWrap: 'wrap',
                            alignItems: 'flex-start'
                        }}>

                            {this.state.selectedElement.categories.map(id => {


                                if (this.state.parolenElemente !== null) {
                                    var c;
                                    for (c = 0; c < this.state.parolenElemente.parolen.length; c++) {
                                        const paroleCategory = this.state.parolenElemente.parolen[c];
                                        if (paroleCategory.id === id) {
                                            selectedParoleCategory = paroleCategory;
                                        }
                                    }

                                }

                                if (selectedParoleCategory !== null) {

                                    var icon = icons[selectedParoleCategory.icon + '_grey'];

                                    var miniIcon = require('../assets/images/map/Map_Star_Small_Grey.png');

                                    //console.log("marker icon ==>", this.state.selectedLocation, selectedParoleCategory.id, this.state.parolenStarsWinLocation);

                                    if (this.state.parolenStarsWinLocation !== null) {

                                        if (this.state.parolenStarsWinLocation[this.state.selectedLocation] !== undefined) {

                                            if (this.state.parolenStarsWinLocation[this.state.selectedLocation][selectedParoleCategory.id] === true) {
                                                miniIcon = require('../assets/images/map/Map_Star_Small_Gold.png');
                                                icon = icons[selectedParoleCategory.icon];
                                            }
                                        }
                                    }

                                    var name = selectedParoleCategory.name;
                                    var color = selectedParoleCategory.color;

                                    if (this.state.selectedLocationCategoryId === id) {
                                        icon = icons[selectedParoleCategory.icon];
                                        selectedParoleCategoryColor = selectedParoleCategory.color;
                                    }

                                    //console.log("why?", this.state.parolenStarsWinLastLocation, this.state.selectedLocation)

                                    if (this.state.parolenStarsWinLastLocation && this.state.selectedLocation === 9) {
                                        icon = icons[selectedParoleCategory.icon];
                                        miniIcon = require('../assets/images/map/Map_Star_Small_Gold.png');
                                    }


                                    return (<View key={id}
                                                  style={{width: 40, height: 45, margin: 5, marginLeft: 10}}
                                                  onPress={this._selectCategory.bind(this, name, id, color)}>
                                        <Image
                                            style={{position: 'absolute', width: 20, height: 20, right: -15, top: -6}}
                                            source={miniIcon}
                                            resizeMode="contain"
                                        />
                                        <Image
                                            style={{width: '100%', height: '100%'}}
                                            source={icon}
                                            resizeMode="contain"
                                        />
                                    </View>)

                                } else {
                                    return null;
                                }

                            })}

                        </View>


                    </ScrollView>


                    <View style={styles.line}>
                    </View>

                    <Text style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        color: selectedParoleCategoryColor,
                        textAlign: 'center',
                        marginTop: 20,
                        marginBottom: -25,
                        height: 20
                    }}>{this.state.selectedLocationCategoryName}</Text>

                </View>
            }

            popupInfo = <View style={{position: 'absolute', zIndex: 10}}>
                <View style={styles.popup}>

                    <TouchableOpacity style={styles.closeButtonWrapper}
                                      onPress={this._hideInfo.bind(this)}>
                        <Image
                            style={styles.closeButton}
                            source={require('../assets/images/map/Button_Close_Idle.png')}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>

                    <View style={styles.locationContainer}>
                        {star}
                        <View style={styles.locationWrapper}>
                            <Text style={styles.location}>{selectedElementText}</Text>
                        </View>
                    </View>

                    {selectCategory}

                    <TouchableOpacity style={styles.weiterButtonWrapper}
                                      onPress={this._playLocation.bind(this)}>
                        <Image
                            style={styles.weiterButton}
                            source={require('../assets/images/map/Play-Button.png')}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>

                </View>
            </View>
        }

        var lastReviewButton = null;

        if (this.state.lastreview !== null) {

            lastReviewButton = (<TouchableOpacity style={styles.letztesErgebnisWrapper}
                                                  onPress={() => this.props.navigation.push('LastReviewScreen', {selectedLocation: this.state.selectedLocation})}>
                    <Image
                        style={styles.letztesErgebnis}
                        source={require('../assets/images/buttons/letztes_ergebnis.png')}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            )

        }

        return (
            <View style={styles.container}>


                <ImageBackground
                    style={{width: '100%', height: '100%', background: '#fff', position: 'relative', zIndex: 0}}>

                    {popupInfo}
                    {lastReviewButton}

                    <View style={{position: 'fixed', top: 50, left: 5, zIndex: 5}}>
                        {popup}
                    </View>

                    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                        <View style={{width: '100%', height: '100%', position: 'absolute', zIndex: 0}}>


                            <ImageBackground source={require('../assets/images/map/bg_map3x.jpg')}
                                             style={styles.map}>


                                {this.state.mapElements.map.map(data => {

                                    var parolenStarsFreeplay = this.state.parolenStarsFreeplay;

                                    var hidden = true;

                                    var parolenStarsFreePlayLocation = parolenStarsFreeplay['location_' + data.id];
                                    var lastParolenStarsFreePlayLocation = parolenStarsFreeplay['location_' + (data.id - 1)];
                                    var lastLastParolenStarsFreePlayLocation = parolenStarsFreeplay['location_' + (data.id - 2)];
                                    var lastLastLastParolenStarsFreePlayLocation = parolenStarsFreeplay['location_' + (data.id - 3)];

                                    if (lastParolenStarsFreePlayLocation !== undefined && data.id === 2) {
                                        hidden = false
                                    }
                                    if (lastParolenStarsFreePlayLocation !== undefined && data.id === 3 || lastLastParolenStarsFreePlayLocation !== undefined && data.id === 4 || lastLastLastParolenStarsFreePlayLocation !== undefined && data.id === 5) {
                                        hidden = false
                                    }

                                    if (data.id === 6 && lastParolenStarsFreePlayLocation !== undefined &&
                                        data.id === 6 && lastLastParolenStarsFreePlayLocation !== undefined &&
                                        data.id === 6 && lastLastLastParolenStarsFreePlayLocation !== undefined) {
                                        hidden = false
                                    }

                                    if (data.id === 7 && lastParolenStarsFreePlayLocation !== undefined || data.id === 8 && lastLastParolenStarsFreePlayLocation !== undefined) {
                                        hidden = false
                                    }

                                    if (data.id === 9 && lastParolenStarsFreePlayLocation !== undefined) {
                                        hidden = false
                                    }

                                    if (data.id === 1) {
                                        hidden = false;
                                    }

                                    if (parolenStarsFreePlayLocation !== undefined) {

                                        var star = require('../assets/images/map/mapmarker_star.png');

                                        if (this._hasWonAllCategories(data.id) !== null
                                            || data.id === 9 && this.state.parolenStarsFinal !== null
                                            || this.state.parolenStarsWinLastLocation && data.id === 9) {
                                            star = require('../assets/images/map/Gruppe_34543.png');
                                        }

                                        return (
                                            <View key={data.id} style={
                                                {
                                                    width: 75,
                                                    height: 160,
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    left: data.x,
                                                    top: data.y
                                                }}>
                                                <TouchableOpacity style={
                                                    {
                                                        width: 75,
                                                        height: 160,
                                                    }}
                                                                  onPress={this._showInfo.bind(this, data.id)}>
                                                    <ImageBackground
                                                        source={star}
                                                        style={
                                                            {
                                                                width: 75,
                                                                height: 92,
                                                            }}>
                                                    </ImageBackground>
                                                </TouchableOpacity>
                                            </View>)

                                    } else if (!hidden) {
                                        return (
                                            <View key={data.id} style={
                                                {
                                                    width: 75,
                                                    height: 150,
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    left: data.x,
                                                    top: data.y
                                                }}>
                                                <TouchableOpacity style={
                                                    {
                                                        width: 75,
                                                        height: 150,
                                                    }}
                                                                  onPress={this._showInfo.bind(this, data.id)}>
                                                    <ImageBackground
                                                        source={require('../assets/images/map/mapmarker.png')}
                                                        style={
                                                            {
                                                                width: 75,
                                                                height: 92,
                                                            }}>
                                                    </ImageBackground>
                                                </TouchableOpacity>
                                            </View>)
                                    }

                                    /*
                                    if (parolenStarsFreeplay === null && data.id === 1 || parolenStarsFreeplay === null && data.id === 2) {
                                        return (
                                            <View key={data.id} style={
                                                {
                                                    width: 75,
                                                    height: 92,
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    left: data.x,
                                                    top: data.y
                                                }}>
                                                <TouchableOpacity style={
                                                    {
                                                        width: 75,
                                                        height: 92,
                                                    }}
                                                                  onPress={this._showInfo.bind(this, data.id)}>
                                                    <ImageBackground
                                                        source={require('../assets/images/map/mapmarker.png')}
                                                        style={
                                                            {
                                                                width: 75,
                                                                height: 92,
                                                            }}>
                                                    </ImageBackground>
                                                </TouchableOpacity>
                                            </View>)
                                    }
                                    */

                                })
                                }

                            </ImageBackground>

                        </View>

                    </ScrollView>
                </ImageBackground>

            </View>
        );

    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380,
        position: 'relative',
        zIndex: 0,
    },

    contentContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        position: 'relative',
        zIndex: 0,
    },
    map: {
        height: 1116,
        width: deviceWidth * 0.96,
        marginLeft: deviceWidth * 0.02,
        borderRadius: 10,
        overflow: 'hidden',
        zIndex: 0,
        position: 'absolute',
    },

    popup: {
        width: deviceWidth * 0.76,
        marginLeft: deviceWidth * 0.12,
        marginTop: deviceHeight * 0.075,
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        height: deviceHeight * 0.70,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9
    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },

    locationWrapper: {
        width: '70%',
        paddingLeft: 20,
        marginBottom: 20
    },

    location: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10
    },

    weiterButtonWrapper: {
        width: 140,
        height: 70,
        marginBottom: 30,
        marginTop: 30,
        marginLeft: deviceWidth * 0.19,
    },

    weiterButton: {
        width: '100%',
        height: 70,
    },

    closeButtonWrapper: {
        width: 40,
        height: 50,
        position: 'absolute',
        right: -20,
        top: -20,
        zIndex: 10
    },

    closeButton: {
        width: '100%',
        height: 50,
    },

    letztesErgebnisWrapper: {
        width: 140,
        height: 70,
        position: 'fixed',
        right: -30,
        bottom: 80,
        zIndex: 5
    },

    letztesErgebnis: {
        width: '100%',
        height: 70,
    },

    line: {
        width: '90%',
        marginLeft: '5%',
        height: 3,
        backgroundColor: '#f2f2f2',
    },

    avatar: {
        width: 90,
        height: 108,
        position: 'absolute',
        left: 10,
        top: -30,
        zIndex: 5,
    },

    avatarBackground: {
        width: 90,
        height: 108,
    },


    avatarHead: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarClothes: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarHair: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarAcc: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },

    avatarFace: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 95
    },


});
