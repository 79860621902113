import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback, Linking
} from 'react-native';
import {WebBrowser} from 'expo';
import icons from "../assets/images/images_icons"

const deviceWidth = 380;
const deviceHeight = 768

export default class CreditsScreen extends React.Component {

    static navigationOptions = {
        title: "Mitwirkende",
        headerStyle: {
            backgroundColor: '#605659'
        },
        headerTintColor: '#FFFFFF',
    };

    constructor(props) {
        super(props);

    }

    render() {

        return (

            <View style={styles.container}>

                <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                 style={{width: '100%', height: '100%'}}>
                    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                        <View
                            style={{
                                width: deviceWidth * 0.93,
                                marginLeft: 3,
                                paddingRight:15,
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: 'hidden',
                                backgroundColor: '#ffffff',
                            }}>

                            <Text style={styles.text}><Text style={styles.text}>Die App</Text><Text
                                style={{fontWeight: "bold"}}> KonterBUNT </Text><Text style={styles.text}>ist ein
                                Angebot der Niedersächsischen Landeszentrale für politische Bildung
                            </Text></Text>

                            <Image
                                source={require('../assets/images/logos/logo_nlpb3x.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Text style={styles.text}>
                                In Kooperation mit der Landeszentrale für politische Bildung des Landes{"\n"}
                                Sachsen-Anhalt
                            </Text>

                            <Image
                                source={require('../assets/images/logos/LSA_LZ.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 300,
                                    marginTop: 20,
                                    marginBottom: 20,
                                    marginLeft: 0
                                }
                                }
                                resizeMode="contain"
                            />

                            <View style={styles.line}>
                            </View>

                            <Text style={styles.text}>
                                Wir danken folgenden Kooperationspartner_innen{"\n"}
                                für die Mitarbeit:{"\n"}{"\n"}</Text>

                            <Image
                                source={require('../assets/images/logos/Walschl.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 150,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/Amadeu_Antonio_Stiftung.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 120,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/DerPat.png')}
                                style={{

                                    width: deviceWidth * 0.6,
                                    height: 80,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/IGMetall_SZ-PE.jpg')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/KogA.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/LAMSA.jpg')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 120,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/LPR.jpg')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/SoVD.jpg')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Image
                                source={require('../assets/images/logos/ZDB.jpg')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />


                            <Text style={styles.text}>Mehr Infos zu den Beiträgen unserer Partner_innen gibt es
                                hier
                            </Text>

                            <TouchableOpacity style={{
                                width: 180,
                                height: 70,
                                marginLeft: 20,
                                marginBottom: 20
                            }}
                                              onPress={() => {
                                                  Linking.openURL('http://konterbunt.de/info')
                                              }}>
                                <Image
                                    style={styles.button}
                                    source={require('../assets/images/buttons/browser_btn.jpg')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                            <Text style={styles.text}>
                                Für die Bereitstellung des Strategieguides danken wir{"\n"}{"\n"}

                                Prof. Dr. Klaus-Peter Hufer{"\n"}{"\n"}
                            </Text>

                            <View style={styles.line}>
                            </View>

                            <Text style={styles.text}> App-Entwicklung durch{"\n"}
                            </Text>

                            <Image
                                source={require('../assets/images/logos/waza_Logo_Orange3x.png')}
                                style={{

                                    width: deviceWidth * 1.0,
                                    height: 110,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }
                                }
                                resizeMode="contain"
                            />

                            <Text style={styles.text}>
                                Produzent & Geschäftsführer:{"\n"}
                                Eric Jannot{"\n"}
                                Associate Producer:{"\n"}
                                Christian Huberts{"\n"}
                                {"\n"}
                                Game Designer: Julian Röth{"\n"}
                                Artist: Torben Bökemeyer{"\n"}
                                Head of QA: Till Balbach{"\n"}
                                Sound Designer: Julian Röth{"\n"}
                            </Text>


                            <Text style={styles.text}>Die App KonterBUNT ist ein Angebot der Niedersächsischen
                                Landeszentrale für politische Bildung
                                {"\n"}
                                Namenskonzeption: Lisa Fascina
                                {"\n"}{"\n"}
                                Vielen Dank an alle Tester_innen.
                                {"\n"}{"\n"}
                                Zusätzliche Vektorgrafiken mit freundlicher Genehmigung von Freepik.com:
                                {"\n"}{"\n"}
                                Graphics designed by Freepik, vectorpocket, macrovector, vectorpouch, Johndory,
                                rawpixel.com.
                                {"\n"}{"\n"}
                            </Text>

                            <View style={styles.line}>
                            </View>

                            <Text style={styles.text}>
                                Impressum
                                {"\n"}{"\n"}
                                Herausgeberin:
                                {"\n"}{"\n"}
                                Niedersächsische Landeszentrale für politische Bildung
                                {"\n"}{"\n"}
                                Georgsplatz 18/19
                                D-30159 Hannover
                                {"\n"}{"\n"}
                                Telefon: +49 511 120-7500
                                {"\n"}{"\n"}
                                E-Mail: poststelle@lpb.niedersachsen.de
                                {"\n"}{"\n"}
                                Internet: http://www.demokratie.niedersachsen.de
                                {"\n"}{"\n"}
                                Vertretungsberechtigt:
                                Ulrika Engler, Direktorin
                            </Text>

                            <Text style={styles.text}>
                            </Text>

                            <TouchableOpacity style={{
                                width: 200,
                                height: 70,
                                marginLeft: 15,
                                marginBottom: 20
                            }}
                                              onPress={() => {
                                                  Linking.openURL('http://konterbunt.de/datenschutz')
                                              }}>
                                <Image
                                    style={styles.button}
                                    source={require('../assets/images/buttons/browser_daten.jpg')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                width: 200,
                                height: 70,
                                marginLeft: 15,
                                marginBottom: 30
                            }}
                                              onPress={() => {
                                                  Linking.openURL('mailto:poststelle@lpb.niedersachsen.de')
                                              }}>
                                <Image
                                    style={styles.button}
                                    source={require('../assets/images/buttons/kontakt_btn.jpg')}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>

                            <Text style={styles.text}>v. 7.7.0:12
                            </Text>
                        </View>

                    </ScrollView>
                </ImageBackground>

            </View>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        width: deviceWidth,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
    },

    text: {
        fontFamily: 'roboto-regular',
        textAlignVertical: "center",
        textAlign: "center",
        marginLeft: 10,
        fontSize: 16,
        lineHeight: 22,
        padding: 20,
    },

    line: {
        width: deviceWidth * .95,
        height: 2,
        backgroundColor: '#dddddd',
    },

    button: {
        width: '100%',
        height: 70,
    },

});
