import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    TextInput,
    Picker,
    Button, TouchableWithoutFeedback, AsyncStorage,
    BackHandler,
} from 'react-native';
import {WebBrowser} from 'expo';

import gameimages from "../assets/images/images_game"
import charactereditor from "../assets/images/images_charactereditor";
import gegner from "../assets/images/images_gegner";

const deviceWidth = 380;
const deviceHeight = 768

const bganswer = require('../assets/images/game/bganswer.png');
const bganswerred = require('../assets/images/game/bganswerred.png');
const bganswergelb = require('../assets/images/game/bganswergelb.png');
const bganswergreen = require('../assets/images/game/bganswergreen.png');
const bganswerorange = require('../assets/images/game/bganswerorange.png');

export default class GameScreen extends React.Component {

    static navigationOptions = {
        header: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            parolenElemente: require('../assets/json/parolen.json'),
            eskalationenElemente: require('../assets/json/eskalationen.json'),
            strategieguideElemente: require('../assets/json/strategieguide.json'),
            gegnerElemente: require('../assets/json/gegner.json'),
            mapElemente: require('../assets/json/map.json'),
            gegnerElement: null,
            avatar: null,
            parolenForRound: null,
            eskalationenForRound: null,
            parolenIndex: 0,
            isModeFreePlay: false,
            maxParolenForRound: 3,
            selectedAnswerForRound: [],
            selectedEskalationenForRound: [],
            selectedCategory: null,
            selectedCategoryId: null,
            selectedCategoryName: null,
            selectedCategoryColor: null,
            selectedAllCategories: null,
            selected: {'0': bganswer, '1': bganswer, '2': bganswer, '3': bganswer},
            active: false,
            maxTimer: 60,
            timer: 60,
            countDown: this.props.countDown,
            bar: 50,
            showBar: 1,
            pointsForRound: 0,
            gamePause: false,
            messages: require('../assets/json/messages.json'),
            popup: null,
            message: null,
            refresh: false,
            goodRefresh: false,
            refreshedParolenForRound: [],
            eskalation: false,
            settings: {
                musikChecked: true,
                soundChecked: true,
                erklarungenChecked: true,
                schwierigkeitEinfachChecked: false,
                schwierigkeitNormalChecked: true,
            }
        };

        this.backgroundPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/05_ClockTicking_FX.aac');
        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/04_StartEncounter_FX.aac');
    }

    playBackgroundSound = async () => {

        if (this.state.settings != null) {
            if (this.state.settings.soundChecked) {
                this.backgroundPlayer.currentTime = 0;
                this.backgroundPlayer.play();

                this.backgroundPlayer.addEventListener('ended', function () {
                    this.currentTime = 0;
                    this.play();
                }, false);
            }
        }

    };

    playSound = async (name) => {

        if (this.state.settings != null) {
            if (this.state.settings.soundChecked) {
                switch (name) {

                    case '04_StartEncounter_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/04_StartEncounter_FX.aac');
                        break;
                    case '05_ClockTicking_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/05_ClockTicking_FX.aac');
                        break;
                    case '06_RefreshGood_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/06_RefreshGood_FX.aac');
                        break;
                    case '07_RefreshBad_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/07_RefreshBad_FX.aac');
                        break;
                    case '08_Escalation_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/08_Escalation_FX.aac');
                        break;
                    case '09_AnswerBest_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/09_AnswerBest_FX.aac');
                        break;
                    case '10_AnswerMedium_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/10_AnswerMedium_FX.aac');
                        break;
                    case '11_AnswerPoor_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/11_AnswerPoor_FX.aac');
                        break;
                    case '12_ReviewWin_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/12_ReviewWin_FX.aac');
                        break;
                    case '13_ReviewFail_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/13_ReviewFail_FX.aac');
                        break;
                    case '14_SpecialStar_FX':
                        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/14_SpecialStar_FX.aac');
                        break;

                    default:
                        break

                }

                this.audioPlayer.currentTime = 0;
                this.audioPlayer.play();

            }
        }


    };

    stopSound = async () => {
        this.audioPlayer.pause();
        this.backgroundPlayer.pause();
    };

    _timer = () => {
        this.interval = setInterval(() => {
            this.setState(prevState => ({
                timer: prevState.timer - 1,
            }), () => {

                if (this.state.timer === 0) {
                    this._stopCountDown();
                    this._next(null, this.state.parolenIndex, -2);
                }
            });
        }, 1000)
    };

    _startCountDown = () => {
        if (this.state.settings != null) {
            if (!this.state.settings.schwierigkeitEinfachChecked) {
                this._timer();
            }
        } else {
            this._timer();
        }
    };

    _stopCountDown = () => {
        clearInterval(this.interval); // Not working
    };

    componentWillUnmount() {
        this._stopCountDown();

        BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    }

    componentDidMount() {

        BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);

        AsyncStorage.getItem('avatar', (err, result) => {
            if (result !== null) {
                this.setState({avatar: JSON.parse(result)});
            } else {
                this.setState({
                    avatar: {
                        avatarKopf: 'Avatar_Head_01_color',
                        avatarHaare: 'Avatar_Hair_01_FG_color',
                        avatarHaareBG: 'Avatar_Hair_01_BG_color',
                        avatarGesicht: 'Avatar_Face_01',
                        avatarTorso: 'Avatar_Clothes_01_color',
                        avatarAccessoire: 'Avatar_Acc_01',
                        avatarKopfColor: 1,
                        avatarHaareColor: 1,
                        avatarTorsoColor: 1
                    }
                })
            }
        });

        AsyncStorage.getItem('isModeFreePlay', (err, result) => {

            const {navigation} = this.props;
            const selectedLocation = navigation.getParam('selectedLocation');
            const selectedAllCategories = navigation.getParam('selectedAllCategories');

            if (result !== null) {
                this.setState({isModeFreePlay: true, maxTimer: 40, timer: 40});
            } else {
                if (selectedLocation === 9) {
                    this.setState({isModeFreePlay: true, maxTimer: 50, timer: 50});
                }
            }

            console.log("isModeFreePlay", result)
            // remove some gegner from category

            var gegnerElementeWithRemovedHidden = [];

            var ge;
            for (ge = 0; ge < this.state.gegnerElemente.gegner.length; ge++) {

                var adding = true;
                const gegner = this.state.gegnerElemente.gegner[ge];
                const hidden = gegner.hidden;

                var hid;
                for (hid = 0; hid < hidden.length; hid++) {

                    const hiddenId = hidden[hid];

                    // iterate categories from all cat

                    var cat;
                    for (cat = 0; cat < selectedAllCategories.length; cat++) {

                        const catId = selectedAllCategories[cat];

                        if (catId === hiddenId) {
                            adding = false

                            //console.log(catId, hiddenId, "addinf false")
                        }
                    }


                }

                if (adding) {
                    gegnerElementeWithRemovedHidden.push(gegner)
                }

            }

            var gegnerElement = gegnerElementeWithRemovedHidden[Math.floor((Math.random() * gegnerElementeWithRemovedHidden.length))];
            this.setState({gegnerElement: gegnerElement});


            var isModeFreePlay = false;

            if (result !== null) {
                isModeFreePlay = true
            }

            this._initGame(isModeFreePlay);

            AsyncStorage.getItem('parolen_settings', (err, result) => {
                if (result !== null) {

                    const settings = JSON.parse(result);

                    this.setState({
                        settings: {
                            musikChecked: settings.musikChecked,
                            soundChecked: settings.soundChecked,
                            erklarungenChecked: settings.erklarungenChecked,
                            schwierigkeitEinfachChecked: settings.schwierigkeitEinfachChecked,
                            schwierigkeitNormalChecked: settings.schwierigkeitNormalChecked,
                        }
                    }, () => {
                        AsyncStorage.getItem('popup', (err, result) => {

                            if (result !== null) {
                                this.setState({popup: JSON.parse(result)}, () => {
                                    this._showMessages()
                                })

                            } else {
                                this._showMessages()
                            }


                            AsyncStorage.getItem('lastreview', (err, result) => {
                                if (result !== null) {
                                    this.setState({lastreview: JSON.parse(result)})

                                }
                            });

                            AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                                if (result !== null) {
                                    this.setState({parolenStarsFreeplay: JSON.parse(result)})
                                } else {
                                    this.setState({parolenStarsFreeplay: {}})
                                }

                                AsyncStorage.getItem('parolen_stars_win_last_location', (err, result) => {
                                    if (result !== null) {
                                        this.setState({parolenStarsWinLastLocation: true});
                                    }
                                });

                                AsyncStorage.getItem('parolen_stars_win', (err, result) => {

                                    //console.log(result)

                                    if (result !== null) {
                                        this.setState({parolenStarsWin: JSON.parse(result)})
                                    } else {
                                        this.setState({parolenStarsWin: {}})
                                    }

                                    AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                                        //console.log(result)

                                        if (result !== null) {
                                            this.setState({parolenStarsWinLocation: JSON.parse(result)})
                                        }

                                    });


                                });

                            });


                        });
                    });
                } else {
                    AsyncStorage.getItem('popup', (err, result) => {

                        if (result !== null) {
                            this.setState({popup: JSON.parse(result)}, () => {
                                this._showMessages()
                            })

                        } else {
                            this._showMessages()
                        }


                        AsyncStorage.getItem('lastreview', (err, result) => {
                            if (result !== null) {
                                this.setState({lastreview: JSON.parse(result)})

                            }
                        });

                        AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                            if (result !== null) {
                                this.setState({parolenStarsFreeplay: JSON.parse(result)})
                            } else {
                                this.setState({parolenStarsFreeplay: {}})
                            }

                            AsyncStorage.getItem('parolen_stars_win_last_location', (err, result) => {
                                if (result !== null) {
                                    this.setState({parolenStarsWinLastLocation: true});
                                }
                            });

                            AsyncStorage.getItem('parolen_stars_win', (err, result) => {

                                //console.log(result)

                                if (result !== null) {
                                    this.setState({parolenStarsWin: JSON.parse(result)})
                                } else {
                                    this.setState({parolenStarsWin: {}})
                                }

                                AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                                    //console.log(result)

                                    if (result !== null) {
                                        this.setState({parolenStarsWinLocation: JSON.parse(result)})
                                    }

                                });


                            });

                        });


                    });
                }
            });

        });

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {
                const settings = JSON.parse(result);

                this.setState({
                    settings: {
                        musikChecked: settings.musikChecked,
                        soundChecked: settings.soundChecked,
                        erklarungenChecked: settings.erklarungenChecked,
                        schwierigkeitEinfachChecked: settings.schwierigkeitEinfachChecked,
                        schwierigkeitNormalChecked: settings.schwierigkeitNormalChecked,
                    }
                });

                if (settings.soundChecked) {
                    this.playSound('04_StartEncounter_FX');
                    this.playBackgroundSound();
                }
            } else {
                this.playSound('04_StartEncounter_FX');
                this.playBackgroundSound();
            }

        });

    }

    _shuffleArray(array) {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    _initGame(isModeFreePlay) {


        const {navigation} = this.props;
        const selectedLocation = navigation.getParam('selectedLocation');
        const selectedCategory = navigation.getParam('selectedCategory');
        var maxParolenForRound = this.state.maxParolenForRound;

        console.log(isModeFreePlay, "isModeFreePlay")
        if (isModeFreePlay) {
            maxParolenForRound = 4;
            this.setState({maxParolenForRound: 4})
        }

        if (selectedLocation === 9 && isModeFreePlay) {
            maxParolenForRound = 7;
            this.setState({maxParolenForRound: 7})
        }

        if (selectedLocation === 9 && !isModeFreePlay) {
            maxParolenForRound = 5;
            this.setState({maxParolenForRound: 5})
        }


        const selectedCategories = navigation.getParam('selectedCategories');
        const selectedStarteskalation = navigation.getParam('selectedStarteskalation');
        const selectedCategoryId = navigation.getParam('selectedCategoryId');
        const selectedCategoryColor = navigation.getParam('selectedCategoryColor');
        const selectedCategoryName = navigation.getParam('selectedCategoryName');
        const selectedAllCategories = navigation.getParam('selectedAllCategories');

        if (selectedStarteskalation !== null) {
            const shuffleStarteskalation = this._shuffleArray(selectedStarteskalation)[0];
            const shuffleStarteskalationBar = (shuffleStarteskalation - 10) * 2;

            if (shuffleStarteskalation !== 10) {
                this.setState({bar: shuffleStarteskalationBar + 50, pointsForRound: -(shuffleStarteskalation - 10)})
            }
        }

        if (selectedCategoryId !== null) {
            this.setState({
                selectedCategory: selectedCategory,
                selectedCategoryId: selectedCategoryId,
                selectedCategoryName: selectedCategoryName,
                selectedCategoryColor: selectedCategoryColor,
                selectedAllCategories: selectedAllCategories
            })
        }

        // select parolen from selected categories

        var parolen = [];
        var selectedCategoriesShuffle = this._shuffleArray(selectedCategories)[0];

        var p;
        for (p = 0; p < this.state.parolenElemente.parolen.length; p++) {

            const paroleCategories = this.state.parolenElemente.parolen[p];

            if (selectedCategory !== null) {
                if (selectedCategory === paroleCategories.id) {
                    parolen.push(...paroleCategories.parolen)
                }
            } else {

                if (selectedLocation !== 9) {

                    if (selectedCategoriesShuffle === paroleCategories.id) {
                        parolen.push(...paroleCategories.parolen)
                    }
                } else {
                    if (selectedCategories.includes(paroleCategories.id)) {
                        parolen.push(...paroleCategories.parolen)
                    }
                }

            }


        }

        var parolenShuffle = this._shuffleArray(parolen);
        var parolenForRound = [];

        var ps;
        for (ps = 0; ps < parolenShuffle.length; ps++) {

            const parolenShuffleParole = parolenShuffle[ps];

            var answersForParole = [];

            // (--) -2 Points
            var badAnswers = this.state.parolenElemente.answers;

            // (-) -1 Points
            var lowAnswers = [];

            // (+) +1 Points
            var mediumAnswers = [];

            // (++) +2 Points
            var goodAnswers = [];


            var a;
            for (a = 0; a < parolenShuffleParole.answers.length; a++) {

                // get the answers
                const parolenAnswer = parolenShuffleParole.answers[a];


                if (parolenAnswer.points === -1) {
                    lowAnswers.push(parolenAnswer)
                }

                if (parolenAnswer.points === 1) {
                    mediumAnswers.push(parolenAnswer)
                }

                if (parolenAnswer.points === 2) {
                    goodAnswers.push(parolenAnswer)
                }

            }

            //console.log("lowAnswers", lowAnswers.length)
            //console.log("mediumAnswers", mediumAnswers.length)
            //console.log("goodAnswers", goodAnswers.length)
            //console.log("badAnswers", badAnswers.length)


            // shuffle the answers

            var goodAnswersShuffle;
            var lowAnswersShuffle = this._shuffleArray(lowAnswers)[0];
            var mediumAnswersShuffle = this._shuffleArray(mediumAnswers)[0];
            var badAnswersShuffle = this._shuffleArray(badAnswers)[0];

            // refresh only over level and 50/50 chance

            var alternativeAnswersForParole = [];

            if (selectedLocation > 5 && !isModeFreePlay || isModeFreePlay) {

                if (Math.random() >= 0.5) {
                    if (mediumAnswersShuffle.length > 1) {
                        goodAnswersShuffle = this._shuffleArray(mediumAnswers)[0];
                    } else {
                        goodAnswersShuffle = this._shuffleArray(goodAnswers)[0];
                    }
                } else {
                    goodAnswersShuffle = this._shuffleArray(goodAnswers)[0];
                }

                var lowAnswersShuffleAlternative;
                var mediumAnswersShuffleAlternative;
                var goodAnswersShuffleAlternative;
                var badAnswersShuffleAlternative;

                // if elements are lenght < 1 show same after refresh again

                if (lowAnswers.length > 1) {
                    lowAnswersShuffleAlternative = this._shuffleArray(lowAnswers)[1];

                } else {
                    lowAnswersShuffleAlternative = this._shuffleArray(lowAnswers)[0];
                }

                if (mediumAnswers.length > 1) {
                    mediumAnswersShuffleAlternative = this._shuffleArray(mediumAnswers)[1];

                } else {
                    mediumAnswersShuffleAlternative = this._shuffleArray(mediumAnswers)[0];
                }

                if (goodAnswers.length > 1) {

                    goodAnswersShuffleAlternative = this._shuffleArray(goodAnswers)[1];

                    if (Boolean(Math.round(Math.random()))) {
                        if (mediumAnswers.length > 1) {
                            goodAnswersShuffleAlternative = this._shuffleArray(mediumAnswers)[0];
                        }
                    }

                } else {
                    goodAnswersShuffleAlternative = this._shuffleArray(goodAnswers)[0];
                }

                if (badAnswers.length > 1) {
                    badAnswersShuffleAlternative = this._shuffleArray(badAnswers)[1];

                } else {
                    badAnswersShuffleAlternative = this._shuffleArray(badAnswers)[0];
                }


                alternativeAnswersForParole = [lowAnswersShuffleAlternative, mediumAnswersShuffleAlternative, goodAnswersShuffleAlternative, badAnswersShuffleAlternative];
                alternativeAnswersForParole = this._shuffleArray(alternativeAnswersForParole);

            } else {
                goodAnswersShuffle = this._shuffleArray(goodAnswers)[0];
            }

            answersForParole = [lowAnswersShuffle, mediumAnswersShuffle, goodAnswersShuffle, badAnswersShuffle];
            answersForParole = this._shuffleArray(answersForParole);

            parolenShuffleParole.answers = answersForParole;
            parolenShuffleParole.alternativeAnswers = alternativeAnswersForParole;

            parolenForRound.push(parolenShuffleParole);
        }

        // select eskalationen

        if (selectedLocation > 1 && !isModeFreePlay || isModeFreePlay) {

            var eskalationen = [];

            var ec;
            for (ec = 0; ec < this.state.eskalationenElemente.eskalationen.length; ec++) {
                const eskalation = this.state.eskalationenElemente.eskalationen[ec];
                eskalationen.push(eskalation)
            }

            var eskalationenShuffle = this._shuffleArray(eskalationen);
            var eskalationenForRound = [];

            var es;
            for (es = 0; es < eskalationenShuffle.length; es++) {

                const eskalationShuffleEskalation = eskalationenShuffle[es];

                //console.log("eskalationShuffleEskalation", eskalationShuffleEskalation)

                var answersForEskalation = [];

                // (--) -2 Points
                var badAnswersForEskalation = this.state.eskalationenElemente.answers;

                // (-) -1 Points
                var lowAnswersForEskalation = [];

                // (++) +2 Points
                var goodAnswersForEskalation = [];


                var e;
                for (e = 0; e < eskalationenShuffle[es].answers.length; e++) {

                    // get the answers
                    const eskalationenAnswers = eskalationenShuffle[es].answers[e];

                    //console.log("eskalationenAnswers undefined???", eskalationenAnswers)

                    if (eskalationenAnswers.points === 1) {
                        lowAnswersForEskalation.push(eskalationenAnswers)
                    }

                    if (eskalationenAnswers.points === 3) {
                        goodAnswersForEskalation.push(eskalationenAnswers)
                    }

                }

                // shuffle the answers

                var lowAnswersShuffleForEskalation = this._shuffleArray(lowAnswersForEskalation)[0];

                var goodAnswersShuffleForEskalation = this._shuffleArray(goodAnswersForEskalation)[0];

                var badAnswersShuffleForEskalation = this._shuffleArray(badAnswersForEskalation)[0];

                answersForEskalation = [lowAnswersShuffleForEskalation, goodAnswersShuffleForEskalation, badAnswersShuffleForEskalation];
                answersForEskalation = this._shuffleArray(answersForEskalation);

                eskalationShuffleEskalation.answers = answersForEskalation;

                eskalationenForRound.push(eskalationShuffleEskalation);
            }

            this.setState({
                eskalationenForRound: eskalationenForRound.slice(0, maxParolenForRound),
                parolenForRound: parolenForRound.slice(0, maxParolenForRound),
            })

        } else {

            this.setState({
                parolenForRound: parolenForRound.slice(0, maxParolenForRound),
            })
        }


    }

    _next(selectedAnswer, index, points) {

        if (!this.state.active) {

            this._stopCountDown();

            this.setState({active: true});

            const {navigation} = this.props;
            const selectedLocation = navigation.getParam('selectedLocation');

            const selected = this.state.selected;

            var changeBar = 0;
            var pointsForRound = this.state.pointsForRound;

            if (points === -3) {
                this.playSound('11_AnswerPoor_FX');

                pointsForRound = pointsForRound - 3;
                selected[index] = bganswerred;
                changeBar = +20;

            }

            if (points === -2) {
                this.playSound('11_AnswerPoor_FX');

                pointsForRound = pointsForRound - 2;
                selected[index] = bganswerred;
                changeBar = +10;

            }

            if (points === -1) {
                this.playSound('10_AnswerMedium_FX');

                pointsForRound = pointsForRound - 1;
                selected[index] = bganswerorange;
                changeBar = +5;

            }

            if (points === 1) {
                this.playSound('10_AnswerMedium_FX');

                pointsForRound = pointsForRound + 1;
                selected[index] = bganswergelb;
                changeBar = -5;


            }

            if (points === 2) {
                this.playSound('09_AnswerBest_FX');

                pointsForRound = pointsForRound + 2;
                selected[index] = bganswergreen;
                changeBar = -10;


            }

            if (points === 3) {
                this.playSound('09_AnswerBest_FX');

                pointsForRound = pointsForRound + 3;
                selected[index] = bganswergreen;
                changeBar = -20;


            }

            // trigger message for timeout

            var popup = this.state.popup;

            if (this.state.eskalation && selectedAnswer === null) {

                this._stopCountDown();

                if (popup.timeout === undefined) {
                    this.setState({
                        message: {
                            id: 'timeout',
                            text: this.state.messages.timeout,
                            position: 'bottom'
                        }
                    });
                }

            }

            var refreshedParolenForRound = this.state.refreshedParolenForRound;

            if (this.state.refresh) {
                refreshedParolenForRound.push(true)
            } else {
                refreshedParolenForRound.push(false)
            }

            this.setState({
                selected: selected,
                bar: this.state.bar + changeBar,
                pointsForRound: pointsForRound,
                refreshedParolenForRound: refreshedParolenForRound,
            });

            const showNext = this._showNext.bind(this);

            setTimeout(function () {
                showNext(selectedAnswer, selectedLocation, bganswer);
            }, 1000);

            var t = this;

            setTimeout(function () {
                t.setState({showBar: 0})
            }, 100);

            setTimeout(function () {
                t.setState({showBar: 1})
            }, 200);

            setTimeout(function () {
                t.setState({showBar: 0})
            }, 300);

            setTimeout(function () {
                t.setState({showBar: 1})
            }, 400);


        }

    }

    _showNext(selectedAnswer, selectedLocation, bganswer) {

        const nextParole = this.state.parolenIndex + 1;
        const selectedClear = {selected: {'0': bganswer, '1': bganswer, '2': bganswer, '3': bganswer}};

        this.setState(selectedClear);

        var selectedAnswerForRound = this.state.selectedAnswerForRound;
        selectedAnswerForRound.push(selectedAnswer);

        var selectedEskalationenForRound = this.state.selectedEskalationenForRound;

        if (this.state.eskalation) {
            selectedEskalationenForRound.push(this.state.eskalationenForRound[this.state.parolenIndex]);
        } else {
            selectedEskalationenForRound.push(null);
        }

        this.setState({
            selectedAnswerForRound: selectedAnswerForRound, refresh: false
        });

        var popup = this.state.popup;

        if (nextParole === this.state.maxParolenForRound) {

            this.stopSound()

            this.props.navigation.push('ReviewScreen', {
                selectedLocation: selectedLocation,
                selectedCategory: this.state.selectedCategory,
                selectedCategoryId: this.state.selectedCategoryId,
                selectedCategoryName: this.state.selectedCategoryName,
                selectedCategoryColor: this.state.selectedCategoryColor,
                parolenForRound: this.state.parolenForRound,
                eskalationenForRound: this.state.eskalationenForRound,
                selectedAnswerForRound: this.state.selectedAnswerForRound,
                selectedEskalationenForRound: this.state.selectedEskalationenForRound,
                pointsForRound: this.state.pointsForRound,
                refreshedParolenForRound: this.state.refreshedParolenForRound
            });

        } else {

            //console.log(this.state.bar)

            if (selectedLocation === 9) {
                var gegnerElement = this.state.gegnerElemente.gegner[Math.floor((Math.random() * 23) + 1)];
                this.setState({gegnerElement: gegnerElement});
            }
            console.log(gegnerElement, "gegnerElement")

            //console.log(this.state.bar);
            if (this.state.eskalation) {
                this.setState({parolenIndex: nextParole, timer: this.state.maxTimer, eskalation: false});
            } else {

                if (selectedLocation === 2 && this.state.parolenIndex === 0 && this.state.isModeFreePlay === false) {
                    this.setState({parolenIndex: nextParole, timer: this.state.maxTimer, eskalation: true});

                    if (popup.eskalation === undefined) {

                        this._stopCountDown();

                        this.setState({
                            message: {
                                id: 'eskalation',
                                text: this.state.messages.eskalation,
                                position: 'middle'
                            }
                        });
                    }
                } else {
                    if (selectedLocation > 1 && this.state.bar > 60 && !this.state.isModeFreePlay || this.state.bar > 60 && this.state.isModeFreePlay) {
                        if (Math.random() >= 0.5) {
                            //console.log("eskalation!!")

                            this.playSound('08_Escalation_FX');

                            this.setState({parolenIndex: nextParole, timer: this.state.maxTimer, eskalation: true});
                        } else {
                            this.setState({parolenIndex: nextParole, timer: this.state.maxTimer, eskalation: false});
                        }
                    } else {
                        this.setState({parolenIndex: nextParole, timer: this.state.maxTimer, eskalation: false});
                    }
                }


            }

            this._startCountDown();
        }

        this.setState({active: false});

    }


    _toggle(identifier) {

        if (identifier === 'schwierigkeitEinfach') {
            this.setState({schwierigkeitEinfachChecked: true, schwierigkeitNormalChecked: false}, () => this._save());
        } else {
            this.setState({schwierigkeitEinfachChecked: false, schwierigkeitNormalChecked: true}, () => this._save());
        }

    }

    _togglePause() {

        var pause = this.state.gamePause;

        if (pause) {
            this.setState({gamePause: false});
            this._startCountDown()
            this.playBackgroundSound();

        } else {
            this.setState({gamePause: true});
            this._stopCountDown()
            this.stopSound();

        }

    }

    _showMessages() {

        //if (this.state.settings.erklarungenChecked) {

        if (this.state.popup != null) {

            var popup = this.state.popup;

            if (popup.gamestart === undefined) {
                this.setState({
                    message: {
                        id: 'gamestart',
                        text: this.state.messages.gamestart,
                        position: 'bottom'
                    }
                });
            } else {
                this._startCountDown();
            }

            if (this.state.parolenForRound[0].alternativeAnswers.length > 0) {
                if (popup.refresh === undefined) {

                    this._stopCountDown();

                    this.setState({
                        message: {
                            id: 'refresh',
                            text: this.state.messages.refresh,
                            position: 'bottom'
                        }
                    });
                }
            }


        } else {
            this.setState({
                message: {
                    id: 'gamestart',
                    text: this.state.messages.gamestart,
                    position: 'bottom'
                }
            });
        }
        //}

    }

    _hideMessages() {
        this.setState({message: null})
    }

    handleBackButton() {
        return true;
    }

    _nextMessages(id) {

        if (id === 'gamestart') {

            if (!this.state.settings.schwierigkeitEinfachChecked) {
                this.setState({message: {id: 'gamestart_2', text: this.state.messages.gamestart_2, position: 'top'}});
            } else {
                this.setState({
                    message: {
                        id: 'gamestart_2_difficult_easy',
                        text: this.state.messages.gamestart_2_difficult_easy,
                        position: 'top'
                    }
                });
            }

        } else if (id === 'gamestart_2') {
            this.setState({message: {id: 'gamestart_3', text: this.state.messages.gamestart_3, position: 'bottom'}});
        } else if (id === 'gamestart_3') {
            this._savePopUp('gamestart');
            this._startCountDown();
            this._hideMessages()
        } else if (id === 'refresh') {
            this._savePopUp('refresh');
            this._startCountDown();
            this._hideMessages()
        } else if (id === 'eskalation') {
            this.setState({message: {id: 'eskalation_2', text: this.state.messages.eskalation_2, position: 'bottom'}});
        } else if (id === 'eskalation_2') {
            this._savePopUp('eskalation');
            this._startCountDown();
            this._hideMessages()
        } else if (id === 'timeout') {
            this._savePopUp('timeout');
            this._startCountDown();
            this._hideMessages()
        } else {
            this._startCountDown();
            this._hideMessages();
        }

    }

    _savePopUp(id) {

        var popup = this.state.popup;
        if (popup === null) {
            popup = {};
        }
        popup[id] = true;

        AsyncStorage.setItem('popup', JSON.stringify(popup));

    }

    _endgame() {
        this.stopSound();
        this.props.navigation.goBack()
    }

    _refresh() {

        var parolenForRound = this.state.parolenForRound;
        var parolenIndex = this.state.parolenIndex;

        var alternativeAnswers = parolenForRound[parolenIndex].alternativeAnswers;
        var goodRefresh = false;

        var i;
        for (i = 0; i < alternativeAnswers.length; i++) {

            const points = alternativeAnswers[i].points;

            if (points === 2) {
                goodRefresh = true;
            }
        }

        if (goodRefresh) {
            this.playSound('06_RefreshGood_FX');
            this.setState({refresh: true, goodRefresh: true});
        } else {
            this.playSound('07_RefreshBad_FX');
            this.setState({refresh: true, goodRefresh: false});
        }

    }

    render() {

        var avatar = null;
        var gegnerElement = this.state.gegnerElement;

        var parolenForRound = this.state.parolenForRound;
        var eskalationenForRound = null;
        var parolenIndex = this.state.parolenIndex;

        var selected = this.state.selected;
        var pause = null;

        var navigation = this.props.navigation;

        var popup = null;

        if (this.state.message !== null) {

            var popupPositionStyle;
            var popupPositionTextStyle = {
                fontSize: 14,
                lineHeight: 17
            };

            var messageText = this.state.message.text;

            let weiterButtonWrapperStyle = {
                flex: 1,
                flexDirection: 'row', flexWrap: 'wrap',
                alignItems: 'flex-start',
                marginBottom: 30,
                marginTop: 120,
                marginLeft: 15,
                width: '100%',
                height: 50
            }

            if (this.state.avatar !== null) {
                if (this.state.avatar.name !== undefined) {
                    messageText = messageText.replace('[Spielername]', this.state.avatar.name)
                } else {
                    messageText = messageText.replace('[Spielername]', '')
                }

            } else {
                messageText = messageText.replace('[Spielername]', '')
            }

            if (this.state.message.position === 'top') {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.065,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.60,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 9
                };
            } else if (this.state.message.position === 'middle') {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.065,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.60,
                    position: 'absolute',
                    top: 50,
                    left: 0,
                    zIndex: 9
                };
            } else if (this.state.message.position === 'big') {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.12,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.70,
                    position: 'absolute',
                    top: 50,
                    left: 0,
                    zIndex: 9
                };

                weiterButtonWrapperStyle = {
                    flex: 1,
                    flexDirection: 'row', flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    marginBottom: 30,
                    marginTop: 340,
                    marginLeft: 15,
                    width: '100%',
                    height: 50
                };


            } else {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.065,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.60,
                    position: 'absolute',
                    top: 95,
                    left: 0,
                    zIndex: 9
                };
            }

            popup = <View>

                <View style={styles.pauseOverlay}><Text>.</Text></View>

                <View style={popupPositionStyle}>

                    <View style={styles.locationContainer}>
                        <Text style={popupPositionTextStyle}>{messageText}</Text>
                    </View>

                    <TouchableOpacity style={weiterButtonWrapperStyle}
                                      onPress={this._nextMessages.bind(this, this.state.message.id)}>
                        <Image
                            style={styles.weiterButton}
                            source={require('../assets/images/buttons/weiter.png')}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>

                </View>


            </View>
        }

        if (this.state.gamePause) {
            pause = <View>

                <View style={styles.pauseOverlay}><Text>.</Text></View>

                <View style={styles.pausePositionStyle}>
                    <View style={styles.pauseContainer}>
                        <Text style={styles.pausePositionTextStyle}>SPIEL PAUSIERT</Text>
                    </View>

                    <View style={styles.endButtonWrapper}>

                        <TouchableOpacity onPress={this._endgame.bind(this)} style={styles.endButton}>
                            <Image style={styles.button}
                                   source={require('../assets/images/buttons/endgame.png')}
                                   resizeMode="contain"
                            />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={this._togglePause.bind(this)} style={styles.endButton}>
                            <Image style={styles.button}
                                   source={require('../assets/images/buttons/playcont.png')}
                                   resizeMode="contain"
                            />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        }

        if (this.state.avatar !== null) {
            var avatarKopf = this.state.avatar.avatarKopf;
            var avatarHaare = this.state.avatar.avatarHaare;
            var avatarHaareBG = this.state.avatar.avatarHaareBG;
            var avatarGesicht = this.state.avatar.avatarGesicht;
            var avatarTorso = this.state.avatar.avatarTorso;
            var avatarAccessoire = this.state.avatar.avatarAccessoire;
            var avatarKopfColor = this.state.avatar.avatarKopfColor;
            var avatarHaareColor = this.state.avatar.avatarHaareColor;
            var avatarTorsoColor = this.state.avatar.avatarTorsoColor;

            avatar = <View>
                <Image
                    source={charactereditor[avatarHaareBG + avatarHaareColor]}
                    style={styles.avatarHair}
                    resizeMode="contain"
                />

                <Image
                    source={charactereditor[avatarKopf + avatarKopfColor]}
                    style={styles.avatarHead}
                    resizeMode="contain"
                />

                <Image
                    source={charactereditor[avatarTorso + avatarTorsoColor]}
                    style={styles.avatarClothes}
                    resizeMode="contain"
                />

                <Image
                    source={charactereditor[avatarHaare + avatarHaareColor]}
                    style={styles.avatarHair}
                    resizeMode="contain"
                />


                <Image
                    source={charactereditor[avatarGesicht]}
                    style={styles.avatarFace}
                    resizeMode="contain"
                />

                <Image
                    source={charactereditor[avatarAccessoire]}
                    style={styles.avatarAcc}
                    resizeMode="contain"
                />

            </View>
        }

        if (this.state.eskalationenForRound !== null) {

            eskalationenForRound = this.state.eskalationenForRound;
            //console.log("eskalationenForRound", this.state.eskalationenForRound.length)
        }

        var bgparole = require('../assets/images/game/bgparole.png');
        var redBorder = null;

        if (this.state.eskalation) {
            redBorder = <View
                style={{
                    width: (deviceWidth * 1.0),
                    height: (deviceHeight * 1.0) - 24,
                    position: 'absolute',
                    left: 0,
                    top: 24,
                    zIndex: -1,
                    borderColor: '#FF0000',
                    borderWidth: 4
                }} resizeMode="stretch"><Text>.</Text></View>;
            bgparole = require('../assets/images/game/bgparole_break.png');
        }

        if (this.state.parolenForRound !== null
            && this.state.gegnerElement !== null) {

            var bar = this.state.bar + '%';

            if (this.state.bar >= 90) {
                bar = '90%';
            }
            if (this.state.bar <= 10) {
                bar = '10%';
            }

            var refreshButton =
                <TouchableOpacity style={styles.refresh}>

                    <ImageBackground source={require('../assets/images/game/avatar_norefresh.png')}
                                     style={styles.refreshImage}
                                     resizeMode="contain">


                    </ImageBackground>

                </TouchableOpacity>;

            if (parolenForRound[parolenIndex].alternativeAnswers.length > 0 && !this.state.eskalation) {

                refreshButton =
                    <TouchableOpacity style={styles.refresh}
                                      onPress={this._refresh.bind(this)}>

                        <ImageBackground source={require('../assets/images/game/avatar_refresh.png')}
                                         style={styles.refreshImage}
                                         resizeMode="contain">


                        </ImageBackground>

                    </TouchableOpacity>

            }

            if (this.state.refresh) {
                if (this.state.goodRefresh) {
                    refreshButton =
                        <TouchableOpacity style={styles.refresh}>

                            <ImageBackground source={require('../assets/images/game/avatar_refresh_green.png')}
                                             style={styles.refreshImage}
                                             resizeMode="contain">


                            </ImageBackground>

                        </TouchableOpacity>
                } else {
                    refreshButton =
                        <TouchableOpacity style={styles.refresh}>

                            <ImageBackground source={require('../assets/images/game/avatar_refresh_rot.png')}
                                             style={styles.refreshImage}
                                             resizeMode="contain">


                            </ImageBackground>

                        </TouchableOpacity>
                }
            }

            var answer1 = parolenForRound[parolenIndex].answers[0];
            var answer2 = parolenForRound[parolenIndex].answers[1];
            var answer3 = parolenForRound[parolenIndex].answers[2];
            var answer4 = parolenForRound[parolenIndex].answers[3];

            if (this.state.refresh) {
                answer1 = parolenForRound[parolenIndex].alternativeAnswers[0];
                answer2 = parolenForRound[parolenIndex].alternativeAnswers[1];
                answer3 = parolenForRound[parolenIndex].alternativeAnswers[2];
                answer4 = parolenForRound[parolenIndex].alternativeAnswers[3];
            }

            var answers = <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                           style={{
                                               width: '100%',
                                               height: deviceHeight * .82,
                                               marginTop: '20%',
                                               borderRadius: 20,
                                               overflow: 'hidden',
                                               zIndex: 0
                                           }}>
                <View style={styles.container} contentContainerStyle={styles.contentContainer}>

                    <Image
                        source={gegner[gegnerElement.image]}
                        style={styles.gegner}
                        resizeMode="contain"
                    />

                    <ImageBackground source={bgparole}
                                     style={{
                                         width: deviceWidth * .99,
                                         height: 119,
                                         marginLeft: 10,
                                         marginTop: 8,
                                         zIndex: 1
                                     }}
                                     resizeMode="contain">
                        <Text style={styles.parole}>{parolenForRound[parolenIndex].title}</Text>

                    </ImageBackground>


                </View>

                <ImageBackground source={require('../assets/images/game/bganswers.png')}
                                 style={{
                                     width: deviceWidth * 0.85,
                                     height: '90%',
                                     marginLeft: 27,
                                     marginTop: 110,
                                     paddingTop: 26,
                                     zIndex: 3
                                 }}
                                 resizeMode="stretch">


                    <TouchableOpacity
                        onPress={this._next.bind(this, answer1, 0, answer1.points)}>
                        <ImageBackground source={selected['0']}
                                         style={styles.bganswer}
                                         resizeMode="stretch">
                            <Text
                                style={styles.bganswerText}>{answer1.text}</Text>
                        </ImageBackground>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={this._next.bind(this, answer2, 1, answer2.points)}>
                        <ImageBackground source={selected['1']}
                                         style={styles.bganswer}
                                         resizeMode="stretch">
                            <Text
                                style={styles.bganswerText}>{answer2.text}</Text>
                        </ImageBackground>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={this._next.bind(this, answer3, 2, answer3.points)}>
                        <ImageBackground source={selected['2']}
                                         style={styles.bganswer}
                                         resizeMode="stretch">
                            <Text
                                style={styles.bganswerText}>{answer3.text}</Text>
                        </ImageBackground>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={this._next.bind(this, answer4, 3, answer4.points)}>
                        <ImageBackground source={selected['3']}
                                         style={styles.bganswer}
                                         resizeMode="stretch">
                            <Text
                                style={styles.bganswerText}>{answer4.text}</Text>
                        </ImageBackground>
                    </TouchableOpacity>

                </ImageBackground>

            </ImageBackground>;

            //console.log(eskalationenForRound, "eskalationenForRound")
            if (this.state.eskalation) {

                answer1 = eskalationenForRound[parolenIndex].answers[0];
                answer2 = eskalationenForRound[parolenIndex].answers[1];
                answer3 = eskalationenForRound[parolenIndex].answers[2];

                //console.log(eskalationenForRound[parolenIndex]);

                answers = <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                           style={{
                                               width: (deviceWidth * 1.0) - 8,
                                               height: deviceHeight * .80,
                                               marginTop: '20%',
                                               marginLeft: 4,
                                               borderRadius: 20,
                                               overflow: 'hidden',
                                               zIndex: 0
                                           }}>

                    <View style={styles.container} contentContainerStyle={styles.contentContainer}>

                        <Image
                            source={gegner[gegnerElement.image]}
                            style={styles.gegner}
                            resizeMode="contain"
                        />

                        <ImageBackground source={bgparole}
                                         style={{
                                             width: deviceWidth * .99,
                                             height: 120,
                                             marginLeft: 10,
                                             marginTop: 23,
                                             zIndex: 2
                                         }}
                                         resizeMode="contain">
                            <Text style={styles.parole}>{eskalationenForRound[parolenIndex].question}</Text>

                        </ImageBackground>

                    </View>

                    <ImageBackground source={require('../assets/images/game/bganswers.png')}
                                     style={{
                                         width: (deviceWidth * 0.8) + 20,
                                         height: '90%',
                                         marginLeft: 27,
                                         marginTop: 130,
                                         paddingTop: 26,
                                         zIndex: 3
                                     }}
                                     resizeMode="stretch">


                        <TouchableOpacity
                            onPress={this._next.bind(this, answer1, 0, answer1.points)}>
                            <ImageBackground source={selected['0']}
                                             style={styles.bganswer}
                                             resizeMode="stretch">
                                <Text
                                    style={styles.bganswerText}>{answer1.text}</Text>
                            </ImageBackground>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={this._next.bind(this, answer2, 1, answer2.points)}>
                            <ImageBackground source={selected['1']}
                                             style={styles.bganswer}
                                             resizeMode="stretch">
                                <Text
                                    style={styles.bganswerText}>{answer2.text}</Text>
                            </ImageBackground>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={this._next.bind(this, answer3, 2, answer3.points)}>
                            <ImageBackground source={selected['2']}
                                             style={styles.bganswer}
                                             resizeMode="stretch">
                                <Text
                                    style={styles.bganswerText}>{answer3.text}</Text>
                            </ImageBackground>
                        </TouchableOpacity>

                    </ImageBackground>


                </ImageBackground>
            }

            if (pause !== null) {
                return (<View style={styles.container}>{pause}</View>)
            } else if (popup !== null) {
                return (<View style={styles.container}>

                    {popup}

                    <ImageBackground source={require('../assets/images/game/bar.png')}
                                     style={{
                                         width: deviceWidth * .90,
                                         height: 55,
                                         position: 'absolute',
                                         left: 20,
                                         top: 40,
                                         zIndex: 3,
                                         opacity: this.state.showBar
                                     }}
                                     resizeMode="contain">
                        <Image
                            source={require('../assets/images/game/handle.png')}
                            style={{
                                width: 28,
                                height: 28,
                                position: 'absolute',
                                left: bar,
                                top: 0,
                                marginLeft: -14
                            }}
                            resizeMode="contain"
                        />

                    </ImageBackground>

                    <View style={styles.avatar}>
                        {avatar}
                    </View>

                    {(!this.state.settings.schwierigkeitEinfachChecked) &&
                    <ImageBackground source={require('../assets/images/game/timer.png')}
                                     style={styles.timer}
                                     resizeMode="contain">

                        <Text style={styles.timerText}>{this.state.timer}</Text>

                    </ImageBackground>}

                    {refreshButton}

                    <View style={styles.pauseButton}>
                        <TouchableWithoutFeedback onPress={this._togglePause.bind(this)}>
                            <Image
                                source={gameimages['pause.png']}
                                style={styles.pauseButtonImage}
                                resizeMode="contain"
                            />
                        </TouchableWithoutFeedback>
                    </View>


                </View>)
            } else {
                return (
                    <View style={styles.container}>

                        {answers}

                        <ImageBackground source={require('../assets/images/game/bar.png')}
                                         style={{
                                             width: deviceWidth * .90,
                                             height: 55,
                                             position: 'absolute',
                                             left: 20,
                                             top: 40,
                                             zIndex: 3,
                                             opacity: this.state.showBar
                                         }}
                                         resizeMode="contain">
                            <Image
                                source={require('../assets/images/game/handle.png')}
                                style={{
                                    width: 28,
                                    height: 28,
                                    position: 'absolute',
                                    left: bar,
                                    top: 0,
                                    marginLeft: -14
                                }}
                                resizeMode="contain"
                            />

                        </ImageBackground>

                        {redBorder}

                        <View style={styles.avatar}>
                            {avatar}
                        </View>

                        {(!this.state.settings.schwierigkeitEinfachChecked) &&
                        <ImageBackground source={require('../assets/images/game/timer.png')}
                                         style={styles.timer}
                                         resizeMode="contain">

                            <Text style={styles.timerText}>{this.state.timer}</Text>

                        </ImageBackground>
                        }

                        {refreshButton}

                        <View style={styles.pauseButton}>
                            <TouchableWithoutFeedback onPress={this._togglePause.bind(this)}>
                                <Image
                                    source={gameimages['pause.png']}
                                    style={styles.pauseButtonImage}
                                    resizeMode="contain"
                                />
                            </TouchableWithoutFeedback>
                        </View>

                    </View>

                );
            }
        } else {
            return (<View><Text>...</Text></View>)
        }

    }

}

const styles = StyleSheet.create({

    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        width: deviceWidth * .90,
    },

    parole: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#FFFFFF',
        paddingLeft: 120,
        paddingRight: 100,
        paddingTop: 15
    },

    bganswer: {
        width: deviceWidth * 0.83,
        marginLeft: deviceWidth * 0.010,
        marginTop: 5,
        overflow: 'hidden',
        paddingBottom: 5
    },

    bganswerText: {
        padding: 5,
        paddingLeft: 10,
        width: '98%',
        height: 65,
        marginTop: 5,
        marginBottom: 5,
        fontSize: 11,
    },

    timer: {
        width: 95,
        height: 65,
        position: 'absolute',
        bottom: 30,
        left: 30,
        zIndex: 4
    },

    timerText: {
        color: '#f15a23',
        fontWeight: 'bold',
        fontSize: 20,
        marginLeft: 60,
        marginTop: 30
    },

    refresh: {
        width: 95,
        height: 65,
        position: 'absolute',
        bottom: 26,
        right: 30
    },


    refreshImage: {
        width: 95,
        height: 65,
    },

    pauseButton: {
        width: 35,
        height: 45,
        position: 'absolute',
        bottom: 30,
        right: (deviceWidth * 0.5) - 17,
        zIndex: 4,

    },
    pauseButtonImage: {
        width: '100%',
        height: 45,
    },

    gegner: {
        width: 60,
        height: 70,
        position: 'absolute',
        left: 46,
        top: 42,
        zIndex: 5,
    },

    avatar: {
        width: 60,
        height: 70,
        position: 'absolute',
        right: 26,
        bottom: 45,
        zIndex: 5,
    },

    avatarHead: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 70
    },

    avatarClothes: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 70
    },

    avatarHair: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 70
    },

    avatarAcc: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 70,
        zIndex: 5
    },

    avatarFace: {
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: 70,
    },

    pauseOverlay: {
        width: deviceWidth,
        height: deviceHeight,
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 8,
        opacity: 0.3,
        overflow: 'hidden',

    },

    pauseContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },

    pausePositionTextStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 17,
        marginLeft: '21%'
    },

    pausePositionStyle: {
        width: deviceWidth * 0.76,
        marginLeft: deviceWidth * 0.12,
        marginTop: deviceHeight * 0.15,
        paddingTop: 20,
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        borderRadius: 15,
        height: deviceHeight * 0.60,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9
    },

    endButtonWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 30,
        marginTop: 50,
        marginLeft: 15,
        width: '100%',
        height: 50
    },

    weiterButtonWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 30,
        marginTop: 50,
        marginLeft: 15,
        width: '100%',
        height: 50
    },

    weiterButton: {
        width: '50%',
        marginLeft: '19%',
        height: 70,
    },

    endButton: {
        width: '40%',
        marginLeft: 9,
        height: 70,
    },

    button: {
        width: '100%',
        height: 70,
    },

    popup: {
        width: deviceWidth * 0.76,
        marginLeft: deviceWidth * 0.12,
        marginTop: deviceHeight * 0.075,
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        borderRadius: 15,
        height: deviceHeight * 0.70,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9
    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },

    checkboxWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 5,
        paddingLeft: 30
    },

    checkbox: {
        width: 40,
        height: 40
    },

    checkboxText: {
        width: deviceWidth * .8,
        height: 50,
        paddingLeft: 20,
    },

    checkboxImage: {
        width: deviceWidth * .1,
        height: 50,
        margin: 0,
        marginTop: -10,
        marginRight: 20,
        marginBottom: 20
    },

});
