const gameimages = {
    '17_Uni-Professor.png' : require("./game/17_Uni-Professor.png"),
    'avatar_norefresh.png' : require("./game/avatar_norefresh.png"),
    'avatar_refresh.png' : require("./game/avatar_refresh.png"),
    'bar.png' : require("./game/bar.png"),
    'bganswer.png' : require("./game/bganswer.png"),
    'bganswergelb.png' : require("./game/bganswergelb.png"),
    'bganswergreen.png' : require("./game/bganswergreen.png"),
    'bganswerorange.png' : require("./game/bganswerorange.png"),
    'bganswerred.png' : require("./game/bganswerred.png"),
    'bganswers.png' : require("./game/bganswers.png"),
    'bganswerselected.png' : require("./game/bganswerselected.png"),
    'bgoverlay.png' : require("./game/bgoverlay.png"),
    'bgparole.png' : require("./game/bgparole.png"),
    'bgparole_break.png' : require("./game/bgparole_break.png"),
    'handle.png' : require("./game/handle.png"),
    'Paroli_Avatars_v2_Spieler_Avatar_Head_02-2.png' : require("./game/Paroli_Avatars_v2_Spieler_Avatar_Head_02-2.png"),
    'pause.png' : require("./game/pause.png"),
    'resetgame.png' : require("./buttons/resetgame.png"),
    'pause_down.png' : require("./game/pause_down.png"),
    'Rechteck4169.png' : require("./game/Rechteck4169.png"),
    'refreshicon.png' : require("./game/refreshicon.png"),
    'refreshicon_rot.png' : require("./game/refreshicon_rot.png"),
    'timer.png' : require("./game/timer.png")
};

export default gameimages;