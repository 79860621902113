import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback, TextInput, AsyncStorage,
    Linking
} from 'react-native';
import {WebBrowser} from 'expo';
import icons from "../assets/images/images_icons"

const deviceWidth = 380;
const deviceHeight = 768

export default class ParolenVerzeichnisScreen extends React.Component {

    static navigationOptions = {
        title: "Parolenverzeichnis",
        headerStyle: {
            backgroundColor: '#6eb6df'
        },
        headerTintColor: '#fff',
    };

    constructor(props) {
        super(props);
        this.state = {
            parolenElemente: null,
            toggle: -1,
            toggleParole: -1,
            search: false,
            noelements: false,
            popup: null,
            message: null,
            messages: require('../assets/json/messages.json'),
            popUpChecked: false
        };

    }

    componentDidMount() {


        const parolen = JSON.parse(JSON.stringify(require('../assets/json/parolen.json')));
        this.setState({parolenElemente: parolen})
        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {

                const settings = JSON.parse(result);

                this.setState({
                    settings: {
                        erklarungenChecked: settings.erklarungenChecked
                    }
                }, () => {
                    if (settings.erklarungenChecked) {

                        AsyncStorage.getItem('popup', (err, result) => {
                            console.log(result);
                            if (result === null) {
                                this._showMessages()
                            } else {
                                this.setState({popup: JSON.parse(result)}, () => {
                                    this._showMessages();
                                })
                            }
                        });

                    }
                });


            } else {

                AsyncStorage.getItem('popup', (err, result) => {
                    console.log(result);
                    if (result === null) {
                        this._showMessages()
                    } else {
                        this.setState({popup: JSON.parse(result)}, () => {
                            this._showMessages();
                        })
                    }
                });

            }
        });


    }

    _toggleModal(id) {

        this.setState({
            toggle: id
        });

        if (this.state.toggle === id) {
            this.setState({
                toggle: -1,
                toggleParole: -1
            })
        }
    };

    _toggleParoleModal(id) {

        this.setState({
            toggleParole: id
        });

        if (this.state.toggleParole === id) {
            this.setState({
                toggleParole: -1
            })
        }
    };

    _getUnique(arr, comp) {

        const unique = arr
            .map(e => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    _search(searchText) {

        var newData = [];

        const parolenNode =  JSON.parse(JSON.stringify({...this.state.parolenElemente}));

        if (searchText.length > 2) {

            var c;
            for (c = 0; c < parolenNode.parolen.length; c++) {

                var paroleCategory = parolenNode.parolen[c];

                if (paroleCategory.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                    newData.push(paroleCategory);
                }

                var newParoleCategory = [];

                var p;
                for (p = 0; p < paroleCategory.parolen.length; p++) {

                    const parolen = paroleCategory.parolen[p];

                    if (parolen.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                        newParoleCategory.push(parolen);
                    }

                    if (parolen.text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                        newParoleCategory.push(parolen);
                    }

                    if (parolen.keywords.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                        newParoleCategory.push(parolen);
                    }

                }

                if (newParoleCategory.length !== 0) {
                    paroleCategory.parolen = this._getUnique(newParoleCategory, 'parolenId')
                    newData.push(paroleCategory);
                }

            }

            if (newData.length !== 0) {
                console.log("newData length", newData)
                // copy from mutable object


                const newParolenNode = {parolen: this._getUnique(newData, 'id')};

                this.setState({search: true});
                this.setState({parolenElemente: newParolenNode})

            } else {
                this.setState({parolenElemente: require('../assets/json/parolen.json')})
                this.setState({noelements: true});
            }

        } else {
            this.setState({search: false});
            this.setState({parolenElemente: require('../assets/json/parolen.json')})
            this.setState({noelements: false});
        }

    }

    _showMessages() {

        var popup = this.state.popup;

        if (popup != null) {

            console.log(popup.parolenverzeichnis, "popup.parolenverzeichnis ")

            if (popup.parolenverzeichnis === undefined || popup.parolenverzeichnis === true) {
                this.setState({
                    message: {
                        id: 'parolenverzeichnis',
                        text: this.state.messages.parolenverzeichnis,
                        position: 'top'
                    }
                });
            }

        } else {
            this.setState({
                message: {
                    id: 'parolenverzeichnis',
                    text: this.state.messages.parolenverzeichnis,
                    position: 'top'
                }
            });
        }

    }

    _hideMessages() {
        if (this.state.popUpChecked) {

            var popup = this.state.popup;

            if (popup === null) {
                popup = {};
            }

            popup['parolenverzeichnis'] = true;

            AsyncStorage.setItem('popup', JSON.stringify(popup));


        }


        this.setState({message: null})
    }

    _check() {
        if (this.state.popUpChecked) {
            this.setState({popUpChecked: false});
        } else {
            this.setState({popUpChecked: true});
        }
    }

    render() {

        var popup = null;

        if (this.state.message !== null) {

            var popupPositionStyle = {
                width: deviceWidth * 0.86,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.035,
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'absolute',
                top: 5,
                left: 0,
                zIndex: 9
            };

            var popupPositionTextStyle = {
                fontSize: 15,
                lineHeight: 17
            };

            var checkbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox.png')}
                resizeMode="contain"
            />);

            if (this.state.popUpChecked) {
                checkbox = (<Image
                    style={styles.checkbox}
                    source={require('../assets/images/buttons/checkbox_checked.png')}
                    resizeMode="contain"
                />);
            }

            popup = (
                <View>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{this.state.message.text}</Text>


                            <View style={styles.line}>
                            </View>

                            <View style={styles.checkboxWrapper}>

                                <TouchableOpacity style={styles.checkboxImage}
                                                  onPress={this._check.bind(this)}>
                                    {checkbox}
                                </TouchableOpacity>

                                <Text stlye={styles.checkboxText}>Nicht mehr anzeigen</Text>
                            </View>

                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={this._hideMessages.bind(this)}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>)

        }

        var parolenIndex = 0;

        if (this.state.noelements || this.state.parolenElemente === null) {
            return (
                <View style={styles.container}>

                    {popup}

                    <View style={styles.searchWrapper}>
                        <View style={styles.searchImageWrapper}>
                            <Image
                                source={require('../assets/images/buttons/search.jpg')}
                                style={styles.searchImage}
                                resizeMode="contain"
                            />
                        </View>
                        <TextInput
                            placeholder="Suche"
                            style={styles.search}
                            onChangeText={(search) => this._search(search)}
                            defaultValue={this.state.name}
                        />
                    </View>

                </View>)

        } else {


            if (popup !== null) {
                return (<View style={styles.container}>{popup}</View>)
            } else {

                return (
                    <View style={styles.container}>

                        <View style={styles.searchWrapper}>
                            <View style={styles.searchImageWrapper}>
                                <Image
                                    source={require('../assets/images/buttons/search.jpg')}
                                    style={styles.searchImage}
                                    resizeMode="contain"
                                />
                            </View>
                            <TextInput
                                placeholder="Suche"
                                style={styles.search}
                                onChange={(event) => {
                                    if (event.nativeEvent.text.length > 3) {
                                        this._search(event.nativeEvent.text)
                                    } else {

                                        console.log("search text is false")
                                        console.log(require('../assets/json/parolen.json'), "require('../assets/json/parolen.json')")

                                        this.setState({search: false});
                                        this.setState({parolenElemente: JSON.parse(JSON.stringify(require('../assets/json/parolen.json')))});
                                        this.setState({noelements: false});

                                    }
                                }}
                                defaultValue={this.state.name}
                            />
                        </View>

                        <ImageBackground source={require('../assets/images/buttons/BGWhite3x.png')}
                                         style={{width: '100%', height: '100%'}}>
                            <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                                <View style={styles.box}>
                                    {this.state.parolenElemente.parolen.map(data =>

                                        <View key={data.id} backgroundColor={data.color} style={styles.entry}>

                                            <View style={styles.entryTitleIconWrapper}>
                                                <TouchableWithoutFeedback
                                                    onPress={this._toggleModal.bind(this, data.id)}>

                                                    <Image
                                                        source={icons[data.icon]}
                                                        style={styles.entryIcon}
                                                        resizeMode="contain"
                                                    />

                                                </TouchableWithoutFeedback>

                                                <TouchableWithoutFeedback
                                                    onPress={this._toggleModal.bind(this, data.id)}>
                                                    <Text style={styles.entryTitle}>{data.name.toUpperCase()}</Text>
                                                </TouchableWithoutFeedback>
                                            </View>

                                            {
                                                this.state.toggle === data.id || this.state.search === true ?
                                                    <TouchableWithoutFeedback
                                                        onPress={this._toggleModal.bind(this, data.id)}>
                                                        <Image
                                                            source={require('../assets/images/buttons/arrowdown_brown.png')}
                                                            style={styles.arrow}
                                                            resizeMode="contain"
                                                        />
                                                    </TouchableWithoutFeedback>

                                                    :
                                                    <TouchableWithoutFeedback
                                                        onPress={this._toggleModal.bind(this, data.id)}>
                                                        <Image
                                                            source={require('../assets/images/buttons/arrowup_brown.png')}
                                                            style={styles.arrow}
                                                            resizeMode="contain"
                                                        />
                                                    </TouchableWithoutFeedback>

                                            }

                                            {
                                                this.state.toggle === data.id || this.state.search === true ?
                                                    <View>

                                                        <Text style={styles.introduction}>{data.introduction}</Text>

                                                        {

                                                            this.state.toggle === data.id || this.state.search === true ?

                                                                data.parolen.map(parole =>
                                                                    <View key={'p' + parolenIndex++}
                                                                          style={styles.parole}>

                                                                        <View style={{flex: 1}}>
                                                                            {
                                                                                this.state.toggleParole === parolenIndex ?

                                                                                    <View>
                                                                                        <TouchableWithoutFeedback
                                                                                            onPress={this._toggleParoleModal.bind(this, parolenIndex)}>
                                                                                            <View>
                                                                                                <Text
                                                                                                    style={styles.paroleTitle}>{parole.title}</Text>

                                                                                                <Text
                                                                                                    style={styles.paroleText}>{parole.text}</Text>
                                                                                            </View>
                                                                                        </TouchableWithoutFeedback>

                                                                                        <a href={parole.url}
                                                                                           target='_blank'>
                                                                                            <Image
                                                                                                style={styles.openButton}
                                                                                                source={require('../assets/images/buttons/browser_blau.png')}
                                                                                                resizeMode="contain"
                                                                                            />
                                                                                        </a>
                                                                                    </View>
                                                                                    :
                                                                                    <TouchableWithoutFeedback
                                                                                        onPress={this._toggleParoleModal.bind(this, parolenIndex)}>
                                                                                        <Text
                                                                                            style={styles.paroleTitle}>{parole.title}</Text>
                                                                                    </TouchableWithoutFeedback>

                                                                            }

                                                                        </View>


                                                                    </View>
                                                                )

                                                                : null

                                                        }
                                                    </View>

                                                    :
                                                    null

                                            }


                                        </View>
                                    )}
                                </View>
                            </ScrollView>
                        </ImageBackground>

                    </View>
                );

            }
        }
    }

}

const
    styles = StyleSheet.create({
        container: {
            flex: 1, width: 380
        },

        contentContainer: {
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: '#f2f2f2',
            width: deviceWidth * .95,
            marginLeft: 10,
            paddingBottom: 50,
            borderRadius: 10,
        },

        box: {
            padding: 10,
            paddingBottom: 50
        },

        entry: {
            width: deviceWidth * .95 - 20,
            minHeight: 30,
            borderRadius: 10,
            backgroundColor: '#ffffff',
            overflow: 'hidden',
            padding: 5,
            marginBottom: 10,
            paddingTop: 15
        },

        entryTitleIconWrapper: {
            flex: 1,
            flexDirection: 'row', flexWrap: 'wrap',
            alignItems: 'flex-start',
        },

        entryIcon: {
            width: '12%',
            height: 40,
            marginLeft: 5,
        },

        entryTitle: {
            width: '72%',
            fontFamily: 'roboto-bold',
            paddingTop: 10,
            paddingLeft: 15
        },

        entryText: {
            fontFamily: 'roboto-regular',
            marginTop: 20,
            width: '100%',
        },

        arrow: {
            marginTop: 13,
            width: '10%',
            marginLeft: 8
        },

        introduction: {
            width: '97%',
            borderRadius: 10,
            overflow: 'hidden',
            padding: 20,
            marginBottom: 3,
            marginTop: 5,
            marginLeft: 4,
            marginRight: 4,
            backgroundColor: '#f2f2f2',
            minHeight: 30,
        },

        parole: {
            width: '97%',
            borderRadius: 10,
            overflow: 'hidden',
            padding: 5,
            paddingTop: 0,
            paddingLeft: 10,
            marginBottom: 3,
            marginTop: 3,
            marginLeft: 4,
            marginRight: 4,
            backgroundColor: '#f2f2f2',
            minHeight: 30,

        },

        paroleTitle: {
            width: '88%',
            fontFamily: 'roboto-bold',
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10
        },

        paroleArrow: {
            width: '7%',
            marginTop: 15,
        },

        paroleText: {
            fontFamily: 'roboto-regular',
            marginTop: 10,
            marginBottom: 10,
            width: '100%',
            paddingLeft: 10,
            paddingRight: 10
        },


        searchWrapper: {
            width: deviceWidth * .95 - 20,
            backgroundColor: '#e1e1e1',
            borderRadius: 20,
            overflow: 'hidden',

            padding: 20,

            marginLeft: 20,
            marginTop: 15,
            marginBottom: 5,

            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
        },

        searchImageWrapper: {
            width: deviceWidth * .1,
            height: 60,
            margin: 0,
        },

        searchImage: {
            width: '100%',
            height: 25,
            marginTop: -13
        },

        search: {
            width: deviceWidth * .8,
            height: 60,
            backgroundColor: '#e1e1e1',
            color: '#2e2e2e',
            fontSize: 16,
            margin: 0,
            marginTop: -30,
            padding: 10
        },


        locationContainer: {
            flex: 1,
            flexDirection: 'row', flexWrap: 'wrap',
            alignItems: 'flex-start',
            padding: 20,
        },


        weiterButtonWrapper: {
            width: 140,
            height: 70,
            marginBottom: 30,
            marginTop: 130,
            marginLeft: deviceWidth * 0.25,
        },

        weiterButton: {
            width: '90%',
            height: 60,
            marginTop: 20,
        },


        pauseOverlay: {
            width: deviceWidth,
            height: deviceHeight,
            left: 0,
            top: 0,
            backgroundColor: '#000000',
            position: 'absolute',
            zIndex: 8,
            opacity: 0.5,
            overflow: 'hidden',

        },

        line: {
            width: '95%',
            marginLeft: '3%',
            height: 3,
            backgroundColor: '#f2f2f2',
            marginTop: 20,
            marginBottom: 10
        },

        checkboxWrapper: {
            flex: 1,
            flexDirection: 'row', flexWrap: 'wrap',
            alignItems: 'flex-start',
            padding: 10,
            marginBottom: 20,
        },

        checkbox: {
            width: 40,
            height: 40
        },

        checkboxText: {
            width: deviceWidth * .8,
            height: 50,
            paddingLeft: 20,
        },

        checkboxImage: {
            width: deviceWidth * .1,
            height: 50,
            margin: 0,
            marginTop: -10,
            marginRight: 20,
            marginBottom: 20
        },

        openButtonWrapper: {
            width: 140,
            height: 70,
            marginBottom: 30,
            marginTop: 10,
            marginLeft: deviceWidth * 0.22,
        },

        openButton: {
            width: '90%',
            height: 60,
            marginTop: 20,
        },


    });
