const locations = {
    'Location_Intro_Icon_01_Spielplatz' : require("./locations/Location_Intro_Icon_01_Spielplatz.png"),
    'Location_Intro_Icon_02_Supermarkt' : require("./locations/Location_Intro_Icon_02_Supermarkt.png"),
    'Location_Intro_Icon_03_Disco' : require("./locations/Location_Intro_Icon_03_Disco.png"),
    'Location_Intro_Icon_04_Rummel' : require("./locations/Location_Intro_Icon_04_Rummel.png"),
    'Location_Intro_Icon_05_Bar' : require("./locations/Location_Intro_Icon_05_Bar.png"),
    'Location_Intro_Icon_07_Kantine' : require("./locations/Location_Intro_Icon_07_Kantine.png"),
    'Location_Intro_Icon_08_Bolzplatz' : require("./locations/Location_Intro_Icon_08_Bolzplatz.png"),
    'Location_Intro_Icon_09_Familienessen' : require("./locations/Location_Intro_Icon_09_Familienessen.png"),
    'Location_Intro_Map_Icon_06_Offis' : require("./locations/Location_Intro_Map_Icon_06_Offis.png"),
};

export default locations;