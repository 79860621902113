import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    TextInput,
    Picker,
    Button, TouchableWithoutFeedback,
    AsyncStorage
} from 'react-native';
import {WebBrowser} from 'expo';
import gameimages from "../assets/images/images_game";

const deviceWidth = 380;
const deviceHeight = 768

export default class ReviewScreen extends React.Component {

    static navigationOptions = {
        header: null
    };

    constructor(props) {
        super(props);
        this.state = {
            mapElements: require('../assets/json/map.json'),
            parolenElemente: require('../assets/json/parolen.json'),
            messages: require('../assets/json/messages.json'),
            popup: null,
            message: null,
            avatar: null,
            isModeFreePlay: false,
            selectedCategory: null,
            selectedCategoryId: null,
            selectedCategoryName: null,
            selectedCategoryColor: null,
            parolenStarsWin: {},
            parolenStarsWinLocation: null,
            settings: {
                musikChecked: true,
                soundChecked: true,
                erklarungenChecked: true
            }
        };

        this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/12_ReviewWin_FX.aac');

    }

    componentDidMount() {

        const {navigation} = this.props;
        const pointsForRound = navigation.getParam('pointsForRound');
        const selectedLocation = navigation.getParam('selectedLocation');
        const selectedCategory = navigation.getParam('selectedCategory');
        const selectedCategoryId = navigation.getParam('selectedCategoryId');
        const selectedCategoryName = navigation.getParam('selectedCategoryName');
        const selectedCategoryColor = navigation.getParam('selectedCategoryColor');

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {
                const settings = JSON.parse(result);

                this.setState({
                    settings: {
                        musikChecked: settings.musikChecked,
                        soundChecked: settings.soundChecked,
                        erklarungenChecked: settings.erklarungenChecked
                    }
                });

                //if (settings.erklarungenChecked) {

                AsyncStorage.getItem('popup', (err, result) => {

                    if (result === null) {
                        this._showMessages()
                    } else {
                        this.setState({popup: JSON.parse(result)}, () => this._showMessages())
                    }


                });

                //}
            } else {
                AsyncStorage.getItem('popup', (err, result) => {

                    if (result === null) {
                        this._showMessages()
                    } else {
                        this.setState({popup: JSON.parse(result)}, () => this._showMessages())
                    }


                });

            }

        });

        AsyncStorage.getItem('avatar', (err, result) => {
            if (result !== null) {
                this.setState({avatar: JSON.parse(result)});
            } else {
                this.setState({
                    avatar: {
                        avatarKopf: 'Avatar_Head_01_color',
                        avatarHaare: 'Avatar_Hair_01_FG_color',
                        avatarHaareBG: 'Avatar_Hair_01_BG_color',
                        avatarGesicht: 'Avatar_Face_01',
                        avatarTorso: 'Avatar_Clothes_01_color',
                        avatarAccessoire: 'Avatar_Acc_01',
                        avatarKopfColor: 1,
                        avatarHaareColor: 1,
                        avatarTorsoColor: 1
                    }
                })
            }
        });

        AsyncStorage.getItem('isModeFreePlay', (err, result) => {
            if (result !== null) {
                this.setState({isModeFreePlay: true});
            }

            AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                if (result !== null) {
                    this.setState({parolenStarsWinLocation: JSON.parse(result)}, () => {
                        AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                            var parolenStarsFreeplay = {};

                            if (result !== null) {
                                parolenStarsFreeplay = JSON.parse(result);
                            }

                            if (selectedCategoryId !== null) {
                                this.setState({
                                    selectedCategory: selectedCategory,
                                    selectedCategoryId: selectedCategoryId,
                                    selectedCategoryName: selectedCategoryName,
                                    selectedCategoryColor: selectedCategoryColor
                                })
                            }


                            // also in render
                            if (pointsForRound > 1 ||
                                this.state.isModeFreePlay === false && selectedLocation !== 9 ||
                                selectedLocation === 9 && pointsForRound > 5) {

                                parolenStarsFreeplay['location_' + selectedLocation] = true;

                                AsyncStorage.setItem('parolen_stars_freeplay', JSON.stringify(parolenStarsFreeplay));

                            }

                            if (!this.state.isModeFreePlay && selectedLocation === 9 && pointsForRound > 5) {
                                this.setState({isModeFreePlay: true});
                            }

                            if (this.state.isModeFreePlay && selectedLocation === 9 && pointsForRound > 5) {
                                AsyncStorage.setItem('parolen_stars_win_last_location', JSON.stringify(true));
                            }


                        });
                    })
                } else {
                    AsyncStorage.getItem('parolen_stars_freeplay', (err, result) => {

                        var parolenStarsFreeplay = {};

                        if (result !== null) {
                            parolenStarsFreeplay = JSON.parse(result);
                        }

                        if (selectedCategoryId !== null) {
                            this.setState({
                                selectedCategory: selectedCategory,
                                selectedCategoryId: selectedCategoryId,
                                selectedCategoryName: selectedCategoryName,
                                selectedCategoryColor: selectedCategoryColor
                            })
                        }

                        // also in render
                        if (pointsForRound > 1 ||
                            this.state.isModeFreePlay === false && selectedLocation !== 9 ||
                            selectedLocation === 9 && pointsForRound > 5) {

                            console.log("set freeplay?", parolenStarsFreeplay['location_' + selectedLocation])
                            parolenStarsFreeplay['location_' + selectedLocation] = true;
                            console.log("set freeplay?", selectedLocation)

                            this.setState({isModeFreePlay: true});

                            AsyncStorage.setItem('parolen_stars_freeplay', JSON.stringify(parolenStarsFreeplay));

                        }

                    });
                }


            });


        });

    }

    playSound = async (name) => {

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {
                const settings = JSON.parse(result);
                if (settings.soundChecked) {
                    switch (name) {

                        case '12_ReviewWin_FX':
                            this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/12_ReviewWin_FX.aac');
                            break;
                        case '13_ReviewFail_FX':
                            this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/13_ReviewFail_FX.aac');
                            break;
                        case '14_SpecialStar_FX':
                            this.audioPlayer = new Audio('https://konterbunt.net/assets/sounds/sfx/14_SpecialStar_FX.aac');
                            break;

                        default:
                            break
                    }

                    this.audioPlayer.currentTime = 0;
                    this.audioPlayer.play();

                }
            }
        });


    };

    stopSound = async () => {
        this.audioPlayer.pause();
    };

    _showMessages() {

        var popup = this.state.popup;
        const {navigation} = this.props;
        const selectedLocation = navigation.getParam('selectedLocation');

        if (popup != null) {

            if (popup.review === undefined) {

                this.setState({
                    message: {
                        id: 'review',
                        text: this.state.messages.review,
                        position: 'middle'
                    }
                });
            }

            if (popup.review_2 === undefined && selectedLocation > 1) {
                this.setState({
                    message: {
                        id: 'review_2',
                        text: this.state.messages.review_2,
                        position: 'middle'
                    }
                });
            }

        }


    }

    _hideMessages() {
        this.setState({message: null})
    }

    _nextMessages(id) {

        this._savePopUp(id);
        this._hideMessages();

    }

    _savePopUp(id) {

        var popup = this.state.popup;
        if (popup === null) {
            popup = {};
        }
        popup[id] = true;

        AsyncStorage.setItem('popup', JSON.stringify(popup));

    }

    _hasWonAllCategories(selectedLocation) {

        var isSelectedLocationWin = null;
        var mapElements = this.state.mapElements;

        if (this.state.parolenStarsWinLocation !== null && this.state.parolenStarsWinLocation !== undefined) {
            if (this.state.parolenStarsWinLocation[selectedLocation] !== undefined) {

                isSelectedLocationWin = true;

                var mapCategories = null;

                var m;
                for (m = 0; m < mapElements.map.length; m++) {

                    const mapElement = mapElements.map[m];

                    if (mapElement.id === selectedLocation) {
                        mapCategories = mapElement.categories
                    }
                }

                if (mapCategories !== null) {
                    var c;
                    for (c = 0; c < mapCategories.length; c++) {

                        const mapCategoriesElementId = mapCategories[c];

                        if (this.state.parolenStarsWinLocation[selectedLocation][mapCategoriesElementId] === undefined) {
                            isSelectedLocationWin = null;
                        }
                    }
                }
            }
        }


        return isSelectedLocationWin;

    }

    _againButtoTap() {
        this.stopSound()
        this.props.navigation.pop(2)
    }

    _mapButtoTap() {
        this.stopSound()
        this.props.navigation.pop(3)
    }

    render() {

        const {navigation} = this.props;
        const selectedLocation = navigation.getParam('selectedLocation');
        const parolenForRound = navigation.getParam('parolenForRound');
        const eskalationenForRound = navigation.getParam('eskalationenForRound');
        const selectedAnswerForRound = navigation.getParam('selectedAnswerForRound');
        const selectedEskalationenForRound = navigation.getParam('selectedEskalationenForRound');
        const pointsForRound = navigation.getParam('pointsForRound');
        const refreshedParolenForRound = navigation.getParam('refreshedParolenForRound');
        const selectedCategory = navigation.getParam('selectedCategory');
        const selectedCategoryId = navigation.getParam('selectedCategoryId');
        const selectedCategoryName = navigation.getParam('selectedCategoryName');
        const selectedCategoryColor = navigation.getParam('selectedCategoryColor');

        const mapElements = this.state.mapElements;

        var selectedElement = mapElements.map[selectedLocation - 1];
        var selectedElementIndex = 0;

        var textGeschafft = '';
        var star = require('../assets/images/review/Star_Below_Empty.png');
        var won = false;

        // also in component did mount
        if (pointsForRound > 1 ||
            this.state.isModeFreePlay === false && selectedLocation !== 9 ||
            selectedLocation === 9 && pointsForRound > 5) {
            textGeschafft = 'GESCHAFFT!';
            star = require('../assets/images/review/Star_Below_Gold.png');
            won = true;
        } else {
            textGeschafft = 'NICHT GESCHAFFT!';
            star = require('../assets/images/review/Star_Below_Empty.png');
            won = false;
        }

        //if(pointsForRound > 1 || !isModeFreePlay && selectedLocation != 9 || selectedLocation == 9 && pointsForRound > 5) {

        var popup = null;

        if (this.state.message !== null) {

            var popupPositionStyle;
            var popupPositionTextStyle = {
                fontSize: 13,
                lineHeight: 15
            };

            var messageText = this.state.message.text;


            if (this.state.avatar !== null) {
                if (this.state.avatar.name !== undefined) {
                    messageText = messageText.replace('[Spielername]', this.state.avatar.name)
                } else {
                    messageText = messageText.replace('[Spielername]', '')
                }

            } else {
                messageText = messageText.replace('[Spielername]', '')
            }

            if (this.state.message.position === 'top') {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.085,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.85,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 9
                };
            } else if (this.state.message.position === 'middle') {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.085,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.85,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 9
                };

                popupPositionTextStyle = {
                    fontSize: 15,
                    lineHeight: 17
                };
            } else {
                popupPositionStyle = {
                    width: deviceWidth * 0.76,
                    marginLeft: deviceWidth * 0.12,
                    marginTop: deviceHeight * 0.065,
                    paddingTop: 20,
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    borderRadius: 15,
                    height: deviceHeight * 0.60,
                    position: 'absolute',
                    top: 95,
                    left: 0,
                    zIndex: 9
                };
            }

            popup = <View>

                <View style={styles.pauseOverlay}><Text>.</Text></View>

                <View style={popupPositionStyle}>

                    <View style={styles.popupContainer}>
                        <Text style={popupPositionTextStyle}>{messageText}</Text>
                    </View>

                    <TouchableOpacity style={styles.weiterButtonWrapper}
                                      onPress={this._nextMessages.bind(this, this.state.message.id)}>
                        <Image
                            style={styles.weiterButton}
                            source={require('../assets/images/buttons/weiter.png')}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>

                </View>


            </View>
        }

        var selectedCategoryText = <Text style={{
            fontSize: 14,
            fontWeight: 'bold',
            color: this.state.selectedCategoryColor,
            height: 20,
            opacity: 0,
        }}>.</Text>;

        var categoryId = this.state.selectedCategory;

        //console.log("isModeFreePlay?", this.state.isModeFreePlay)

        const lastReview = {
            selectedLocation: selectedLocation,
            parolenForRound: parolenForRound,
            selectedAnswerForRound: selectedAnswerForRound,
            eskalationenForRound: eskalationenForRound,
            selectedEskalationenForRound: selectedEskalationenForRound,
            refreshedParolenForRound: refreshedParolenForRound,
            selectedCategory: selectedCategory,
            selectedCategoryId: selectedCategoryId,
            selectedCategoryName: selectedCategoryName,
            selectedCategoryColor: selectedCategoryColor,
            won: won
        };

        AsyncStorage.setItem('lastreview', JSON.stringify(lastReview));

        if (this.state.isModeFreePlay) {

            selectedCategoryText = <Text style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: this.state.selectedCategoryColor,
                height: 20,
            }}>{this.state.selectedCategoryName}</Text>

            if (won) {

                if (selectedCategory === null && selectedLocation === 9 &&
                    this._hasWonAllCategories(1) &&
                    this._hasWonAllCategories(2) &&
                    this._hasWonAllCategories(3) &&
                    this._hasWonAllCategories(4) &&
                    this._hasWonAllCategories(5) &&
                    this._hasWonAllCategories(6) &&
                    this._hasWonAllCategories(7) &&
                    this._hasWonAllCategories(8)

                ) {
                    this.playSound('14_SpecialStar_FX');

                    AsyncStorage.setItem('parolen_stars_final', 'true');

                } else {

                    this.playSound('12_ReviewWin_FX');

                    AsyncStorage.getItem('parolen_stars_win_location', (err, result) => {

                        // console.log(result)

                        var parolenStarsWinLocation = {};

                        if (result !== null) {
                            parolenStarsWinLocation = JSON.parse(result)
                        }

                        if (parolenStarsWinLocation[selectedLocation] !== undefined) {
                            parolenStarsWinLocation[selectedLocation][categoryId] = true;
                        } else {
                            parolenStarsWinLocation[selectedLocation] = {[categoryId]: true};
                        }

                        //console.log("this saving:", parolenStarsWinLocation)

                        AsyncStorage.setItem('parolen_stars_win_location', JSON.stringify(parolenStarsWinLocation), (err, result) => {

                        });


                    });

                }


            } else {

                this.playSound('13_ReviewFail_FX');

            }
        }


        if (popup !== null) {
            return (
                <View style={styles.container}>
                    {popup}
                </View>
            );
        } else {
            return (
                <View style={styles.container}>
                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{
                                         width: '100%',
                                         height: '100%'
                                     }}>
                        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                            <View style={styles.wrapper}>
                                <View style={{
                                    height: 60,
                                    padding: 10,
                                    paddingBottom: 20,
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 10,
                                    marginTop: 5,
                                    overflow: 'hidden'
                                }}>
                                    <Text style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: 10
                                    }}>{textGeschafft}</Text>
                                </View>

                                <View style={styles.box}>
                                    <View style={styles.locationContainer}>
                                        <Image
                                            source={star}
                                            style={styles.star}
                                            resizeMode="contain"
                                        />
                                        <View style={styles.locationWrapper}>
                                            <Text style={styles.location}>{selectedElement.title}</Text>
                                            <View
                                                style={{
                                                    borderBottomColor: '#949494',
                                                    borderBottomWidth: 2,
                                                    marginBottom: 15,
                                                    marginTop: 15
                                                }}
                                            />

                                            {selectedCategoryText}


                                        </View>
                                    </View>

                                    <View style={styles.nochmalWrapper}>
                                        <TouchableOpacity style={styles.nochmalButtonWrapper}
                                                          onPress={() => this._againButtoTap()}>
                                            <Image
                                                style={styles.nochmalButton}
                                                source={require('../assets/images/review/Game_Button_Nochmal.png')}
                                                resizeMode="contain"
                                            />
                                        </TouchableOpacity>

                                        <TouchableOpacity style={styles.nochmalButtonWrapper}
                                                          onPress={() => this._mapButtoTap()}>
                                            <Image
                                                style={styles.nochmalButton}
                                                source={require('../assets/images/review/Game_Button_Karte.png')}
                                                resizeMode="contain"
                                            />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.wrapper}>
                                <View style={{
                                    height: 60,
                                    padding: 10,
                                    paddingBottom: 20,
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 10,
                                    marginTop: 5,
                                    overflow: 'hidden'
                                }}>
                                    <Text style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: 10
                                    }}>PAROLEN & ANTWORTEN</Text>
                                </View>
                                <View style={styles.box}>

                                    {parolenForRound.map(data => {

                                            const selectedAnswer = selectedAnswerForRound[selectedElementIndex];
                                            const selectedEskalation = selectedEskalationenForRound[selectedElementIndex];
                                            const parolenElemente = this.state.parolenElemente;

                                            var refresh = null;

                                            var titleLabelText = 'Deine Antwort';

                                            var title = data.title;

                                            var button = <TouchableOpacity
                                                onPress={() => this.props.navigation.push('DetailParolenVerzeichnisScreen', {data: data})}>
                                                <Image
                                                    source={require('../assets/images/review/parolenverzeichnis_button.png')}
                                                    style={styles.button}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>;


                                            if (selectedEskalation !== null) {

                                                //console.log("selectedEskalation", selectedEskalation)

                                                title = selectedEskalationenForRound[selectedElementIndex].question;
                                                refresh = null;
                                                titleLabelText = 'Deine Strategie';

                                                button = <TouchableOpacity
                                                    onPress={() => this.props.navigation.push('DetailStrategieguideVerzeichnisScreen', {data: selectedEskalation})}>
                                                    <Image
                                                        source={require('../assets/images/review/strategieguide_button.png')}
                                                        style={styles.button}
                                                        resizeMode="contain"
                                                    />
                                                </TouchableOpacity>;

                                                //button = null;
                                            }


                                            var selectedAnswerText = null;

                                            if (selectedAnswer !== null) {

                                                var smiley = <Image
                                                    source={require('../assets/images/review/icon_smiley_green.png')}
                                                    style={styles.icon}
                                                    resizeMode="contain"
                                                />;

                                                var feedback = null;

                                                selectedAnswerText = selectedAnswer.text;

                                                if (selectedAnswer.points === -3) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_red.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.bad;
                                                }

                                                if (selectedAnswer.points === -2) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_red.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.bad;
                                                }

                                                if (selectedAnswer.points === -1) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_yellow.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.low;
                                                }

                                                if (selectedAnswer.points === 1) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_gruengelb.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.medium;
                                                }

                                                if (selectedAnswer.points === 2) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_green.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.good;

                                                }

                                                if (selectedAnswer.points === 3) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_green.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.good;

                                                }

                                            } else {
                                                smiley = <Image
                                                    source={require('../assets/images/review/icon_smiley_red.png')}
                                                    style={styles.icon}
                                                    resizeMode="contain"
                                                />;

                                                selectedAnswerText = this.state.parolenElemente.feedback.timeout;

                                                feedback = '';
                                            }

                                            if (refreshedParolenForRound.length !== 0) {
                                                if (refreshedParolenForRound[selectedElementIndex]) {

                                                    if (selectedAnswer.points < 0) {
                                                        refresh = <Image
                                                            source={require('../assets/images/game/refreshicon_rot.png')}
                                                            style={styles.icon}
                                                            resizeMode="contain"
                                                        />
                                                    } else {
                                                        refresh = <Image
                                                            source={require('../assets/images/game/refreshicon.png')}
                                                            style={styles.icon}
                                                            resizeMode="contain"
                                                        />
                                                    }
                                                }
                                            }


                                            selectedElementIndex++;

                                            return <View key={data.parolenId} style={{
                                                marginLeft: 10,
                                                marginTop: 20,
                                                paddingBottom: 20,
                                                borderBottomColor: '#CCCCCC',
                                                borderBottomWidth: 2,
                                            }}>

                                                <Text style={styles.paroleTitle}>{title}</Text>

                                                <View style={{
                                                    flex: 1,
                                                    flexDirection: 'row', flexWrap: 'wrap',
                                                    width: 75,
                                                    alignSelf: 'flex-end',
                                                    marginBottom: -30
                                                }}>
                                                    {smiley}

                                                </View>

                                                <Text style={styles.mediumTitle}>{titleLabelText}</Text>
                                                <Text style={styles.text}>{selectedAnswerText} </Text>

                                                {(feedback !== '') ? <View><Text style={styles.mediumTitle}>Feedback</Text>
                                                    <Text style={styles.text}>{feedback} </Text>
                                                    {button}</View> : null}


                                            </View>

                                        }
                                    )}


                                </View>
                            </View>


                        </ScrollView>

                    </ImageBackground>
                </View>
            );
        }

    }

}

const styles = StyleSheet.create({

    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        marginBottom: 2,
        width: deviceWidth * .90,
        marginLeft: 20,
        paddingTop: 40
    },

    wrapper: {
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
        overflow: 'hidden',
        padding: 10,
        paddingTop: 5,
        marginTop: 10
    },

    box: {
        flex: 1,
        padding: 10,
        paddingBottom: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        overflow: 'hidden',
        marginTop: 5
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10
    },

    location: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10
    },

    nochmalWrapper: {
        paddingLeft: 10,
        paddingTop: 60,
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    nochmalButtonWrapper: {
        width: '45%',
        height: 50,
        marginLeft: 5,
        marginRight: 5
    },

    nochmalButton: {
        width: '100%',
        height: 50,
    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    locationWrapper: {
        width: '70%',
        paddingLeft: 20,
        marginBottom: 20
    },

    star: {
        width: 60,
        height: 60,
    },

    paroleTitle: {
        fontSize: 18,
        fontWeight: '500',
        marginBottom: 10,
    },

    mediumTitle: {
        fontSize: 16,
        marginBottom: 5,
        marginTop: 20,

    },

    text: {
        fontSize: 15
    },

    button: {
        width: 150,
        height: 52,
        marginLeft: (deviceWidth * 0.5) - 125,
        marginTop: 20
    },

    icon: {
        width: 30,
        height: 30
    },

    weiterButtonWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 30,
        marginTop: 260,
        marginLeft: 15,
        width: '100%',
        height: 50
    },

    weiterButton: {
        width: '50%',
        marginLeft: '19%',
        height: 70,
    },

    endButton: {
        width: '40%',
        marginLeft: 9,
        height: 70,
    },

    popup: {
        width: deviceWidth * 0.76,
        marginLeft: deviceWidth * 0.12,
        marginTop: deviceHeight * 0.075,
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        borderRadius: 15,
        height: deviceHeight * 0.70,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9
    },

    pauseOverlay: {
        width: deviceWidth,
        height: deviceHeight,
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 8,
        opacity: 0.5,
        overflow: 'hidden',

    },


    popupContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },


});
