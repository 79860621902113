import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback
} from 'react-native';
import {WebBrowser} from 'expo';
import icons from "../assets/images/images_icons"

const deviceWidth = 380;
const deviceHeight = 768

export default class DetailParolenVerzeichnisScreen extends React.Component {

    static navigationOptions = {
        title: "Parolenverzeichnis",
        headerStyle: {
            backgroundColor: '#6eb6df'
        },
        headerTintColor: '#fff',
    };

    constructor(props) {
        super(props);
        this.state = {
            parolenElemente: require('../assets/json/parolen.json'),
            toggle: -1,
            toggleParole: -1
        };

    }

    _toggleModal(id) {

        this.setState({
            toggle: id
        });

        if (this.state.toggle === id) {
            this.setState({
                toggle: -1,
                toggleParole: -1
            })
        }
    };


    _toggleParoleModal(id) {

        this.setState({
            toggleParole: id
        });

        if (this.state.toggleParole === id) {
            this.setState({
                toggleParole: -1
            })
        }
    };


    render() {

        const {navigation} = this.props;
        const data = navigation.getParam('data');

        return (

            <View style={styles.container}>

                <ImageBackground source={require('../assets/images/buttons/BGWhite3x.png')}
                                 style={{width: '100%', height: '100%'}}>
                    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>


                        <View key={data.id} style={styles.entry}>
                            <Text style={styles.paroleTitle}>{data.title}</Text>
                            <Text style={styles.paroleText}>{data.text}</Text>
                        </View>
                    </ScrollView>
                </ImageBackground>

            </View>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#f2f2f2',
        width: deviceWidth * .95,
        marginLeft: 10,
    },

    box: {
        flex: 1,
        padding: 10,
        paddingBottom: 20
    },

    entry: {
        width: deviceWidth * .95 - 20,
        padding: 5,
        margin:10,
        borderRadius:10,
        backgroundColor: '#FFFFFF',


        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    entryIcon: {
        width: '12%',
        height: 40,

    },

    entryTitle: {
        width: '72%',
        fontFamily: 'roboto-bold',
        paddingTop: 10,
        paddingLeft: 15
    },

    entryText: {
        fontFamily: 'roboto-regular',
        marginTop: 20,
        width: '100%',
    },

    arrow: {
        marginTop: 13,
        width: '10%',
        marginLeft: 8
    },

    parole: {
        width: '97%',
        borderRadius: 10,
        overflow: 'hidden',
        padding: 5,
        paddingTop: 0,
        paddingLeft: 10,
        marginBottom: 3,
        marginTop: 3,
        marginLeft: 4,
        marginRight: 4,
        backgroundColor: '#f2f2f2',
        minHeight: 30,

        flexDirection: 'row',
        flexWrap: 'wrap',

    },

    paroleTitle: {
        width: '88%',
        fontFamily: 'roboto-bold',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10
    },

    paroleArrow: {
        width: '7%',
        marginTop: 20,
    },

    paroleText: {
        fontFamily: 'roboto-regular',
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10
    },

});
