import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    TextInput,
    Picker,
    Button, TouchableWithoutFeedback,
    AsyncStorage
} from 'react-native';
import {WebBrowser} from 'expo';
import gameimages from "../assets/images/images_game";

const deviceWidth = 380;
const deviceHeight = 768

export default class LasteviewScreen extends React.Component {

    static navigationOptions = {
        header: null
    };

    constructor(props) {
        super(props);
        this.state = {
            mapElements: require('../assets/json/map.json'),
            parolenElemente: require('../assets/json/parolen.json'),
            messages: require('../assets/json/messages.json'),
            popup: null,
            message: null,
            avatar: null,
            lastreview: null,
            selectedCategory: null,
            selectedCategoryId: null,
            selectedCategoryName: null,
            selectedCategoryColor: null
        };

    }

    componentDidMount() {

        AsyncStorage.getItem('avatar', (err, result) => {
            if (result !== null) {
                this.setState({avatar: JSON.parse(result)});
            } else {
                this.setState({
                    avatar: {
                        avatarKopf: 'Avatar_Head_01_color',
                        avatarHaare: 'Avatar_Hair_01_FG_color',
                        avatarHaareBG: 'Avatar_Hair_01_BG_color',
                        avatarGesicht: 'Avatar_Face_01',
                        avatarTorso: 'Avatar_Clothes_01_color',
                        avatarAccessoire: 'Avatar_Acc_01',
                        avatarKopfColor: 1,
                        avatarHaareColor: 1,
                        avatarTorsoColor: 1
                    }
                })
            }
        });

        AsyncStorage.getItem('popup', (err, result) => {
            //console.log(result);
            if (result === null) {
                this._showMessages()
            } else {
                this.setState({popup: JSON.parse(result)})

                this._showMessages();

            }
        });

        AsyncStorage.getItem('lastreview', (err, result) => {
            if (result !== null) {
                this.setState({lastreview: JSON.parse(result)})

            }
        });


    }

    _showMessages() {

        var popup = this.state.popup;

        if (popup != null) {
            if (popup.review === undefined) {
                this.setState({
                    message: {
                        id: 'review',
                        text: this.state.messages.review,
                        position: 'middle'
                    }
                });
            }

        } else {
            this.setState({
                message: {
                    id: 'review',
                    text: this.state.messages.review,
                    position: 'middle'
                }
            });
        }

    }

    _hideMessages() {
        this.setState({message: null})
    }

    _nextMessages(id) {

        this._savePopUp(id);
        this._hideMessages();
    }

    _savePopUp(id) {

        var popup = this.state.popup;
        if (popup === null) {
            popup = {};
        }
        popup[id] = true;

        AsyncStorage.setItem('popup', JSON.stringify(popup));

    }


    render() {

        if (this.state.lastreview !== null) {
            const selectedLocation = this.state.lastreview.selectedLocation;
            const parolenForRound = this.state.lastreview.parolenForRound;
            const selectedAnswerForRound = this.state.lastreview.selectedAnswerForRound;
            const selectedEskalationenForRound = this.state.lastreview.selectedEskalationenForRound;
            const eskalationenForRound = this.state.lastreview.eskalationenForRound;
            const refreshedParolenForRound = this.state.lastreview.refreshedParolenForRound;
            const won = this.state.lastreview.won;
            const selectedCategory = this.state.lastreview.selectedCategory;
            const selectedCategoryId = this.state.lastreview.selectedCategoryId;
            const selectedCategoryName = this.state.lastreview.selectedCategoryName;
            const selectedCategoryColor = this.state.lastreview.selectedCategoryColor;

            const mapElements = this.state.mapElements;

            var selectedElement = mapElements.map[selectedLocation - 1];
            var selectedElementIndex = 0;

            var textGeschafft = 'NICHT GESCHAFFT!';
            var star = require('../assets/images/review/Star_Below_Empty.png');

            if (won) {
                textGeschafft = 'GESCHAFFT!';
                star = require('../assets/images/review/Star_Below_Gold.png');
            }

            var popup = null;

            if (this.state.message !== null) {

                var popupPositionStyle;
                var popupPositionTextStyle = {
                    fontSize: 14,
                    lineHeight: 17
                };

                var messageText = this.state.message.text;

                if (this.state.avatar !== null) {
                    messageText = messageText.replace('[Spielername]', this.state.avatar.name)
                } else {
                    messageText = messageText.replace('[Spielername]', '')
                }

                if (this.state.message.position === 'top') {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.065,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.60,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 9
                    };
                } else if (this.state.message.position === 'middle') {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.065,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.60,
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        zIndex: 9
                    };
                } else {
                    popupPositionStyle = {
                        width: deviceWidth * 0.76,
                        marginLeft: deviceWidth * 0.12,
                        marginTop: deviceHeight * 0.065,
                        paddingTop: 20,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        borderRadius: 15,
                        height: deviceHeight * 0.60,
                        position: 'absolute',
                        top: 95,
                        left: 0,
                        zIndex: 9
                    };
                }

                popup = <View>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>

                        <View style={styles.popupContainer}>
                            <Text style={popupPositionTextStyle}>{messageText}</Text>
                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={this._nextMessages.bind(this, this.state.message.id)}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>

                    </View>


                </View>
            }

            var selectedCategoryText = <Text style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: selectedCategoryColor,
                height: 20,
                opacity: 0,
            }}>.</Text>;

            if (selectedCategoryId !== null) {
                selectedCategoryText = <Text style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: selectedCategoryColor,
                    height: 20,
                }}>{selectedCategoryName}</Text>
            }

            return (

                <View style={styles.container}>

                    {popup}

                    <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                                     style={{
                                         width: '100%',
                                         height: '100%'
                                     }}>
                        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                            <View style={styles.wrapper}>

                                <View style={{
                                    height: 60,
                                    padding: 10,
                                    paddingBottom: 20,
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 10,
                                    marginTop: 5,
                                    overflow: 'hidden'
                                }}>
                                    <Text style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: 10
                                    }}>{textGeschafft}</Text>
                                </View>

                                <View style={styles.box}>


                                    <View style={styles.locationContainer}>
                                        <Image
                                            source={star}
                                            style={styles.star}
                                            resizeMode="contain"
                                        />
                                        <View style={styles.locationWrapper}>
                                            <Text style={styles.location}>{selectedElement.title}</Text>
                                            <View
                                                style={{
                                                    borderBottomColor: '#949494',
                                                    borderBottomWidth: 2,
                                                    marginBottom: 15,
                                                    marginTop: 15
                                                }}
                                            />

                                            {selectedCategoryText}


                                        </View>
                                    </View>

                                    <View style={styles.nochmalWrapper}>
                                        <TouchableOpacity style={styles.nochmalButtonWrapper}
                                                          onPress={() => this.props.navigation.pop()}>
                                            <Image
                                                style={styles.nochmalButton}
                                                source={require('../assets/images/review/Game_Button_Karte.png')}
                                                resizeMode="contain"
                                            />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.wrapper}>
                                <View style={{
                                    height: 60,
                                    padding: 10,
                                    paddingBottom: 20,
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 10,
                                    marginTop: 5,
                                    overflow: 'hidden'
                                }}>
                                    <Text style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: 10
                                    }}>PAROLEN & ANTWORTEN</Text>
                                </View>
                                <View style={styles.box}>

                                    {parolenForRound.map(data => {

                                            const selectedAnswer = selectedAnswerForRound[selectedElementIndex];
                                            const selectedEskalation = selectedEskalationenForRound[selectedElementIndex];
                                            const parolenElemente = this.state.parolenElemente;

                                            var refresh = null;

                                            var titleLabelText = 'Deine Antwort';

                                            var title = data.title;

                                            var button = <TouchableOpacity
                                                onPress={() => this.props.navigation.push('DetailParolenVerzeichnisScreen', {data: data})}>
                                                <Image
                                                    source={require('../assets/images/review/parolenverzeichnis_button.png')}
                                                    style={styles.button}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>


                                            if (selectedEskalation !== null) {

                                                //console.log("selectedEskalationenForRound", selectedEskalationenForRound)

                                                title = selectedEskalationenForRound[selectedElementIndex].question;
                                                refresh = null;
                                                titleLabelText = 'Deine Strategie';

                                                /*
                                                button = <TouchableOpacity
                                                    onPress={() => this.props.navigation.push('DetailParolenVerzeichnisScreen', {data: data})}>
                                                    <Image
                                                        source={require('../assets/images/review/strategieguide_button.png')}
                                                        style={styles.button}
                                                        resizeMode="contain"
                                                    />
                                                </TouchableOpacity>
                                                */


                                                button = null;
                                            }

                                            var selectedAnswerText = null;

                                            if (selectedAnswer !== null) {

                                                var smiley = <Image
                                                    source={require('../assets/images/review/icon_smiley_green.png')}
                                                    style={styles.icon}
                                                    resizeMode="contain"
                                                />;

                                                var feedback = null;

                                                selectedAnswerText = selectedAnswer.text;

                                                if (selectedAnswer.points === -3) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_red.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.bad;
                                                }

                                                if (selectedAnswer.points === -2) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_red.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.bad;
                                                }

                                                if (selectedAnswer.points === -1) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_yellow.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.low;
                                                }

                                                if (selectedAnswer.points === 1) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_gruengelb.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.medium;
                                                }

                                                if (selectedAnswer.points === 2) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_green.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.good;

                                                }

                                                if (selectedAnswer.points === 3) {
                                                    smiley = <Image
                                                        source={require('../assets/images/review/icon_smiley_green.png')}
                                                        style={styles.icon}
                                                        resizeMode="contain"
                                                    />;

                                                    feedback = parolenElemente.feedback.good;

                                                }

                                            } else {
                                                smiley = <Image
                                                    source={require('../assets/images/review/icon_smiley_red.png')}
                                                    style={styles.icon}
                                                    resizeMode="contain"
                                                />;

                                                selectedAnswerText = 'Keine Antwort gewählt.'

                                                feedback = parolenElemente.feedback.bad;
                                            }

                                            selectedElementIndex++;

                                            if (refreshedParolenForRound.length !== 0) {
                                                if (refreshedParolenForRound[selectedElementIndex]) {

                                                    if (selectedAnswer.points < 0) {
                                                        refresh = <Image
                                                            source={require('../assets/images/game/refreshicon_rot.png')}
                                                            style={styles.icon}
                                                            resizeMode="contain"
                                                        />
                                                    } else {
                                                        refresh = <Image
                                                            source={require('../assets/images/game/refreshicon.png')}
                                                            style={styles.icon}
                                                            resizeMode="contain"
                                                        />
                                                    }
                                                }
                                            }

                                            return <View key={data.parolenId} style={{
                                                marginLeft: 10, marginTop: 20,
                                                paddingBottom: 20,
                                                borderBottomColor: '#CCCCCC',
                                                borderBottomWidth: 2,
                                            }}>

                                                <Text style={styles.paroleTitle}>{title}</Text>

                                                <View style={{
                                                    flex: 1,
                                                    flexDirection: 'row', flexWrap: 'wrap',
                                                    width: 75,
                                                    alignSelf: 'flex-end',
                                                    marginBottom: -30
                                                }}>
                                                    {smiley}

                                                    {refresh}
                                                </View>

                                                <Text style={styles.mediumTitle}>{titleLabelText}</Text>
                                                <Text style={styles.text}>{selectedAnswerText} </Text>

                                                <Text style={styles.mediumTitle}>Dein Feedback</Text>
                                                <Text style={styles.text}>{feedback} </Text>


                                                {button}

                                            </View>

                                        }
                                    )}


                                </View>
                            </View>


                        </ScrollView>

                    </ImageBackground>

                </View>
            );

        }

        return (<View><Text>...</Text></View>)
    }

}

const styles = StyleSheet.create({

    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        marginBottom: 2,
        width: deviceWidth * .90,
        marginLeft: 20,
        paddingTop: 40
    },

    wrapper: {
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
        overflow: 'hidden',
        padding: 10,
        paddingTop: 5,
        marginTop: 10
    },

    box: {
        flex: 1,
        padding: 10,
        paddingBottom: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        overflow: 'hidden',
        marginTop: 5
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10
    },

    location: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10
    },

    nochmalWrapper: {
        paddingLeft: 10,
        paddingTop: 60,
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    nochmalButtonWrapper: {
        width: '45%',
        height: 50,
        marginLeft: '25%',
        marginRight: 5
    },

    nochmalButton: {
        width: '100%',
        height: 50,
    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
    },

    locationWrapper: {
        width: '70%',
        paddingLeft: 20,
        marginBottom: 20
    },

    star: {
        width: 60,
        height: 60,
    },

    paroleTitle: {
        fontSize: 18,
        marginBottom: 10,
    },

    mediumTitle: {
        fontSize: 16,
        marginBottom: 5,
        marginTop: 20,

    },

    text: {
        fontSize: 15
    },

    button: {
        width: 150,
        height: 52,
        marginLeft: (deviceWidth * 0.5) - 125,
        marginTop: 20
    },

    icon: {
        width: 30,
        height: 30
    },

    weiterButtonWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: 30,
        marginTop: 120,
        marginLeft: 50,
        width: '100%',
        height: 50
    },

    weiterButton: {
        width: '50%',
        marginLeft: '19%',
        height: 70,
    },

    endButton: {
        width: '40%',
        marginLeft: 9,
        height: 70,
    },

    popup: {
        width: deviceWidth * 0.76,
        marginLeft: deviceWidth * 0.12,
        marginTop: deviceHeight * 0.075,
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        borderRadius: 15,
        height: deviceHeight * 0.70,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9
    },

    pauseOverlay: {
        width: deviceWidth,
        height: deviceHeight,
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 8,
        opacity: 0.5,
        overflow: 'hidden',

    },


    popupContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },


});
