import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback
} from 'react-native';
import {WebBrowser} from 'expo';

const deviceWidth = 380;
const deviceHeight = 768

import locations from "../assets/images/images_locations"

export default class LocationScreen extends React.Component {

    static navigationOptions = {
        title: "Karte",
        headerStyle: {
            backgroundColor: '#dbcf1d'
        },
        headerTintColor: '#fff',
    };

    constructor(props) {
        super(props);
        this.state = {
            mapElements: require('../assets/json/map.json'),
            info: false,
            location: null
        };
    }

    render() {

        const {navigation} = this.props;
        const selectedLocation = navigation.getParam('selectedLocation');
        const selectedCategory = navigation.getParam('selectedCategory');
        const selectedCategoryId = navigation.getParam('selectedCategoryId');
        const selectedCategoryName = navigation.getParam('selectedCategoryName');
        const selectedCategoryColor = navigation.getParam('selectedCategoryColor');
        const selectedStarteskalation = navigation.getParam('selectedStarteskalation');
        const selectedAllCategories = navigation.getParam('selectedAllCategories');

        const mapElements = this.state.mapElements;

        var selectedElement = mapElements.map[selectedLocation - 1];
        var selectedElementCategories = selectedElement.categories;

        /*
        for (let i = 0; i < mapElements.map.length; i++) {

            if (mapElements.map[i].id === selectedLocation) {
                selectedElement = mapElements.map[i]
            }

        }*/

        return (
            <ImageBackground source={require('../assets/images/launch/BGBrown3x.png')}
                             style={{width: '100%', height: '100%'}}>
                <View style={styles.container} contentContainerStyle={styles.contentContainer}>

                    <Image
                        source={locations[selectedElement.image]}
                        style={styles.locationIcon}
                        resizeMode="contain"
                    />

                    <Text style={styles.title}>
                        {selectedElement.title}
                    </Text>

                    <Text style={styles.text}>
                        {selectedElement.text}
                    </Text>

                    <TouchableWithoutFeedback onPress={() => this.props.navigation.push('GameScreen', {
                        selectedLocation: selectedLocation,
                        selectedCategory: selectedCategory,
                        selectedCategoryId: selectedCategoryId,
                        selectedCategories: selectedElementCategories,
                        selectedCategoryName: selectedCategoryName,
                        selectedCategoryColor: selectedCategoryColor,
                        selectedStarteskalation: selectedStarteskalation,
                        selectedAllCategories: selectedAllCategories
                    })}>

                        <Image
                            source={require('../assets/images/map/Play-Button.png')}
                            style={styles.playButton}
                            resizeMode="contain"
                        />

                    </TouchableWithoutFeedback>

                </View>
            </ImageBackground>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380
    },

    contentContainer: {
        paddingTop: 10,
        paddingBottom: 10,
    },

    locationIcon: {
        width: 150,
        height: 150,
        marginTop: 30,
        marginLeft: deviceWidth * 0.30,
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10
    },

    text: {
        fontSize: 13,
        marginTop: 30,
        marginLeft: deviceWidth * 0.20,
        marginRight: deviceWidth * 0.20,
    },

    playButton: {
        width: 140,
        height: 70,
        marginBottom: 30,
        marginTop: 30,
        marginLeft: deviceWidth * 0.30,
    },

});
