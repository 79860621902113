import React from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ImageBackground,
    Button, TouchableWithoutFeedback, TextInput, AsyncStorage, Linking
} from 'react-native';
import {WebBrowser} from 'expo';

const deviceWidth = 380;
const deviceHeight = 768

export default class MapScreen extends React.Component {

    static navigationOptions = {
        title: "Strategieguide",
        headerStyle: {
            backgroundColor: '#17baaf'
        },
        headerTintColor: '#fff',
    };

    constructor(props) {
        super(props);
        this.state = {
            strategieGuideElements: require('../assets/json/strategieguide.json'),
            toggle: -1,
            search: '',
            popup: null,
            message: null,
            messages: require('../assets/json/messages.json'),
            popUpChecked: false,
        };

    }

    componentDidMount() {

        AsyncStorage.getItem('parolen_settings', (err, result) => {
            if (result !== null) {

                const settings = JSON.parse(result);

                this.setState({
                    settings: {
                        erklarungenChecked: settings.erklarungenChecked
                    }
                }, () => {
                    if (settings.erklarungenChecked) {

                        AsyncStorage.getItem('popup', (err, result) => {
                            console.log(result);
                            if (result === null) {
                                this._showMessages()
                            } else {
                                this.setState({popup: JSON.parse(result)}, () => {
                                    this._showMessages();
                                })
                            }
                        });

                    }
                });


            } else {

                AsyncStorage.getItem('popup', (err, result) => {
                    console.log(result);
                    if (result === null) {
                        this._showMessages()
                    } else {
                        this.setState({popup: JSON.parse(result)}, () => {
                            this._showMessages();
                        })
                    }
                });

            }
        });

    }

    _toggleModal(id) {

        this.setState({
            toggle: id
        });

        if (this.state.toggle === id) {
            this.setState({
                toggle: -1
            })
        }
    };

    _getUnique(arr, comp) {

        const unique = arr
            .map(e => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    _showMessages() {

        var popup = this.state.popup;

        if (popup != null) {

            console.log(popup.strategieguide, "popup.strategieguide ")

            if (popup.strategieguide === undefined || popup.strategieguide === true) {
                this.setState({
                    message: {
                        id: 'parolenverzeichnis',
                        text: this.state.messages.strategieguide,
                        position: 'top'
                    }
                });
            }

        } else {
            this.setState({
                message: {
                    id: 'parolenverzeichnis',
                    text: this.state.messages.strategieguide,
                    position: 'top'
                }
            });
        }

    }

    _hideMessages() {
        if (this.state.popUpChecked) {

            var popup = this.state.popup;

            if (popup === null) {
                popup = {};
            }

            popup['strategieguide'] = true;

            AsyncStorage.setItem('popup', JSON.stringify(popup));

        }


        this.setState({message: null})
    }

    _check() {
        if (this.state.popUpChecked) {
            this.setState({popUpChecked: false});
        } else {
            this.setState({popUpChecked: true});
        }
    }

    render() {

        const searchText = this.state.search;
        var dataStrategieguide = this.state.strategieGuideElements.strategieguide;
        var newData = [];

        if (this.state.search !== '') {

            var s;
            for (s = 0; s < dataStrategieguide.length; s++) {

                const strategie = dataStrategieguide[s];

                if (strategie.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                    newData.push(strategie);
                }

                if (strategie.text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                    newData.push(strategie);
                }

            }

            dataStrategieguide = this._getUnique(newData, 'id')

            // Parolenverzeichnis

            /*
            var c;
            for (c = 0; c < data.length; c++) {

                const paroleCategory = data[c];

                var p;
                for (p = 0; p < paroleCategory.answers; p++) {

                    const paroleCategory = data[p];


                }


            }

             */


        }

        var popup = null;

        if (this.state.message !== null) {

            var popupPositionStyle = {
                width: deviceWidth * 0.86,
                marginLeft: deviceWidth * 0.080,
                marginTop: deviceHeight * 0.035,
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                borderRadius: 15,
                height: deviceHeight * 0.80,
                position: 'absolute',
                top: 5,
                left: 0,
                zIndex: 9
            };

            var popupPositionTextStyle = {
                fontSize: 15,
                lineHeight: 17
            };

            var checkbox = (<Image
                style={styles.checkbox}
                source={require('../assets/images/buttons/checkbox.png')}
                resizeMode="contain"
            />);

            if (this.state.popUpChecked) {
                checkbox = (<Image
                    style={styles.checkbox}
                    source={require('../assets/images/buttons/checkbox_checked.png')}
                    resizeMode="contain"
                />);
            }

            popup = (
                <View>

                    <View style={styles.pauseOverlay}><Text>.</Text></View>

                    <View style={popupPositionStyle}>
                        <View style={styles.locationContainer}>
                            <Text style={popupPositionTextStyle}>{this.state.message.text}</Text>


                            <View style={styles.line}>
                            </View>

                            <View style={styles.checkboxWrapper}>

                                <TouchableOpacity style={styles.checkboxImage}
                                                  onPress={this._check.bind(this)}>
                                    {checkbox}
                                </TouchableOpacity>

                                <Text stlye={styles.checkboxText}>Nicht mehr anzeigen</Text>
                            </View>

                        </View>

                        <TouchableOpacity style={styles.weiterButtonWrapper}
                                          onPress={this._hideMessages.bind(this)}>
                            <Image
                                style={styles.weiterButton}
                                source={require('../assets/images/buttons/weiter.png')}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>)

        }

        if (popup !== null) {
            return (<View style={styles.container}>{popup}</View>)
        } else {

            return (
                <View style={styles.container}>


                    <View style={styles.searchWrapper}>
                        <View style={styles.searchImageWrapper}>
                            <Image
                                source={require('../assets/images/buttons/search.jpg')}
                                style={styles.searchImage}
                                resizeMode="contain"
                            />
                        </View>
                        <TextInput
                            placeholder="Suche"
                            style={styles.search}
                            onChangeText={(search) => this.setState({search: search})}
                            defaultValue={this.state.name}
                        />
                    </View>


                    <ImageBackground source={require('../assets/images/buttons/BGWhite3x.png')}
                                     style={{
                                         width: '100%', height: '100%', zIndex: 0
                                     }}>

                        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

                            <View style={styles.box}>


                                {dataStrategieguide.map(data =>

                                    <View>

                                        {
                                            this.state.toggle === data.id ?

                                                <View style={styles.entry}>

                                                    <TouchableWithoutFeedback key={data.id}
                                                                              onPress={this._toggleModal.bind(this, data.id)}>

                                                        <View>

                                                            <Text style={styles.entryTitle}>{data.title}</Text>

                                                            <Image
                                                                source={require('../assets/images/buttons/arrowup.png')}
                                                                style={styles.arrow}
                                                                resizeMode="contain"
                                                            />

                                                            <Text style={styles.entryText}>{data.text}</Text>
                                                        </View>

                                                    </TouchableWithoutFeedback>
                                                    <a href={data.url} target='_blank'>
                                                        <Image
                                                            style={styles.openButton}
                                                            source={require('../assets/images/buttons/browser_gruen.png')}
                                                            resizeMode="contain"
                                                        />
                                                    </a>

                                                </View>

                                                :
                                                <View style={styles.entry}>
                                                    <TouchableWithoutFeedback key={data.id}
                                                                              onPress={this._toggleModal.bind(this, data.id)}>

                                                        <View>
                                                            <Text style={styles.entryTitle}>{data.title}</Text>

                                                            <Image
                                                                source={require('../assets/images/buttons/arrowdown.png')}
                                                                style={styles.arrow}
                                                                resizeMode="contain"
                                                            />
                                                        </View>
                                                    </TouchableWithoutFeedback>
                                                </View>

                                        }

                                    </View>
                                )}
                            </View>

                        </ScrollView>
                    </ImageBackground>


                </View>
            );
        }
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1, width: 380, zIndex: 0
    },

    contentContainer: {
        marginBottom: 10,
        backgroundColor: '#f2f2f2',
        width: deviceWidth * .95,
        marginLeft: 10,
        borderRadius: 10,
        overflow: 'hidden', zIndex: 0

    },

    box: {
        flex: 1,
        padding: 10,
        paddingBottom: 80
    },

    entry: {
        width: deviceWidth * .95 - 20,
        minHeight: 30,
        backgroundColor: '#ffffff',
        borderRadius: 10,
        overflow: 'hidden',
        padding: 15,
        marginBottom: 10,
        zIndex: 0
    },

    entryTitle: {
        width: '90%',
        fontFamily: 'roboto-bold',
    },

    entryText: {
        fontFamily: 'roboto-regular',
        marginTop: 20,
        width: '100%',
    },

    arrow: {
        marginTop: 5,
        width: '10%'

    },

    searchWrapper: {
        width: deviceWidth * .95 - 20,
        backgroundColor: '#e1e1e1',
        borderRadius: 20,
        overflow: 'hidden',

        padding: 20,

        marginLeft: 20,
        marginTop: 15,
        marginBottom: 15,

        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
    },

    searchImageWrapper: {
        width: deviceWidth * .1,
        height: 60,
        margin: 0,
    },

    searchImage: {
        width: '100%',
        height: 25,
        marginTop: -13
    },

    search: {
        width: deviceWidth * .8,
        height: 60,
        backgroundColor: '#e1e1e1',
        color: '#2e2e2e',
        fontSize: 16,
        margin: 0,
        marginTop: -30,
        padding: 10
    },

    locationContainer: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 20,
    },

    weiterButtonWrapper: {
        width: 140,
        height: 70,
        marginBottom: 30,
        marginTop: 130,
        marginLeft: deviceWidth * 0.25,
    },

    weiterButton: {
        width: '90%',
        height: 60,
        marginTop: 20,
    },

    openButtonWrapper: {
        width: 140,
        height: 70,
        marginBottom: 30,
        marginTop: 10,
        marginLeft: deviceWidth * 0.25,
    },

    openButton: {
        width: '90%',
        height: 60,
        marginTop: 20,
    },


    pauseOverlay: {
        width: deviceWidth,
        height: deviceHeight,
        left: 0,
        top: 0,
        backgroundColor: '#000000',
        position: 'absolute',
        zIndex: 8,
        opacity: 0.5,
        overflow: 'hidden',

    },

    line: {
        width: '95%',
        marginLeft: '3%',
        height: 3,
        backgroundColor: '#f2f2f2',
        marginTop: 20,
        marginBottom: 10
    },

    checkboxWrapper: {
        flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        alignItems: 'flex-start',
        padding: 10,
        marginBottom: 20,
    },

    checkbox: {
        width: 40,
        height: 40
    },

    checkboxText: {
        width: deviceWidth * .8,
        height: 50,
        paddingLeft: 20,
    },

    checkboxImage: {
        width: deviceWidth * .1,
        height: 50,
        margin: 0,
        marginTop: -10,
        marginRight: 20,
        marginBottom: 20
    },

});
